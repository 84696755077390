import { IWijzigenNotitiesParams } from '../../models/relatie';
import api from '../';
import {
  IOphalenContactpersonenVoorLijstResult,
  IOphalenEmailadressenVoorContactpersoonParams,
  IOphalenEmailadressenVoorContactpersoonResult,
  IOphalenTelefoonnummersVoorContactpersoonParams,
  IOphalenTelefoonnummersVoorContactpersoonResult,
  ICheckWijzigenContactpersoonParams,
  IOphalenContactpersoonResult,
  IKoppelenContactpersoonParams,
  ICheckVerwijderenParams,
  IOntkoppelenContactpersoonParams,
  IOphalenContactpersonenParams,
  IOphalenContactpersonenResult,
  IOphalenRelatieBetrekkingenParams,
  IOphalenRelatieBetrekkingenResult,
  IToevoegenRelatieBetrekkingParams,
  IToevoegenRelatieBetrekkingResult,
  IWijzigenRelatieBetrekkingParams,
  IWijzigenRelatieBetrekkingResult,
  IVerwijderenRelatieBetrekkingParams,
  IVerwijderenRelatieBetrekkingResult,
  IOphalenPersonenVanLocatieParams,
  IOphalenPersonenVanLocatieResult,
  IMuterenPersonenVanLocatieParams,
  IMuterenPersonenVanLocatieResult,
} from '../../../../shared/src/api/v2/relatie/contactpersoon';
import {
  IKoppelenLocatiesVoorRelatiesParams,
  IKoppelenLocatiesVoorRelatiesResult,
  IOphalenLocatiesParams,
  IOphalenLocatiesResult,
} from '../../../../shared/src/api/v2/relatie/locatie';
import {
  IToevoegenRelatieParams,
  IToevoegenRelatieResult,
  IVerwijderenRelatieParams,
  ICheckVerwijderenRelatieParams,
  IWijzigenRelatieParams,
  IOphalenLocatiesVoorLijstParams,
  IOphalenLocatiesVoorLijstResult,
  IOphalenAfgeleideDataParams,
  IOphalenAfgeleideDataResult,
  IOphalenRelatiesParams,
  IOphalenRelatiesResult,
  IOphalenContactenParams,
  IOphalenContactenResult,
  IOphalenInformatieVoorOverzichtParams,
  IOphalenInformatieVoorOverzichtResult,
  IOphalenRelatiesuggestiesParams,
  IOphalenRelatiesuggestiesResult,
  IWijzigenRekeningenVanRelatieParams,
  IWijzigenRekeningenVanRelatieResult,
  ICheckVerwijderenRekeningenVanRelatieParams,
  IMuteerRelatieMemoParams,
  IMuteerRelatieMemoResult,
  IWijzigenCorrespondentieadresParams,
  IWijzigenCorrespondentieadresResult,
  IOphalenRekeningenParams,
  IOphalenRekeningenResult,
  IOphalenRelPersParams,
  IOphalenRelPersResult,
  IOphalenRelatieAttributenParams,
  IOphalenRelatieAttributenResult,
} from '../../../../shared/src/api/v2/relatie';
import {
  IOphalenMutatiesParams,
  IOphalenMutatiesResult,
  IHerstellenDataParams,
  IHerstellenDataResult,
} from '../../../../shared/src/api/v2/relatie/mutatie';
import {
  IDataSignaleringenParams,
  IDataSignaleringenResult,
} from '../../../../shared/src/api/v2/relatie/Signalering';
import ICheckData from '../../../../shared/src/models/ICheckData';
import document from './relatie/document';
import duplicaat from './relatie/duplicaat';
import locatie from './relatie/locatie';
import acceptatie from './relatie/acceptatie';
import afdeling from './relatie/afdeling';
import vertegenwoordiging from './relatie/vertegenwoordiger';
import {
  IOphalenRelatiesMetVeelContractenParams,
  IOphalenRelatiesMetVeelContractenResult,
} from '../../../../shared/src/api/v2/relatie/Selectie';
import hoedanigheid from './relatie/hoedanigheid';
import rekening from './relatie/rekening';
import bestand from './relatie/bestand';
import contract from './relatie/contract';
import complex from './relatie/complex';

const relatie = {
  complex,
  rekening,
  vertegenwoordiging,
  acceptatie,
  document,
  duplicaat,
  locatie,
  afdeling,
  hoedanigheid,
  bestand,
  contract,
  wijzigenNotities: async (params: IWijzigenNotitiesParams) => {
    return await api.post('/v2/relatie/wijzigen-notities', params);
  },
  ophalenContactpersonen: async (params: IOphalenContactpersonenParams, signal?: AbortSignal) => {
    return api.post<IOphalenContactpersonenResult>(
      'v2/relatie/contactpersoon/ophalen-contactpersonen',
      params,
      {
        signal,
      },
    );
  },
  // ophalenContactpersonenVoorLijst: async (params: IOphalenContactpersonenVoorLijstParams) => {
  //   return api.post<IOphalenContactpersonenVoorLijstResult>(
  //     'v2/relatie/contactpersoon/ophalen-contactpersonen-voor-lijst',
  //     params,
  //   );
  // },
  checkWijzigenContactpersoon: async (params: ICheckWijzigenContactpersoonParams) => {
    return api.post('v2/relatie/contactpersoon/check-wijzigen-contactpersoon', params);
  },
  verwijderenContactpersoonCheck: async (params: ICheckVerwijderenParams) => {
    return api.post('v2/relatie/contactpersoon/check-verwijderen-contactpersonen', params);
  },
  verwijderenContactpersoon: async (params: any) => {
    return api.post('v2/relatie/contactpersoon/verwijderen-contactpersonen', params);
  },
  ophalenContactpersoon: async (params: any): Promise<IOphalenContactpersoonResult> => {
    return api.post('v2/relatie/contactpersoon/ophalen-contactpersoon', params);
  },
  wijzigenContactpersoon: async (params: any) => {
    return api.post('v2/relatie/contactpersoon/wijzigen-contactpersoon', params);
  },
  ophalenRelatieBijzonderheden: async (
    params: IDataSignaleringenParams,
  ): Promise<IDataSignaleringenResult> => {
    return await api.post('v2/relatie/data-signaleringen', params);
  },
  ophalenRechtsvormen: async () => {
    return await api.post('v2/relatie/ophalen-rechtsvormen');
  },
  ophalenEmailadressenVoorContactpersoon: async (
    params: IOphalenEmailadressenVoorContactpersoonParams,
  ) => {
    return api.post<IOphalenEmailadressenVoorContactpersoonResult>(
      'v2/relatie/contactpersoon/ophalen-emailadressen-voor-contactpersoon',
      params,
    );
  },
  ophalenTelefoonnummersVoorContactpersoon: async (
    params: IOphalenTelefoonnummersVoorContactpersoonParams,
  ) => {
    return api.post<IOphalenTelefoonnummersVoorContactpersoonResult>(
      'v2/relatie/contactpersoon/ophalen-telefoonnummers-voor-contactpersoon',
      params,
    );
  },
  checkToevoegenRelatie: async (params: IToevoegenRelatieParams): Promise<ICheckData> => {
    return await api.post('v2/relatie/check-toevoegen-relatie', params);
  },
  toevoegenRelatie: async (params: IToevoegenRelatieParams): Promise<IToevoegenRelatieResult> => {
    return await api.post('v2/relatie/toevoegen-relatie', params);
  },
  verwijderenRelatie: async (params: IVerwijderenRelatieParams) => {
    return await api.post('v2/relatie/verwijderen-relatie', params);
  },
  checkVerwijderenRelatie: async (params: ICheckVerwijderenRelatieParams) => {
    return await api.post('v2/relatie/check-verwijderen-relatie', params);
  },
  checkWijzigenRelatie: async (params: IWijzigenRelatieParams) => {
    return await api.post('v2/relatie/check-wijzigen-relatie', params);
  },
  wijzigenCorrespondentieadres: async (
    params: IWijzigenCorrespondentieadresParams,
  ): Promise<IWijzigenCorrespondentieadresResult> => {
    return await api.post('v2/relatie/wijzigen-correspondentieadres', params);
  },
  wijzigenRelatie: async (params: IWijzigenRelatieParams) => {
    return await api.post('v2/relatie/wijzigen-relatie', params);
  },
  ophalenLocatiesVoorLijst: async (params: IOphalenLocatiesVoorLijstParams) => {
    return await api.post<IOphalenLocatiesVoorLijstResult>(
      'v2/relatie/ophalen-locaties-voor-lijst',
      params,
    );
  },
  ophalenAfgeleideData: async (params: IOphalenAfgeleideDataParams) => {
    return await api.post<IOphalenAfgeleideDataResult>('v2/relatie/ophalen-afgeleide-data', params);
  },
  koppelenContactpersoon: async (
    params: IKoppelenContactpersoonParams,
  ): Promise<IKoppelenContactpersoonParams> => {
    return await api.post('v2/relatie/contactpersoon/koppelen-contactpersoon', params);
  },
  ontkoppelenContactpersoon: async (
    params: IOntkoppelenContactpersoonParams,
  ): Promise<IOntkoppelenContactpersoonParams> => {
    return await api.post('v2/relatie/contactpersoon/ontkoppelen-contactpersoon', params);
  },
  ophalenRelaties: async (
    params: IOphalenRelatiesParams,
    signal?: AbortSignal,
  ): Promise<IOphalenRelatiesResult> => {
    return await api.post('v2/relatie/ophalen-relaties-v2', params, { signal });
  },
  checkOntkoppelenContactpersoon: async (
    params: IOntkoppelenContactpersoonParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/relatie/contactpersoon/check-ontkoppelen-contactpersoon', params);
  },
  ophalenContacten: async (params: IOphalenContactenParams): Promise<IOphalenContactenResult> => {
    return await api.post('v2/relatie/ophalen-contacten', params);
  },
  ophalenMutaties: async (params: IOphalenMutatiesParams): Promise<IOphalenMutatiesResult> => {
    return await api.post('v2/relatie/mutatie/ophalen-mutaties', params);
  },
  herstellenData: async (params: IHerstellenDataParams): Promise<IHerstellenDataResult> => {
    return await api.post('v2/relatie/mutatie/herstellen-data', params);
  },
  ophalenInformatieVoorOverzicht: async (
    params: IOphalenInformatieVoorOverzichtParams,
  ): Promise<IOphalenInformatieVoorOverzichtResult> => {
    return await api.post('v2/relatie/ophalen-informatie-voor-overzicht', params);
  },
  ophalenRelatiesuggesties: async (
    params: IOphalenRelatiesuggestiesParams,
  ): Promise<IOphalenRelatiesuggestiesResult> => {
    return await api.post('v2/relatie/ophalen-relatiesuggesties', params);
  },
  wijzigenRekeningenVanRelatie: async (
    params: IWijzigenRekeningenVanRelatieParams,
  ): Promise<IWijzigenRekeningenVanRelatieResult> => {
    return await api.post('v2/relatie/wijzigen-rekeningen-van-relatie', params);
  },
  checkVerwijderenRekeningVanRelatie: async (
    params: ICheckVerwijderenRekeningenVanRelatieParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/relatie/check-verwijderen-rekeningen-van-relatie', params);
  },
  ophalenLocaties: async (params: IOphalenLocatiesParams): Promise<IOphalenLocatiesResult> => {
    return await api.post('v2/relatie/locatie/ophalen-locaties', params);
  },
  muteerRelatieMemo: async (
    params: IMuteerRelatieMemoParams,
  ): Promise<IMuteerRelatieMemoResult> => {
    return await api.post('v2/relatie/muteer-relatie-memo', params);
  },
  ophalenRelatiesMetVeelContracten: async (
    params: IOphalenRelatiesMetVeelContractenParams,
  ): Promise<IOphalenRelatiesMetVeelContractenResult> => {
    return await api.post('v2/relatie/selectie/ophalen-relaties-met-veel-contracten', params);
  },
  // ophalenRekeningen: async (
  //   params: IOphalenRekeningenParams,
  // ): Promise<IOphalenRekeningenResult> => {
  //   return await api.post('v2/relatie/ophalen-rekeningen', params);
  // },
  ophalenRelPers: async (params: IOphalenRelPersParams): Promise<IOphalenRelPersResult> => {
    return api.post('v2/relatie/ophalen-rel-pers', params);
  },
  ophalenRelatieAttributen: async (
    params: IOphalenRelatieAttributenParams,
  ): Promise<IOphalenRelatieAttributenResult> => {
    return api.post('v2/relatie/ophalen-relatie-attributen', params);
  },
  ophalenRelatieBetrekkingen: async (
    params: IOphalenRelatieBetrekkingenParams,
  ): Promise<IOphalenRelatieBetrekkingenResult> => {
    return api.post('v2/relatie/contactpersoon/ophalen-relatie-betrekkingen', params);
  },
  toevoegenRelatieBetrekking: async (
    params: IToevoegenRelatieBetrekkingParams,
  ): Promise<IToevoegenRelatieBetrekkingResult> => {
    return api.post('v2/relatie/contactpersoon/toevoegen-relatie-betrekking', params);
  },
  checkToevoegenRelatieBetrekking: async (
    params: IToevoegenRelatieBetrekkingParams,
  ): Promise<ICheckData> => {
    return api.post('v2/relatie/contactpersoon/check-toevoegen-relatie-betrekking', params);
  },
  wijzigenRelatieBetrekking: async (
    params: IWijzigenRelatieBetrekkingParams,
  ): Promise<IWijzigenRelatieBetrekkingResult> => {
    return api.post('v2/relatie/contactpersoon/wijzigen-relatie-betrekking', params);
  },
  checkWijzigenRelatieBetrekking: async (
    params: IWijzigenRelatieBetrekkingParams,
  ): Promise<ICheckData> => {
    return api.post('v2/relatie/contactpersoon/check-wijzigen-relatie-betrekking', params);
  },
  verwijderenRelatieBetrekking: async (
    params: IVerwijderenRelatieBetrekkingParams,
  ): Promise<IVerwijderenRelatieBetrekkingResult> => {
    return api.post('v2/relatie/contactpersoon/verwijderen-relatie-betrekking', params);
  },
  ophalenPersonenVanLocatie: async (
    params: IOphalenPersonenVanLocatieParams,
  ): Promise<IOphalenPersonenVanLocatieResult> => {
    return api.post('v2/relatie/contactpersoon/ophalen-personen-van-locatie', params);
  },
  muterenPersonenVanLocatie: async (
    params: IMuterenPersonenVanLocatieParams,
  ): Promise<IMuterenPersonenVanLocatieResult> => {
    return api.post('v2/relatie/contactpersoon/muteren-personen-van-locatie', params);
  },
};

export default relatie;
