import React from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import { EFunctioneleIcon, functioneleIconMap, IconWinkelwagen } from '../../components/Icons';
import Overdracht from './Overdracht';
import Verkoopvoorstellen from './Verkoopvoorstellen';
// import Actieclaims from '../Klantacties/KlantWerftKlant';
import Klantacties from '../Klantacties';
import Accounts from './Accounts';
import Conceptcontracten from './Conceptcontracten';
import Feedbacks from './Feedbacks';

interface IProps extends RouteComponentProps {}

const Icon = functioneleIconMap[EFunctioneleIcon.Opvolgen];

const Opvolging = (props: IProps) => {
  const { match } = props;

  return (
    <div className="d-flex flex-column flex-fill">
      <Route path={`${match.path}/verkoopvoorstellen`} component={Verkoopvoorstellen} />
      <Route path={`${match.path}/concept-contracten`} component={Conceptcontracten} />
      <Route path={`${match.path}/overdracht`} component={Overdracht} />
      <Route path={`${match.path}/accounts`} component={Accounts} />
      {/* <Route path={`${match.path}/actieclaims`} component={Actieclaims} /> */}
      <Route path={`${match.path}/klantacties`} component={Klantacties} />
      <Route path={`${match.path}/feedbacks`} component={Feedbacks} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/reviews`} />}
      />
    </div>
  );
};

export default Opvolging;
