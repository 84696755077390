import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../shared/src/api/v2/relatie';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import api from '../../../../../../api';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { Kleur as EKleur } from '../../../../../../bedrijfslogica/constanten';
import { RouteComponentProps, withRouter } from 'react-router';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import { IOphalenWebbetalingenResultElement } from '../../../../../../../../shared/src/api/v2/website/betaling';
import { IOphalenAccountsResultElement } from '../../../../../../../../shared/src/api/v2/account/account';
import _ from 'lodash';
import nameOf from '../../../../../../core/nameOf';
import MutatieBedrag from '../../../../../../components/MutatieBedrag';
import useUrlState from '../../../../../../core/useUrlState';
import { IconToevoegen } from '../../../../../../components/Icons';
import { EResultType } from '../../../../../../stores/CheckStore';
import { EBetaalwijze } from '../../../../../../bedrijfslogica/enums';

const RowDetailComp = () => <span></span>;

interface IProps extends IEntiteitProps, RouteComponentProps {
  relID: number;
}

interface IUrlState {
  uitgeklapt: number[];
  selectie: number[];
}
const defaultUrlState: IUrlState = {
  uitgeklapt: [],
  selectie: [],
};

const Webbetalingen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [webbetalingen, setWebbetalingen] = useState<IOphalenWebbetalingenResultElement[] | null>(
    null,
  );
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const [accounts, setAccounts] = useState<IOphalenAccountsResultElement[] | null>(null);

  const ophalenAccounts = useCallback(async () => {
    const accountsResult = await api.v2.account.ophalenAccounts({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
    });

    setAccounts(accountsResult);
  }, [props.relID]);

  useEffect(() => {
    ophalenAccounts();
  }, [ophalenAccounts]);

  const ophalenRelatie = useCallback(async () => {
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.relID],
          },
        ],
      },
    });

    setRelatie(relatiesResult.relaties[0]);
  }, [props.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  const ophalenWebbetalingen = useCallback(async () => {
    if (accounts === null) {
      return;
    }

    const result = await api.v2.extern.betaling.ophalenBetalingen({
      filterSchema: {
        filters: [{ naam: 'ACC_IDS', data: [_.uniq(accounts.map((x) => x.AccID))] }],
      },
      orderSchema: { orders: [{ naam: 'STARTDATUM', richting: 'DESC' }] },
    });

    setWebbetalingen(result.betalingen);
  }, [accounts]);

  useEffect(() => {
    ophalenWebbetalingen();
  }, [ophalenWebbetalingen]);

  const rekeningenKeyExtractor = useCallback(
    (row: IOphalenWebbetalingenResultElement) => row.ID,
    [],
  );
  const kolommen = useMemo<TypedColumn<IOphalenWebbetalingenResultElement>[]>(
    () => [
      {
        name: 'Startdatum',
        title: 'Startdatum',
      },
      {
        name: 'BetaalKenmerk',
        title: 'Kenmerk',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      // {
      //   name: 'TransactieReferentie',
      //   title: 'Referentie',
      // },
      {
        name: 'BetaalMethode',
        title: 'Methode',
      },
      {
        name: '__laatsteStatus' as any,
        title: 'Status',
      },
      {
        name: '__info' as any,
        title: 'IBAN (naam)/Email',
      },
      // {
      //   name: 'RecordToegevoegd',
      //   title: 'Geregistreerd',
      //   getCellValue: (x: any) =>
      //     x.RecordToegevoegd !== null ? format(new Date(x.RecordToegevoegd), 'dd-MM-yyyy') : null,
      // },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenWebbetalingenResultElement>[]>(
    () => [
      {
        columnName: 'Startdatum',
        width: 150,
      },
      {
        columnName: 'BetaalKenmerk',
        width: 150,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Omschrijving',
        width: 250,
      },
      {
        columnName: 'TransactieReferentie',
        width: 200,
      },
      {
        columnName: 'BetaalMethode',
        width: 85,
      },
      {
        columnName: '__laatsteStatus' as any,
        width: 135,
      },
      {
        columnName: '__info' as any,
        width: 350,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-fill flex-column">
      {webbetalingen === null || relatie === null ? (
        <div className="d-flex align-items-center justify-content-center flex-fill">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <MenuLayout
            menu={
              <>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center"
                    style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                    onClick={async () => {
                      const webbetaling = webbetalingen.find((x) => x.ID === urlState.selectie[0])!;
                      if (webbetaling.BetaalMethode !== EBetaalwijze.iDeal) {
                        await checkStore.melden({
                          titel: 'Deze betaling heeft een betaalwijze zonder IBAN, b.v. PayPal.',
                        });
                        return;
                      }

                      // IBAN
                      const iban =
                        webbetaling.dataIDeal !== undefined ? webbetaling.dataIDeal.IBAN : null;
                      const rekeningnaam =
                        webbetaling.dataIDeal !== undefined
                          ? webbetaling.dataIDeal.Rekeningnaam
                          : null;

                      if (iban === null || rekeningnaam === null) {
                        await checkStore.melden({
                          titel: 'Deze betaling heeft geen gevulde IBAN en/of Rekeningnaam',
                        });
                        return;
                      }

                      const params = {
                        relaties: [{ relID: relatie.RelID, iban, rekeningnaam }],
                      };
                      const checkData = await api.v2.relatie.rekening.checkKoppelenIBANAanRelatie(
                        params,
                      );
                      const controleResult = await checkStore.controleren({
                        checkData,
                      });
                      if (controleResult.type === EResultType.Annuleren) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: (
                              <span>
                                Wil je rekening {iban} t.n.v. {rekeningnaam} aan de relatie
                                toevoegen?
                                <br />
                                <br />
                                De rekening wordt niet automatisch als standaardrekening gemarkeerd.
                              </span>
                            ),
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      const result = await api.v2.relatie.rekening.koppelenIBANAanRelatieParams(
                        params,
                      );

                      ophalenWebbetalingen();
                    }}
                    disabled={urlState.selectie.length !== 1}
                  >
                    <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                    <span className="ml-2">Rekening toevoegen bij relatie</span>
                  </button>
                </div>
              </>
            }
            body={
              <div className="d-flex flex-column flex-fill">
                <GridStyleWrapper height={'calc(100vh - 100px)'} rowAmount={webbetalingen.length}>
                  <Grid
                    getRowId={rekeningenKeyExtractor as any}
                    rows={webbetalingen}
                    columns={kolommen}
                  >
                    <DataTypeProvider
                      for={[nameOf<IOphalenWebbetalingenResultElement>('Startdatum')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenWebbetalingenResultElement = formatterProps.row;
                        return <span>{format(new Date(rij.Startdatum), 'dd-MM-yyyy HH:mm')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenWebbetalingenResultElement>('Bedrag')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenWebbetalingenResultElement = formatterProps.row;
                        return <MutatieBedrag bedrag={rij.Bedrag} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__laatsteStatus']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOphalenWebbetalingenResultElement;

                        const status =
                          rij.events.length > 0 ? rij.events[rij.events.length - 1].Status : '';

                        const statusnaam =
                          status === 1
                            ? 'Betaald'
                            : status === 2
                            ? 'Geannuleerd'
                            : status === 3
                            ? 'Verlopen'
                            : status === 4
                            ? 'Mislukt'
                            : 'Onbekend';

                        return (
                          <span style={{ color: status === 1 ? EKleur.Grijs : EKleur.Rood }}>
                            {statusnaam}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__info']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenWebbetalingenResultElement = formatterProps.row;

                        // IBAN
                        const iban = rij.dataIDeal !== undefined ? rij.dataIDeal.IBAN : null;
                        const rekeningnaam =
                          rij.dataIDeal !== undefined ? rij.dataIDeal.Rekeningnaam : null;

                        if (iban !== null) {
                          if (!relatie.financieel.rekeningen.some((x) => x.IBAN === iban)) {
                            // De gebruikte IBAN is niet bij de relatie vastgelegd
                            return (
                              <span
                                style={{ color: EKleur.Rood }}
                                title="Rekening komt niet voor bij de relatie"
                              >
                                {iban} ({rekeningnaam})
                              </span>
                            );
                          }

                          if (relatie.financieel.StandaardRekening_RelRekID !== null) {
                            // De gebruikte IBAN is wel bij de relatie vastgelegd maart is niet de standaardrekening
                            const standaardIBAN = relatie.financieel.rekeningen.find(
                              (x) => x.RelRekID === relatie.financieel.StandaardRekening_RelRekID,
                            );
                            return (
                              <span
                                style={{ color: EKleur.Blauw }}
                                title="Is niet de standaardrekening"
                              >
                                {iban} ({rekeningnaam})
                              </span>
                            );
                          }
                          return <span>{iban}</span>;
                        }

                        // Email
                        const email = rij.dataPayPal !== undefined ? rij.dataPayPal.Email : null;
                        if (email !== null) {
                          return <span>{email}</span>;
                        }
                        return <span></span>;
                      }}
                    />

                    <EditingState
                      onEditingRowIdsChange={(x) => {
                        const id = x[x.length - 1] as number;
                      }}
                      onCommitChanges={() => null}
                    />

                    <VirtualTable messages={{ noData: 'Er zijn geen webbetalingen gevonden' }} />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />
                    <TableEditColumn
                      width={35}
                      showEditCommand
                      commandComponent={DXCommandComponent}
                    />
                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              </div>
            }
          />
        </>
      )}
    </div>
  );
});

export default withRouter(Webbetalingen);
