import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../core/IDialoogProps';
import nameOf from '../../../../core/nameOf';
import teksten from '../../../../bedrijfslogica/teksten';
import EnumCombobox from '../../../../components/formulier/EnumCombobox';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import {
  IOphalenBestellingenResultElement,
  IWinweekWinnaar,
} from '../../../../../../shared/src/api/v2/klantactie/winweek';
import RelatieSelectie from '../../../../components/formulier/RelatieSelectie';
import RelatieSelectieV2, {
  ERelatieSelectieKolom,
} from '../../../../components/formulier/RelatieSelectieV2';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { Provider } from '../../../../components/formulier/MultiComboboxV2';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { addDays } from 'date-fns';
import { dagDatum, dagDatumOfUndefined } from '../../../../helpers/datum';
import { IOphalenBestandenResult } from '../../../../../../shared/src/api/v2/bestand/bestand';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';
import BijlageKiezer from '../../../../components/formulier/BijlageKiezer';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number | null;
}

interface IFormikValues {
  relID?: number;

  weeknummer?: number;
  jaar?: number;
  adres: string;
  foto_BestandID?: number;

  publicatiedatum?: Date | string;
  statusOk?: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  relID: 'Relatie',
  weeknummer: 'Weeknummer',
  jaar: 'Jaar',
  adres: 'Adres',
  foto_BestandID: 'Foto',
  publicatiedatum: 'Publicatiedatum',

  statusOk: 'Status Ok',
};

const MuterenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const [item, setItem] = useState<IWinweekWinnaar | undefined>(undefined);

  const getWeekNumber = () => {
    let now = new Date();
    let onejan = new Date(now.getFullYear(), 0, 1);
    let week = Math.ceil(((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7);
    return week;
  };

  const isNieuwItem = (): boolean => {
    return props.id === null;
  };

  const ophalenItem = useCallback(async () => {
    const results = await api.v2.klantactie.winweek.ophalenWinnaars({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });
    setItem(results[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenItem();
  }, [ophalenItem]);

  const [bestanden, setBestandenResult] = useState<IOphalenBestandenResult | null>(null);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IWinweekWinnaar = {
        ID: item?.ID,
        RelID: values.relID!,

        Weeknummer: values.weeknummer!,
        Jaar: values.jaar!,
        Adres: values.adres,
        Foto_BestandID: values.foto_BestandID,

        Publicatiedatum: values.publicatiedatum!,
        StatusOk: values.statusOk!,
      };

      await api.v2.klantactie.winweek.muterenWinnaar(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [item],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (item == null) {
      const x: IFormikValues = {
        relID: undefined,
        weeknummer: getWeekNumber(),
        adres: '',
        jaar: new Date().getFullYear(),
        foto_BestandID: undefined,

        publicatiedatum: dagDatum(new Date()),
        statusOk: false,
      };
      return x;
    }

    const x: IFormikValues = {
      relID: item.RelID,
      weeknummer: item.Weeknummer,
      jaar: item.Jaar,
      adres: item.Adres,
      foto_BestandID: item.Foto_BestandID,

      publicatiedatum: dagDatumOfUndefined(item.Publicatiedatum),
      statusOk: item.StatusOk,
    };

    return x;
  }, [item]);

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (!values.relID) {
      errors.relID = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'relID' });
    }
    return errors;
  }, []);

  const ophalenBestanden = async (bestandID: number) => {
    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: bestandID,
          },
        ],
      },
    });
    setBestandenResult(result);
  };

  const [relevanteBestellingen, setRelevanteBestellingen] = useState<
    IOphalenBestellingenResultElement[] | null
  >(null);

  const ophalenRelevanteBestellingen = useCallback(async () => {
    const result = await api.v2.klantactie.winweek.ophalenBestellingen({
      filterSchema: {},
    });
    setRelevanteBestellingen(result.bestellingen);
  }, []);

  useEffect(() => {
    ophalenRelevanteBestellingen();
  }, [ophalenRelevanteBestellingen]);

  const relatieSelectieProvider = useMemo<
    Provider<ERelatieSelectieKolom, IOphalenRelatiesResultElementV2>
  >(
    () => async (params) => {
      const relatiesResult = await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters:
            relevanteBestellingen == null
              ? []
              : [
                  {
                    naam: 'IDS',
                    data: relevanteBestellingen?.map((b) => b.RelID),
                  },
                ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'WEERGAVENAAM',
              richting: 'ASC',
            },
          ],
        },
        paginatie: params.paginatie,
      });

      const items = relatiesResult.relaties.reduce(
        (acc, curr, idx) => ({
          ...acc,
          [params.paginatie.index + idx]: curr,
        }),
        params.huidigeBron,
      );

      return {
        items,
        totaalAantal: relatiesResult.totaalAantal,
      };
    },
    [relevanteBestellingen],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{isNieuwItem() ? 'Toevoegen' : 'Wijzigen'} Winweek Winnaar</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={handleValidate}
          enableReinitialize
          isInitialValid={!isNieuwItem()}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.relID}</label>
                        <Field
                          name={nameOf<IFormikValues>('relID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <RelatieSelectieV2
                                  relID={field.value}
                                  onChange={(relID) => form.setFieldValue(field.name, relID)}
                                  provider={relatieSelectieProvider}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.weeknummer}</label>
                        <Field
                          name={nameOf<IFormikValues>('weeknummer')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <input
                                type="number"
                                className="form-control"
                                {...fieldProps.field}
                                min={0}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>{veldnamen.jaar}</label>
                        <Field
                          name={nameOf<IFormikValues>('jaar')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <input
                                type="number"
                                className="form-control"
                                {...fieldProps.field}
                                min={0}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.adres}</label>
                        <Field
                          name={nameOf<IFormikValues>('adres')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.foto_BestandID}</label>
                        <Field
                          name={nameOf<IFormikValues>('foto_BestandID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <BijlageKiezer
                                bestand={bestanden?.bestanden[0]}
                                onBestIDChange={async (bestID) => {
                                  console.log('bestand veranderd:' + bestID);
                                  fieldProps.form.setFieldValue(fieldProps.field.name, bestID);
                                  if (bestID != null) ophalenBestanden(bestID);
                                  else setBestandenResult(null);
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.publicatiedatum}</label>
                        <Field
                          name={nameOf<IFormikValues>('publicatiedatum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <DatumKiezer
                                  waarde={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  determinePreviousValidDate={(date) => {
                                    return addDays(date, -7);
                                  }}
                                  determineNextValidDate={(date) => {
                                    return addDays(date, +7);
                                  }}
                                  isClearable
                                ></DatumKiezer>
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('statusOk')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <label className="d-flex">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <label>{veldnamen.statusOk}</label>
                              </label>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default MuterenDialoog;
