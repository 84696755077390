import IDialoogProps from '../../../../../../core/IDialoogProps';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../../../../dialogen/Dialoog';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import nameOf from '../../../../../../core/nameOf';
import api from '../../../../../../api';
import {
  IGebouw,
  IToevoegenGebouwParams,
  IWijzigenGebouwParams,
} from '../../../../../../../../shared/src/api/v2/gebouw';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../stores/CheckStore';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../../Gedeeld/LoadingSpinner';

interface IProps extends IDialoogProps<null> {
  id?: number;
  locID: number;
}

interface IFormikValues {
  naam: string;
}

const GebouwMutatieDialoog = (props: IProps): JSX.Element => {
  const { checkStore } = useContext(RootStoreContext);

  const [gebouw, setGebouw] = useState<IRemoteData<IGebouw | null>>(createPendingRemoteData());
  const bepalenGebouw = useCallback(async () => {
    if (props.id === undefined) {
      setGebouw(createReadyRemoteData(null));
      return;
    }

    const gebouwenResult = await api.v2.gebouw.ophalenGebouwen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });
    const gebouw = gebouwenResult.gebouwen[0];

    setGebouw(createReadyRemoteData(gebouw));
  }, [props.id]);
  useEffect(() => {
    bepalenGebouw();
  }, [bepalenGebouw]);

  const initialValues = useMemo<IRemoteData<IFormikValues>>(() => {
    if (gebouw.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    if (gebouw.data !== null) {
      return createReadyRemoteData({
        naam: gebouw.data!.Naam,
      });
    }

    return createReadyRemoteData({
      naam: '',
    });
  }, [gebouw]);
  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      if (props.id === undefined) {
        const toevoegenParams: IToevoegenGebouwParams = {
          locID: props.locID,
          naam: values.naam,
        };
        const checkData = await api.v2.gebouw.checkToevoegenGebouw(toevoegenParams);

        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          return;
        }

        await api.v2.gebouw.toevoegenGebouw(toevoegenParams);
      } else {
        const wijzigenParams: IWijzigenGebouwParams = {
          id: props.id,
          locID: props.locID,
          naam: values.naam,
        };

        const checkData = await api.v2.gebouw.checkWijzigenGebouw(wijzigenParams);
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          return;
        }

        await api.v2.gebouw.wijzigenGebouw(wijzigenParams);
      }

      props.onSuccess(null);
    },
    [props.id, props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Gebouw {props.id === undefined ? 'toevoegen' : 'wijzigen'}</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <LoadingSpinner />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues.data!}
          render={(formikProps) => <Formulier {...props} formikProps={formikProps} />}
        />
      )}
    </Dialoog>
  );
};

interface IFormulierProps extends IProps {
  formikProps: FormikProps<IFormikValues>;
}

const Formulier = (props: IFormulierProps): JSX.Element => {
  return (
    <>
      <ModalBody>
        <label>Naam</label>
        <Field
          name={nameOf<IFormikValues>('naam')}
          render={(fieldProps: FieldProps<IFormikValues>) => (
            <input type="text" className="form-control" {...fieldProps.field} />
          )}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={props.formikProps.submitForm}
          style={{ width: 100 }}
          disabled={props.formikProps.isSubmitting || !props.formikProps.isValid}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={props.formikProps.isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default GebouwMutatieDialoog;
