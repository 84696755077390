import React, { useCallback, useContext, useMemo, useState } from 'react';
import DienstFormulier, {
  IFormikValues as IDienstFormulierFormikValues,
  validationSchema as dienstFormulierValidationSchema,
} from '../../../DienstFormulier';
import { LeverancierContext } from '../../../../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/transport';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import nameof from '../../../../../../core/nameOf';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import TijdVeld, { ITijdVeldWaarde } from '../../../../../../components/formulier/TijdVeld';
import * as Yup from 'yup';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';

interface IFormikValues extends IDienstFormulierFormikValues {
  tijdUiterlijkOpdracht: ITijdVeldWaarde;
  emailOpdracht: string | null;
  emailOpdrachtCC: string | null;
  levertermijn: number | null;
  ftpOpdrachtParams: string | null;
  ftpToegestaan: boolean;
  premiumLeveren: boolean;
  koppelenTransportopdrachtenAanFactuur: boolean;
}

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  tijdUiterlijkOpdracht: 'Uiterlijke tijd opdracht',
  emailOpdracht: 'Email tbv opdrachten',
  emailOpdrachtCC: 'Email CC',
  levertermijn: 'Levertermijn',
  ftpOpdrachtParams: 'Parameters FTP-opdrachten',
  ftpToegestaan: 'FTP Toegestaan',
  premiumLeveren: 'Premiums leveren',
  koppelenTransportopdrachtenAanFactuur: 'Transportopdrachten koppelen aan de factuur',
};

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const transportdienst = context.transportdienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(false);
  const [transportUitgeklapt, setTransportUitgeklapt] = useState(true);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      actief: transportdienst.Actief,
      naam: transportdienst.Naam || '',
      naamEnum: transportdienst.NaamEnum ?? '',
      opdWijzeID: transportdienst.OpdWijzeID !== undefined ? transportdienst.OpdWijzeID : null,
      parameters: transportdienst.Parameters || '',
      tijdUiterlijkOpdracht: {
        resultaat: transportdienst.TijdUiterlijkOpdracht,
        tekst:
          transportdienst.TijdUiterlijkOpdracht === null
            ? ''
            : transportdienst.TijdUiterlijkOpdracht,
      },
      emailOpdracht: transportdienst.EmailOpdracht,
      emailOpdrachtCC: transportdienst.EmailOpdrachtCC,
      levertermijn: transportdienst.Levertermijn,
      ftpOpdrachtParams: transportdienst.FTPOpdracht,
      ftpToegestaan: transportdienst.FTPToegestaan,
      premiumLeveren: transportdienst.PremiumLeveren,
      koppelenTransportopdrachtenAanFactuur: transportdienst.KoppelenTransportopdrachtenAanFactuur,
    };
  }, [transportdienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenDienstParams = {
        relID: context.relID!,
        parameters: values.parameters === '' ? null : values.parameters,
        opdWijzeID: values.opdWijzeID,
        naam: values.naam,
        naamEnum: values.naamEnum,
        actief: values.actief,

        trsDienstID: transportdienst.ID,

        levertermijn: values.levertermijn!,
        emailOpdracht: values.emailOpdracht === '' ? null : values.emailOpdracht,
        emailOpdrachtCC: values.emailOpdrachtCC === '' ? null : values.emailOpdrachtCC,
        tijdUiterlijkOpdracht: values.tijdUiterlijkOpdracht.resultaat!,
        ftpOpdrachtParams: values.ftpOpdrachtParams,
        ftpToegestaan: values.ftpToegestaan,
        premiumLeveren: values.premiumLeveren,
        koppelenTransportopdrachtenAanFactuur: values.koppelenTransportopdrachtenAanFactuur,
      };

      const checkData = await api.v2.dienst.transport.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.transport.wijzigenDienst(params);
      context.onVerversenTransportdienstAangevraagd();
      actions.setSubmitting(false);
    },
    [context.relID, transportdienst, context.onVerversenTransportdienstAangevraagd],
  );

  const validationSchema = useMemo(() => {
    return dienstFormulierValidationSchema.concat(
      Yup.object().shape({
        tijdUiterlijkOpdracht: Yup.object().shape({
          resultaat: Yup.string(),
        }),
        emailOpdracht: Yup.string(),
        // emailOpdrachtCC: Yup.string(),
        levertermijn: Yup.number(),
      }),
    );
  }, []);

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        // enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={<DienstFormulier />}
                />
                <div className="p-2" />
                <UitklapFormulier
                  label="Transport"
                  uitgeklapt={transportUitgeklapt}
                  onUitgeklaptChange={setTransportUitgeklapt}
                  formulier={
                    <div className="p-3">
                      <div className="row mb-3">
                        <div className="col-6">
                          <label>{veldnamen.levertermijn}</label>
                          <Field
                            name={nameof<IFormikValues>('levertermijn')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={0}
                                    max={99}
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <label>{veldnamen.tijdUiterlijkOpdracht}</label>
                          <Field
                            name={nameof<IFormikValues>('tijdUiterlijkOpdracht')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <TijdVeld
                                    waarde={field.value}
                                    onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <label>{veldnamen.emailOpdracht}</label>
                          <Field
                            name={nameof<IFormikValues>('emailOpdracht')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <label>{veldnamen.emailOpdrachtCC}</label>
                          <Field
                            name={nameof<IFormikValues>('emailOpdrachtCC')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="row col-12 mt-3">
                        <label>{veldnamen.ftpOpdrachtParams}</label>
                        <Field
                          name={nameof<IFormikValues>('ftpOpdrachtParams')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <textarea {...field} className="form-control" rows={3} />;
                          }}
                        />
                      </div>

                      <div className="row col-12 d-flex mt-3">
                        <Field
                          name={nameof<IFormikValues>('premiumLeveren')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <label className="ml-2">{veldnamen.premiumLeveren}</label>
                      </div>

                      <div className="row col-12 d-flex mt-3">
                        <Field
                          name={nameof<IFormikValues>('ftpToegestaan')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <label className="ml-2">{veldnamen.ftpToegestaan}</label>
                      </div>

                      <div className="row col-12 d-flex mt-3">
                        <Field
                          name={nameof<IFormikValues>('koppelenTransportopdrachtenAanFactuur')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <label className="ml-2">
                          {veldnamen.koppelenTransportopdrachtenAanFactuur}
                        </label>
                      </div>
                    </div>
                  }
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
