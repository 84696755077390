import React, { useContext } from 'react';
import ContractTegel from '../ContractTegel';
import { TableRowDetail } from '@devexpress/dx-react-grid';
import { ILocatieContracten, LocatieContractenTabelContext } from './index';
import { TableRowDetailWrapper } from '../../../../../../../../helpers/dxTableGrid';
import { ContractenContext } from '../../index';

const RowDetail = (props: TableRowDetail.ContentProps) => {
  const ctx = useContext(LocatieContractenTabelContext);
  const contractenContext = useContext(ContractenContext);

  const row: ILocatieContracten = props.row;
  return (
    <TableRowDetailWrapper>
      <div className="p-1 pt-2">
        {row.contracten.map((contract) => {
          return (
            <div key={contract.CntID} className={`ml-1 mr-1 mb-1`}>
              <ContractTegel
                contract={contract}
                geselecteerd={ctx.selectie.includes(contract.CntID)}
                onGeselecteerdChange={(geselecteerd) => {
                  if (!geselecteerd) {
                    ctx.onSelectieChange(ctx.selectie.filter((id) => id !== contract.CntID));
                  } else {
                    ctx.onSelectieChange([...ctx.selectie, contract.CntID]);
                  }
                }}
                vernieuwenContracten={() => {
                  ctx.onRequestRefresh();
                }}
                relID={contractenContext.relID}
              />
            </div>
          );
        })}
      </div>
    </TableRowDetailWrapper>
  );
};

export default RowDetail;
