import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import {
  IMuterenWinweekWinnaarParams,
  IOphalenBestellingenParams,
  IOphalenBestellingenResult,
  IOphalenWinweekWinnaarParams,
  IVerwijderenWinweekWinnaarParams,
  IWinweekWinnaar,
} from '../../../../../../shared/src/api/v2/klantactie/winweek';
import {
  ILeegGeneriekResult,
  IMuterenGeneriekResult,
} from '../../../../../../shared/src/api/v2/generiek';

const winweek = {
  ophalenWinnaars: async (params: IOphalenWinweekWinnaarParams): Promise<IWinweekWinnaar[]> => {
    return await api.post('/v2/klantactie/winweek/ophalen-winnaars', params);
  },
  muterenWinnaar: async (params: IMuterenWinweekWinnaarParams): Promise<IMuterenGeneriekResult> => {
    return await api.post('/v2/klantactie/winweek/muteren-winnaar', params);
  },
  verwijderenWinnaars: async (
    params: IVerwijderenWinweekWinnaarParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/klantactie/winweek/verwijderen-winnaars', params);
  },

  ophalenBestellingen: async (
    params: IOphalenBestellingenParams,
  ): Promise<IOphalenBestellingenResult> => {
    return await api.post('/v2/klantactie/winweek/ophalen-bestellingen', params);
  },
};

export default winweek;
