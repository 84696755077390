import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Tabblad, { ITabblad } from '../../components/layout/Tabblad';
import Winweek from './Winweek';
import KlantWerftKlant from './KlantWerftKlant';

export enum ETabblad {
  KlantWerftKlant = 1,
  Winweek = 2,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.KlantWerftKlant]: '/klant-werft-klant',
  [ETabblad.Winweek]: '/winweek',
};

interface IProps extends RouteComponentProps {}

const Klantacties: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Winweek]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Winweek,
        label: 'Winweek',
        content: Winweek,
      },
      {
        id: ETabblad.KlantWerftKlant,
        label: 'Klant werft klant',
        content: KlantWerftKlant,
      },
    ],
    [],
  );

  return (
    <>
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </>
  );
};

export default withRouter(Klantacties);
