import React from 'react';
import urlCriteria from '../urlCriteria';
import Service from './entiteiten/Service';
import Contracten from './entiteiten/Contracten';
import Accounts from './entiteiten/Accounts';
import Events from './entiteiten/Events';
import Boekingen from '../../../../components/kaart/entiteiten/Boekingen';
import Acceptatie from './entiteiten/Acceptatie';
import Debiteur from './entiteiten/Debiteur';
import EntiteitContainer, {
  IUrlEntiteitMapping,
} from '../../../../components/kaart/EntiteitContainer';
import Communicatie from '../../../../components/kaart/entiteiten/Communicatie';
import Overzicht from './entiteiten/Overzicht';
import Facturen from './entiteiten/Facturen';
import Klant from './entiteiten/Klant';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { defaultEntiteitRoute } from '../../index';
import Contactpersonen from '../../../../components/kaart/entiteiten/Contactpersonen';
import Locaties from './entiteiten/Locaties';
import Bankzaken from '../../../../components/kaart/entiteiten/Bankzaken';
import Transport from '../../../../components/kaart/entiteiten/Transport';
import Signaleringen from '../../../../components/kaart/relatie/Signaleringen';
import Afdelingen from '../../../../components/kaart/entiteiten/Afdelingen';
import ProductAdvies from './entiteiten/Productadvies';
import Vertegenwoordiging from './entiteiten/Vertegenwoordiging';
import Winkelwagens from './entiteiten/Winkelwagens';
import FinancieelOverzicht from './entiteiten/Financieel/Overzicht';
import Documenten from '../../../../components/relatie/Bestanden';
// import Rekeningen from './entiteiten/Rekeningen';
import Rekeningen from '../../../../components/kaart/entiteiten/Rekeningen';
import Reviews from './entiteiten/Reviews';
import Rubrieken from './entiteiten/Rubrieken';
// import Webbetalingen from './Entiteiten/Webbetalingen';
import IRemoteData from '../../../../models/IRemoteData';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import Webbetalingen from './entiteiten/Webbetalingen';
import Partnerships from './entiteiten/Partnerships';
import Complexen from './entiteiten/Complexen';
import Opvolging from './entiteiten/Opvolging';

interface IProps extends RouteComponentProps {
  relID: number;
  relatie: IRemoteData<IOphalenRelatiesResultElementV2>;
  verversRelatie: () => Promise<void>;
}

const urlEntiteitMapping: IUrlEntiteitMapping[] = [
  {
    criteria: urlCriteria.overzicht,
    comp: Overzicht,
  },
  {
    criteria: urlCriteria.communicatie,
    comp: Communicatie,
  },
  {
    criteria: urlCriteria.documenten,
    comp: Documenten,
  },
  {
    criteria: urlCriteria.transport,
    comp: Transport,
  },
  {
    criteria: urlCriteria.service,
    comp: Service,
  },
  {
    criteria: urlCriteria.contracten,
    comp: Contracten,
  },
  {
    criteria: urlCriteria.accounts,
    comp: Accounts,
  },
  {
    criteria: urlCriteria.afdelingen,
    comp: Afdelingen,
  },
  {
    criteria: urlCriteria.klant,
    comp: Klant,
  },
  {
    criteria: urlCriteria.facturen,
    comp: Facturen,
  },
  {
    criteria: urlCriteria.debiteur,
    comp: Debiteur,
  },
  {
    criteria: urlCriteria.bankzaken,
    comp: Bankzaken,
  },
  {
    criteria: urlCriteria.winkelwagens,
    comp: Winkelwagens,
  },
  {
    criteria: urlCriteria.events,
    comp: Events,
  },
  {
    criteria: urlCriteria.contactpersonen,
    comp: Contactpersonen,
  },
  {
    criteria: urlCriteria.locaties,
    comp: Locaties,
  },
  {
    criteria: urlCriteria.complexen,
    comp: Complexen,
  },
  {
    criteria: urlCriteria.boekingen,
    comp: Boekingen,
  },
  {
    criteria: urlCriteria.webbetalingen,
    comp: Webbetalingen,
  },
  {
    criteria: urlCriteria.acceptatie,
    comp: Acceptatie,
  },
  {
    criteria: urlCriteria.signaleringen,
    comp: Signaleringen,
  },
  {
    criteria: urlCriteria.partnerships,
    comp: Partnerships,
  },
  {
    criteria: urlCriteria.productadvies,
    comp: ProductAdvies,
  },
  {
    criteria: urlCriteria.vertegenwoordiging,
    comp: Vertegenwoordiging,
  },
  {
    criteria: urlCriteria.opvolging,
    comp: Opvolging,
  },
  {
    criteria: urlCriteria.financieel,
    comp: FinancieelOverzicht,
  },
  {
    criteria: urlCriteria.rekeningen,
    comp: Rekeningen,
  },
  {
    criteria: urlCriteria.rubrieken,
    comp: Rubrieken,
  },
  {
    criteria: urlCriteria.reviews,
    comp: Reviews,
  },
];

const Entiteiten: React.FC<IProps> = (props) => {
  return (
    <EntiteitContainer
      urlEntiteitMapping={urlEntiteitMapping}
      onEntiteitNietGevonden={() => {
        const newUrl = props.match.url + defaultEntiteitRoute;
        props.history.replace(newUrl);
      }}
      relID={props.relID}
      relatie={props.relatie}
      verversRelatie={props.verversRelatie}
    />
  );
};

export default withRouter(Entiteiten);
