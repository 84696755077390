import React, { useCallback, useContext, useMemo } from 'react';
import { IUitgeklapteRijProps } from '../../../../../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import { ILocatie } from '../../../../../../../../../../../shared/src/api/v2/relatie/contract';
import { LocatieTabelContext, LocatieTabelKolom } from '../index';
import styled from 'styled-components';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';
import ContractTegel from './ContractTegel';
import { useOverzichtV2Store } from '../../store';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
  row-gap: 5px;
  background-color: ${Kleur.HeelLichtGrijs};
`;

const UitgeklapteRij = (props: IUitgeklapteRijProps<LocatieTabelKolom, ILocatie>): JSX.Element => {
  const store = useOverzichtV2Store();
  const { dataBijID } = useContext(LocatieTabelContext)!;

  const contracten = useMemo(() => {
    const contractenVanLocatie = dataBijID.contractenBijLocID[props.regel.LocID];
    return contractenVanLocatie.filter(
      (cnt) => store.data.data!.result.cntIDsVoorWeergave.indexOf(cnt.CntID) !== -1,
    );
  }, [props.regel.LocID, dataBijID]);

  const handleVernieuwenContracten = useCallback(async () => {
    await store.verversenContractWeergave();
  }, [store.verversenContractWeergave]);

  return (
    <Root>
      {contracten.map((cnt) => {
        return (
          <ContractTegel
            key={cnt.CntID}
            contract={cnt}
            vernieuwenContracten={handleVernieuwenContracten}
          />
        );
      })}
    </Root>
  );
};

export default UitgeklapteRij;
