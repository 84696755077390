import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { IOphalenToegevoegdeRelatiesResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/duplicaat';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../../../../../../components/personalia/RelatieVisualisatie';
import { formatteerAdresV2 } from '../../../../../../../helpers';
import { IconTerugdraaien, IconToevoegen } from '../../../../../../../components/Icons';
import RelatieSelectieDialoog from '../../../../../../../components/personalia/RelatieSelectieDialoog';
import { EResultType } from '../../../../../../../stores/CheckStore';
import _ from 'lodash';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IRelatieToevoegenDialoogState {
  open: boolean;
}

interface IUrlState {
  selectie: number[];
  relatieToevoegenDialoogState: IRelatieToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  relatieToevoegenDialoogState: null,
};

const geenData = {
  noData: 'Geen toegevoegde relaties gevonden',
};

export interface IRow extends IOphalenToegevoegdeRelatiesResultElement {
  Relatienummer: string;
  adres: {
    Straatnaam: string;
    Huisnummer: number;
    Bisnummer: string | null;
    Postcode: string;
    Plaatsnaam: string;
    LandID: number;
  } | null;
}

const ToegevoegdComp: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const [relaties, setRelaties] = useState<IRow[] | null>(null);

  const ophalenToegevoegdeRelaties = useCallback(async () => {
    // Basisgegevens ophalen
    const toegevoegdeRelatiesResult = await api.v2.relatie.duplicaat.ophalenToegevoegdeRelaties({
      relID: props.relID,
    });
    const relIDs = toegevoegdeRelatiesResult.toegevoegdeRelaties.map((x: any) => x.RelID);
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });

    // Voeg relatie-gegevens er aan toe
    const relaties = toegevoegdeRelatiesResult.toegevoegdeRelaties.map((x: any) => {
      const relatie = relatiesResult.relaties.find((r) => r.RelID === x.RelID)!;
      return {
        ...x,
        Relatienummer: relatie.Relatienummer,
        adres:
          relatie.adres !== null
            ? {
                Straatnaam: relatie.adres.Straatnaam,
                Huisnummer: relatie.adres.Huisnummer,
                Bisnummer: relatie.adres.Bisnummer,
                Postcode: relatie.adres.Postcode,
                Plaatsnaam: relatie.adres.Plaatsnaam,
                LandID: relatie.adres.LandID,
              }
            : null,
      };
    });

    const relatiesGsorteerd = _.orderBy(relaties, ['Datum'], ['desc']);

    setRelaties(relatiesGsorteerd);
  }, [klantkaartStore.relatie]);

  useEffect(() => {
    ophalenToegevoegdeRelaties();
  }, [ophalenToegevoegdeRelaties]);

  // const handleToevoegen = useCallback(async () => {
  //   setUrlStateSync('accessoireToevoegenDialoogState', {
  //     actief: true,
  //   });
  // }, [setUrlStateSync]);

  const handleVerwijderen = useCallback(async () => {
    // const checkData = await api.v2.verkoop.checkVerwijderenVerkoopopdrachten({
    //   IDs: urlState.selectie,
    // });
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }
    // if (
    //   (
    //     await checkStore.bevestigen({
    //       titel: (
    //         <span>
    //           Wil je de opdrachten verwijderen?
    //           <br />
    //           <br />
    //           Let op: Dit kan niet meer ongedaan gemaakt worden{' '}
    //         </span>
    //       ),
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }
    // await api.v2.verkoop.verwijderenVerkoopopdrachten({
    //   IDs: urlState.selectie,
    // });
    // setUrlStateSync('selectie', []);
    // await ophalenProducten();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Toevoegdatum',
      },
      {
        name: 'Relatienummer',
        title: 'Rel.nr.',
      },
      {
        name: '__toegevoegdeRelatie' as any,
        title: 'Relatie',
      },
      {
        name: '__adres' as any,
        title: 'Adres',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 135,
      },
      {
        columnName: 'Relatienummer',
        width: 90,
      },
      {
        columnName: '__toegevoegdeRelatie' as any,
        width: 225,
      },
      {
        columnName: '__adres',
        width: 300,
      },
    ],
    [],
  );

  return (
    <>
      {relaties === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Hiermee worden alle entiteiten (Contracten, factuen etc) van de te
                            selecteren relatie gekoppeld aan de onderhavige relatie.
                            <br />
                            <br />
                            Dit kan ernstige consequenties hebben, je moet er zeker van zijn wat je
                            doet! Je kunt de toevoeging later wel ongedaan maken.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  setUrlStateSync('relatieToevoegenDialoogState', {
                    open: true,
                  });
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Toevoegen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 1}
                onClick={async () => {
                  const params = { id: urlState.selectie[0] };
                  const checkData = await api.v2.relatie.duplicaat.checkHerstellenToegevoegdeRelatie(
                    params,
                  );
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Hiermee wordt de samenvoeging voor de geselecteerde relatie ongedaan
                            gemaakt. De entiteiten die bij het samenvoegen van deze relatie aan de
                            onderhavige relatie gekoppeld zijn worden daar ontkoppeld en wederom aan
                            de geselecteerde relatie gekoppeld.
                            <br />
                            <br />
                            Dit kan ernstige consequenties hebben, je moet er zeker van zijn wat je
                            doet!
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: <span>Ongedaan maken?</span>,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.relatie.duplicaat.herstellenToegevoegdeRelatie(params);

                  setUrlStateSync('selectie', []);
                  ophalenToegevoegdeRelaties();
                }}
              >
                <IconTerugdraaien style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Ongedaan maken</span>
              </button>
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={relaties}>
              <DataTypeProvider
                for={['Datum']}
                formatterComponent={(formatterProps) => {
                  return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__toegevoegdeRelatie']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row;
                  return <RelatieVisualisatie relID={rij.RelID} />;
                }}
              />

              <EditingState
                onCommitChanges={() => null}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  alert('Wijzigen ' + id);
                  // setWijzigenID(id);
                }}
              />

              <DataTypeProvider
                for={['__adres']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row;

                  return (
                    <span>
                      {rij.adres !== null
                        ? formatteerAdresV2({
                            straatnaam: rij.adres.Straatnaam,
                            postcode: rij.adres.Postcode,
                            plaatsnaam: rij.adres.Plaatsnaam,
                            landnaamKort: rij.adres.LandnaamKort,
                            landnaamEnum: rij.adres.LandnaamEnum,
                            huisnummer: rij.adres.Huisnummer,
                            bisnummer: rij.adres.Bisnummer,
                          })
                        : ''}
                    </span>
                  );
                }}
              />

              {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <VirtualTable messages={geenData} />

              {/* <TableEditColumn
                width={35}
                // showAddCommand={true}
                // showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />

              {/* <RowDetailState defaultExpandedRowIds={[]} /> */}
              {/* <TableRowDetail
                contentComponent={() => <span>Detailinfo</span>}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        </>
      )}

      {urlState.relatieToevoegenDialoogState !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={async (result) => {
            // alert(JSON.stringify(result));
            const toevoegenResult = await api.v2.relatie.duplicaat.toevoegenAanRelatie({
              relID: result.relID,
              toevoegenAanRelID: props.relID,
            });

            setUrlStateSync('relatieToevoegenDialoogState', null);
            ophalenToegevoegdeRelaties();
            setUrlStateSync('selectie', [toevoegenResult.ID]);
          }}
          onAnnuleren={() => setUrlStateSync('relatieToevoegenDialoogState', null)}
          // koppelOpties={props.koppelOpties}
        />
      )}
    </>
  );
});

const Toegevoegd = withRouter(ToegevoegdComp);
export default Toegevoegd;
