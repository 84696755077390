import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IEntiteitProps } from '../../EntiteitContainer';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import {
  IOphalenContactpersonenResultElement,
  IOphalenRelatieBetrekkingenResultElement,
} from '../../../../../../shared/src/api/v2/relatie/contactpersoon';
import api from '../../../../api';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import MenuLayout from '../../../MenuLayout';
import {
  IconBellen,
  IconEmail,
  IconInformatie,
  IconKlantkaart,
  IconSjablonen,
  IconSMS,
  IconSter,
  IconTekenbevoegd,
  IconToevoegen,
  IconWhatsapp,
} from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RouterContext } from '../../../../routers/RouterContextProvider';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PersoonSelectieDialoog, { ETabblad } from '../../../dialogen/PersoonSelectieDialoog';
import PersoonWijzigenDialoog from '../../../dialogen/PersoonWijzigenDialoog';
import KredietwaardigheidToetsDialoog from './KredietwaardigheidToetsDialoog';
import WijzigenRelatieGerelateerdDialoog from './WijzigenRelatieGerelateerdDialoog';
import ToevoegenAccountDialoog from './ToevoegenAccountDialoog';
import { IOphalenAccountsResultElement } from '../../../../../../shared/src/api/v2/account/account';
import PersooninfoDialoog from '../../../personalia/PersooninfoDialoog';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import ASPTabel from '../../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import { setCommunicatieOverlayState } from '../../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../../one-off-components/CommunicatieOverlay/MenuHandle';
import { defaultOpstellenFormulierFormikValues } from '../../../communicatie/EmailWerkblad/OpstellenFormulier';
import {
  ECommunicatiekanaalSelectieType,
  EOpgegevenContextSelectieType,
  ESjabloonOplosserModus,
  ESMSVerstuurModusType,
  ETaalSelectieType,
  EVerstuurModusKanaal,
  IEmailVerstuurModus,
  IWhatsAppVerstuurModus,
  SjabloonOplosserVerstuurModusKanalen,
  SMSVerstuurModus,
} from '../../../SjabloonOplosser/types';
import SjabloonOplosser, { ISjabloonOplosserOutput } from '../../../SjabloonOplosser';
import ActieMenuKnop from '../../../ActieMenuKnop';
import { EResultType } from '../../../../stores/CheckStore';
import { format } from 'date-fns';
import PersoonVisualisatie from '../../../personalia/PersoonVisualisatie';
import TelefoonComponent from '../../../communicatie/TelefoonComponent';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../FilterBalkV2';
import TableData, { ITableDataProps } from '../../../tabel/ASPTabel/Body/TableData';
import WijzigenTableData, {
  IWijzigenTableDataProps,
} from '../../../tabel/ASPTabel/Body/WijzigenTableData';
import Skeleton from 'react-loading-skeleton';
import ZoektermFilter from './ZoektermFilter';

interface IProps extends IEntiteitProps, RouteComponentProps {}

interface IToevoegenContactpersoonDialoogState {
  tabblad?: ETabblad;
}

interface IPersoonWijzigenDialoogState {
  persID: number;
}

interface IKredietwaardigheidDialoogState {
  persID: number;
}

interface IToevoegenAccountDialoogState {
  persID: number;
}

interface IWijzigenRelatieGerelateerdDialoogState {
  persID: number;
}

interface IPersooninfoDialoogState {
  persID: number;
}

export enum EFilter {
  Actief = 'IS_ACTIEF',
  Zoekterm = 'ZOEKTERM',
}

interface IUrlState {
  toevoegenContactpersoonDialoogState: IToevoegenContactpersoonDialoogState | null;
  persoonWijzigenDialoogState: IPersoonWijzigenDialoogState | null;
  kredietwaardigheidDialoogState: IKredietwaardigheidDialoogState | null;
  toevoegenAccountDialoogState: IToevoegenAccountDialoogState | null;
  wijzigenRelatieGerelateerdDialoogState: IWijzigenRelatieGerelateerdDialoogState | null;
  persooninfoDialoogState: IPersooninfoDialoogState | null;
  filterData: IFilterData<EFilter>[];
}
const defaultUrlState: IUrlState = {
  toevoegenContactpersoonDialoogState: null,
  persoonWijzigenDialoogState: null,
  kredietwaardigheidDialoogState: null,
  toevoegenAccountDialoogState: null,
  wijzigenRelatieGerelateerdDialoogState: null,
  persooninfoDialoogState: null,
  filterData: [
    {
      naam: EFilter.Actief,
      data: true,
      isActief: true,
    },
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
  ],
};

interface IPersoon extends IOphalenContactpersonenResultElement {
  account: IOphalenAccountsResultElement | null;
  isRelatieVoor_RelIDs: number[];
}

const indicatieIconFiller = <div style={{ minWidth: 20, minHeight: 20 }} className="mr-2" />;
const communicatieIconFiller = <div style={{ minWidth: 17, minHeight: 17 }} className="mr-3" />;

export enum EKolom {
  Naam,
  Indicaties,
  PersoonInfo,
  Taal,
  HeeftAccount,
  TelefoonMobiel,
  Communicatie,
  Acties,
  Notities,
  Betrekking,
  LaatstGewijzigd,
}

const Contactpersonen: React.FC<IProps> = observer((props) => {
  const { klantkaartStore, checkStore, instellingStore } = useContext(RootStoreContext);
  const { navigeerKlantkaartNaarRelID } = useContext(RouterContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [personen, setPersonen] = useState<IRemoteData<IPersoon[]>>(createPendingRemoteData());

  const ophalenPersonenAbortControllerRef = useRef<AbortController | null>(null);
  const ophalenPersonen = useCallback(async () => {
    if (ophalenPersonenAbortControllerRef.current !== null) {
      ophalenPersonenAbortControllerRef.current!.abort();
    }
    ophalenPersonenAbortControllerRef.current = new AbortController();

    const filterSchema = maakFilterSchema(urlState.filterData);

    const contactpersonenResult = await api.v2.relatie.ophalenContactpersonen(
      {
        filterSchema: {
          filters: [
            ...(filterSchema.filters ?? []),
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
          ],
        },
      },
      ophalenPersonenAbortControllerRef.current!.signal,
    );

    const personen = contactpersonenResult.contactpersonen;

    const persIDs = personen.map((x) => x.PersID);

    // Haal de contactpersonen op via de PersID om te bepalen of ze een relatie zijn anders dan bij deze relatie
    const personenDieEenRelatieZijnresult = await api.v2.relatie.ophalenContactpersonen(
      {
        filterSchema: {
          filters: [
            {
              naam: 'PERS_IDS',
              data: persIDs,
            },
            { naam: 'IS_RELATIE', data: true },
          ],
        },
      },
      ophalenPersonenAbortControllerRef.current!.signal,
    );

    const accounts = await api.v2.account.ophalenAccounts(
      {
        filterSchema: {
          filters: [
            { naam: 'REL_IDS', data: [klantkaartStore.relatie!.RelID] },
            { naam: 'PERS_IDS', data: personen.map((x) => x.PersID) },
          ],
        },
      },
      ophalenPersonenAbortControllerRef.current!.signal,
    );

    const contactpersonen = personen.map((persoon) => {
      const isRelatieVoor_RelIDs = personenDieEenRelatieZijnresult.contactpersonen
        .filter((x) => x.PersID === persoon.PersID && x.RelID !== props.relID)
        .map((x) => x.RelID);
      return {
        ...persoon,
        account: accounts.find((x) => x.PersID === persoon.PersID) ?? null,
        isRelatieVoor_RelIDs,
      };
    });

    setPersonen(createReadyRemoteData(contactpersonen));
  }, [props.relID, JSON.stringify(urlState.filterData)]);

  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const relatieBetrekkingIDs = useMemo<IRemoteData<number[]>>(() => {
    if (personen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const ids = personen
      .data!.map((x) => x.RelatieBetrekkingID)
      .filter((x) => x !== null) as number[];
    return createReadyRemoteData(ids);
  }, [personen]);

  const [relatieBetrekkingen, setRelatieBetrekkingen] = useState<
    IRemoteData<Record<number, IOphalenRelatieBetrekkingenResultElement>>
  >(createPendingRemoteData());
  const bepalenRelatieBetrekkingen = useCallback(async () => {
    if (relatieBetrekkingIDs.state === ERemoteDataState.Pending) {
      return;
    }
    if (relatieBetrekkingIDs.data!.length === 0) {
      setRelatieBetrekkingen(createReadyRemoteData({}));
      return;
    }
    const result = await api.v2.relatie.ophalenRelatieBetrekkingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: relatieBetrekkingIDs.data!,
          },
        ],
      },
    });
    const relatieBetrekkingen = result.betrekkingen.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.ID]: curr,
      }),
      {},
    );
    setRelatieBetrekkingen(createReadyRemoteData(relatieBetrekkingen));
  }, [JSON.stringify(relatieBetrekkingIDs)]);
  useEffect(() => {
    bepalenRelatieBetrekkingen();
  }, [bepalenRelatieBetrekkingen]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Actief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => <span>Actief</span>,
      },
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
    ],
    [],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IPersoon>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 190,
        renderer: (rij) => {
          return <PersoonVisualisatie persID={rij.PersID} />;
        },
      },
      {
        key: EKolom.Indicaties,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij) => {
          return (
            <div className="d-flex align-items-center">
              {rij.IsPersoonPrimair ? (
                <UitlegTooltip inhoud="Primair persoon">
                  <IconSter style={{ width: 20, height: 20, fill: Kleur.Blauw }} className="mr-2" />
                </UitlegTooltip>
              ) : (
                indicatieIconFiller
              )}
              {rij.Tekenbevoegd ? (
                <UitlegTooltip inhoud="Tekenbevoegd">
                  <IconTekenbevoegd
                    style={{ width: 20, height: 20, fill: Kleur.Grijs }}
                    className="mr-2"
                  />
                </UitlegTooltip>
              ) : (
                indicatieIconFiller
              )}

              {rij.isRelatieVoor_RelIDs.length !== 0 ? (
                <UitlegTooltip inhoud="Contactpersoon is tevens relatie">
                  <IconKlantkaart style={{ width: 20, height: 20, fill: Kleur.Grijs }} />
                </UitlegTooltip>
              ) : (
                indicatieIconFiller
              )}
            </div>
          );
        },
      },
      {
        key: EKolom.PersoonInfo,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 40,
        renderer: (rij) => {
          return (
            <a
              href="#"
              // style={{ color: Kleur.DonkerGrijs }}
              onClick={() => {
                setUrlStateSync('persooninfoDialoogState', { persID: rij.PersID });
              }}
            >
              <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
            </a>
          );
        },
      },
      {
        key: EKolom.Taal,
        label: 'Taal',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 35,
        renderer: (rij) => rij.taal.TaalKort,
      },
      {
        key: EKolom.TelefoonMobiel,
        label: 'Telefoon',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij) => {
          if (rij.TelefoonMobiel === null) {
            return <span></span>;
          }

          return (
            <TelefoonComponent
              telefoonNummer={rij.TelefoonMobiel}
              options={{
                icoonWeergeven: false,
              }}
            />
          );
        },
      },
      {
        key: EKolom.Communicatie,
        label: 'Communicatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 155,
        renderer: (rij) => {
          return (
            <div className="d-flex align-items-center">
              {rij.TelefoonMobiel !== null ? (
                <button
                  onClick={() => {
                    setCommunicatieOverlayState!((state) => ({
                      ...state,
                      geselecteerdTabblad: ECommunicatieTabblad.Telefoon,
                      telefoonContext: {
                        ...state.telefoonContext,
                        telefoonnummer: rij.TelefoonMobiel!,
                      },
                    }));
                  }}
                  className="mr-3"
                  style={{
                    border: 0,
                    background: 0,
                    padding: 0,
                    margin: 0,
                    outline: 0,
                  }}
                >
                  <IconBellen
                    style={{
                      width: 17,
                      height: 17,
                      fill: Kleur.Grijs,
                    }}
                  />
                </button>
              ) : (
                communicatieIconFiller
              )}
              {rij.WhatsAppSesID !== null ? (
                <button
                  className="mr-3"
                  onClick={() => {
                    setCommunicatieOverlayState!((state) => ({
                      ...state,
                      geselecteerdTabblad: ECommunicatieTabblad.Whatsapp,
                      whatsappContext: {
                        ...state.whatsappContext,
                        chatSessieID: rij.WhatsAppSesID!,
                      },
                    }));
                  }}
                  style={{
                    border: 0,
                    background: 0,
                    padding: 0,
                    margin: 0,
                    outline: 0,
                  }}
                >
                  <IconWhatsapp
                    style={{
                      width: 17,
                      height: 17,
                      fill: Kleur.Grijs,
                    }}
                  />
                </button>
              ) : (
                communicatieIconFiller
              )}
              {rij.TelefoonMobiel !== null ? (
                <button
                  className="mr-3"
                  onClick={() => {
                    console.log(rij);
                    setCommunicatieOverlayState!((state) => ({
                      ...state,
                      geselecteerdTabblad: ECommunicatieTabblad.Sms,
                      smsContext: {
                        ...state.smsContext,
                        telefoonnummer: rij.TelefoonMobiel!,
                        persID: rij.PersID,
                      },
                    }));
                  }}
                  style={{
                    border: 0,
                    background: 0,
                    padding: 0,
                    margin: 0,
                    outline: 0,
                  }}
                >
                  <IconSMS
                    style={{
                      width: 17,
                      height: 17,
                      fill: Kleur.Grijs,
                    }}
                  />
                </button>
              ) : (
                communicatieIconFiller
              )}
              {rij.Email !== null ? (
                <button
                  className="mr-3"
                  onClick={() => {
                    setCommunicatieOverlayState!((state) => ({
                      ...state,
                      geselecteerdTabblad: ECommunicatieTabblad.Email,
                      emailContext: {
                        ...state.emailContext,
                        formulier: {
                          ...defaultOpstellenFormulierFormikValues,
                          recipients: [
                            {
                              emailAdres: rij.Email!,
                              persID: rij.PersID,
                              // relID: props.relID,
                              orgID: null,
                            },
                          ],
                        },
                      },
                    }));
                  }}
                  style={{
                    border: 0,
                    background: 0,
                    padding: 0,
                    margin: 0,
                    outline: 0,
                  }}
                >
                  <IconEmail
                    style={{
                      width: 17,
                      height: 17,
                      fill: Kleur.Grijs,
                    }}
                  />
                </button>
              ) : (
                communicatieIconFiller
              )}
              {rij.WhatsAppSesID !== null || rij.TelefoonMobiel !== null || rij.Email !== null ? (
                <button
                  onClick={async () => {
                    const whatsappVerstuurModusKanaal: IWhatsAppVerstuurModus | null =
                      rij.WhatsAppSesID === null
                        ? null
                        : {
                            whatsAppSesID: rij.WhatsAppSesID,
                          };
                    const smsVerstuurModusKanaal: SMSVerstuurModus | null =
                      rij.TelefoonMobiel === null
                        ? null
                        : {
                            type: ESMSVerstuurModusType.Telefoonnummer,
                            persID: rij.PersID,
                            telefoonnummer: rij.TelefoonMobiel,
                          };
                    const emailVerstuurModusKanaal: IEmailVerstuurModus | null =
                      rij.Email === null
                        ? null
                        : ({
                            aan: [
                              {
                                persID: rij.PersID,
                                emailAdres: rij.Email,
                              },
                            ],
                            cc: [],
                            bcc: [],
                            bijlagen: [],
                            contextRelIDs: [props.relID],
                          } as IEmailVerstuurModus);

                    const kanalen: SjabloonOplosserVerstuurModusKanalen = {
                      [EVerstuurModusKanaal.Email]: emailVerstuurModusKanaal ?? undefined,
                      [EVerstuurModusKanaal.SMS]: smsVerstuurModusKanaal ?? undefined,
                      [EVerstuurModusKanaal.WhatsApp]: whatsappVerstuurModusKanaal ?? undefined,
                    };

                    const result = await globaleRenderer.render<ISjabloonOplosserOutput>(
                      (renderProps) => (
                        <SjabloonOplosser
                          taalSelectie={{
                            type: ETaalSelectieType.AutomatischBepalenViaContexten,
                            isMuteerbaar: false,
                          }}
                          communicatiekanaalSelectie={{
                            type: ECommunicatiekanaalSelectieType.Opgegeven,
                            kanaalID: null,
                            isMuteerbaar: true,
                            magVoorvullen: true,
                          }}
                          modus={{
                            type: ESjabloonOplosserModus.Verstuur,
                            kanalen,
                          }}
                          open
                          onSuccess={(result) => renderProps.destroy(result)}
                          onAnnuleren={() => renderProps.destroy()}
                          defaultOpgegevenContexten={[
                            {
                              selectie: {
                                type: EOpgegevenContextSelectieType.NaamEnum,
                                naamEnum: 'PERSOON',
                              },
                              data: { persID: rij.PersID },
                            },
                            {
                              selectie: {
                                type: EOpgegevenContextSelectieType.NaamEnum,
                                naamEnum: 'RELATIE',
                              },
                              data: { relID: props.relID },
                            },
                          ]}
                        />
                      ),
                    );
                  }}
                  style={{
                    border: 0,
                    background: 0,
                    padding: 0,
                    margin: 0,
                    outline: 0,
                  }}
                  title="Vanuit sjabloon initiëren"
                >
                  <IconSjablonen
                    style={{
                      width: 17,
                      height: 17,
                      fill: Kleur.Grijs,
                    }}
                  />
                </button>
              ) : (
                communicatieIconFiller
              )}
            </div>
          );
        },
      },
      {
        key: EKolom.Acties,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 50,
        renderer: (rij) => {
          return (
            <ActieMenuKnop
              acties={[
                // {
                //   icon: (
                //     <IconWijzigen
                //       style={{ width: 15, height: 15, fill: Kleur.Grijs }}
                //     />
                //   ),
                //   text: 'Wijzigen',
                //   onClick: () =>
                //     setUrlStateSync('persoonWijzigenDialoogState', {
                //       persID: row.PersID,
                //     }),
                // },
                {
                  text: 'Ontkoppelen',
                  onClick: async () => {
                    const params = { relID: props.relID, persID: rij.PersID };
                    const checkData = await api.v2.relatie.checkOntkoppelenContactpersoon(params);
                    const controleResultaat = await checkStore.controleren({
                      checkData,
                    });
                    if (controleResultaat.type === EResultType.Annuleren) {
                      // setIsSubmitting(false);
                      return;
                    }
                    const resultaat = await checkStore.bevestigen({
                      inhoud: `Bevestig ontkoppelen ${rij.Achternaam}?`,
                    });
                    if (resultaat.type === EResultType.Annuleren) {
                      return;
                    }
                    await api.v2.relatie.ontkoppelenContactpersoon(params);
                    await ophalenPersonen();
                  },
                },
                {
                  text: 'Account toevoegen',
                  onClick: () =>
                    setUrlStateSync('toevoegenAccountDialoogState', {
                      persID: rij.PersID,
                    }),
                  // toevoegenAccountDialoogTonenState(contactpersoon.PersID),
                },
                {
                  text: 'Kredietwaardigheid toetsen',
                  onClick: () =>
                    setUrlStateSync('kredietwaardigheidDialoogState', {
                      persID: rij.PersID,
                    }),
                  // kredietwaardigheidtoetsDialoogTonen(contactpersoon.PersID),
                },
                {
                  text: 'Wijzigen Relatie gerelateerd',
                  onClick: () =>
                    setUrlStateSync('wijzigenRelatieGerelateerdDialoogState', {
                      persID: rij.PersID,
                    }),
                },
              ]}
            />
          );
        },
      },
      // {
      //   key: EKolom.Betrekking,
      //   label: 'Betrekking',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 100,
      //   renderer: (rij) => {
      //     return <span>{rij.betrekking?.Naam}</span>;
      //   },
      // },
      {
        key: EKolom.Betrekking,
        label: 'Betrekking',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (rij) => {
          if (rij.RelatieBetrekkingID === null) {
            return <span>-&nbsp;-</span>;
          }
          if (relatieBetrekkingen.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }
          const relBet = relatieBetrekkingen.data![rij.RelatieBetrekkingID];
          if (relBet === undefined) {
            return <Skeleton />;
          }
          return <span>{relBet.Naam}</span>;
        },
      },
      {
        key: EKolom.Notities,
        label: 'Notities',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        minimaleVasteBreedte: 300,
        renderer: (rij) => {
          return (
            <span
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              title={rij.Notities ?? '- -'}
            >
              {rij.Notities ?? '- -'}
            </span>
          );
        },
      },
      // {
      //   key: EKolom.HeeftAccount,
      //   label: () => (
      //     <span
      //       title="Geeft aan dat deze persoon een account heeft voor deze relatie"
      //       style={{ color: Kleur.Grijs }}
      //     >
      //       Acc.
      //     </span>
      //   ),
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 80,
      //   renderer: (rij) => (rij.account !== null ? 'Ja' : null),
      // },
      {
        key: EKolom.LaatstGewijzigd,
        label: 'Laatst gewijzigd',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 145,
        renderer: (rij) =>
          rij.RecordGewijzigd === null
            ? null
            : format(new Date(rij.RecordGewijzigd), 'dd-MM-yyyy HH:mm'),
      },
    ],
    [relatieBetrekkingen],
  );

  const keyExtractor = useCallback((row: IPersoon) => row.PersID, []);

  const handleWijzigenRij = useCallback(async (rij: IPersoon, idx: number) => {
    setUrlStateSync('persoonWijzigenDialoogState', {
      persID: rij.PersID,
    });
  }, []);

  return (
    <>
      <div className="d-flex flex-fill" style={{ width: '100%' }}>
        {personen.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="d-flex flex-fill">
            <MenuLayout
              menu={
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-2"
                    style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                    onClick={() => setUrlStateSync('toevoegenContactpersoonDialoogState', {})}
                  >
                    <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                    <span className="ml-2">Toevoegen</span>
                  </button>

                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    style={{ marginLeft: 20 }}
                  />
                </div>
              }
              body={
                <ASPTabel
                  rijen={personen.data!}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  onWijzigenRij={handleWijzigenRij}
                  tdComponent={TdComponent}
                  wijzigenTdComponent={WijzigenTdComponent}
                />
              }
            />
          </div>
        )}
      </div>
      {urlState.toevoegenContactpersoonDialoogState !== null && (
        <PersoonSelectieDialoog
          tabblad={
            urlState.toevoegenContactpersoonDialoogState.tabblad || ETabblad.NieuwOfSelecteren
          }
          onTabbladChange={(tabblad) =>
            setUrlStateSync('toevoegenContactpersoonDialoogState', { tabblad })
          }
          open
          onSuccess={async (data) => {
            await api.v2.relatie.koppelenContactpersoon({
              persID: data.persID,
              relID: props.relID,
            });
            await api.v2.persoon.bijwerkenLijstRecent({
              persID: data.persID,
            });

            await ophalenPersonen();
            setUrlStateSync('toevoegenContactpersoonDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('toevoegenContactpersoonDialoogState', null);
          }}
        />
      )}

      {urlState.persoonWijzigenDialoogState !== null && (
        <PersoonWijzigenDialoog
          open
          onSuccess={async () => {
            await api.v2.persoon.bijwerkenLijstRecent({
              persID: urlState.persoonWijzigenDialoogState!.persID,
            });
            await ophalenPersonen();
            setUrlStateSync('persoonWijzigenDialoogState', null);
            // klantkaartStore.ophalenRelatie(relID);
            // klantkaartStore.updateActiefContactpersoon(contactpersoonID!);
          }}
          onAnnuleren={() => setUrlStateSync('persoonWijzigenDialoogState', null)}
          // dialoogIndex={1}
          persID={urlState.persoonWijzigenDialoogState.persID}
        />
      )}
      {urlState.kredietwaardigheidDialoogState !== null && (
        <KredietwaardigheidToetsDialoog
          open
          onSuccess={() => {
            setUrlStateSync('kredietwaardigheidDialoogState', null);

            // const state = genereerUrlStateQueryParam<KredietwaardigheidUrlState>({
            //   selectie: [],
            //   toetsingenZichtbaarPersIDs: [urlState.kredietwaardigheidDialoogState!.persID],
            // });
            // props.history.push(`/klant/${props.relID}/klant/kredietwaardigheid?state=${state}`);
          }}
          onAnnuleren={() => setUrlStateSync('kredietwaardigheidDialoogState', null)}
          persID={urlState.kredietwaardigheidDialoogState!.persID}
        />
      )}
      {urlState.toevoegenAccountDialoogState !== null && (
        <ToevoegenAccountDialoog
          open
          relID={props.relID}
          persID={urlState.toevoegenAccountDialoogState.persID}
          onSuccess={() => setUrlStateSync('toevoegenAccountDialoogState', null)}
          onAnnuleren={() => setUrlStateSync('toevoegenAccountDialoogState', null)}
        />
      )}
      {urlState.wijzigenRelatieGerelateerdDialoogState !== null && (
        <WijzigenRelatieGerelateerdDialoog
          open
          relID={props.relID}
          persID={urlState.wijzigenRelatieGerelateerdDialoogState.persID}
          onSuccess={async () => {
            await ophalenPersonen();
            setUrlStateSync('wijzigenRelatieGerelateerdDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenRelatieGerelateerdDialoogState', null)}
        />
      )}
      {urlState.persooninfoDialoogState !== null && (
        <PersooninfoDialoog
          open
          persID={urlState.persooninfoDialoogState.persID}
          onSuccess={() => {
            setUrlStateSync('persooninfoDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('persooninfoDialoogState', null)}
        />
      )}
    </>
  );
});

const TdComponent = (props: ITableDataProps<EKolom, IPersoon>) => {
  const persoon = props.row!;
  return (
    <TableData
      {...props}
      // style={{ backgroundColor: persoon.InactiefOp !== null ? 'rgb(238,238,238)' : undefined }}
      style={{ backgroundColor: persoon.InactiefOp !== null ? 'rgb(241, 218, 218)' : undefined }}
    />
  );
};

const WijzigenTdComponent = (props: IWijzigenTableDataProps<IPersoon>) => {
  const persoon = props.row!;
  return (
    <WijzigenTableData
      {...props}
      style={{ backgroundColor: persoon.InactiefOp !== null ? 'rgb(238,238,238)' : undefined }}
    />
  );
};

export default withRouter(Contactpersonen);
