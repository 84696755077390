import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconAccounts,
  IconBoekhouding,
  IconBoeking,
  IconContract,
  IconEuro,
  IconEvents,
  IconGrid,
  IconKlantkaart,
  IconLocatie,
  IconOverzicht,
  IconPersonen,
  IconService,
  IconTransport,
  IconVink,
  IconWinkelwagen,
  IconAfdeling,
  IconSavings,
  IconReview,
  IconCloud,
  IconCategory,
  IconWebsite,
} from '../../../../components/Icons';
import KaartNavigatie from '../../../../components/kaart/Navigatie';
import {
  ELink,
  IWeergaveProps,
  Link,
} from '../../../../components/navigatie/VerticaleNavigatieBalk';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import {
  IBepalenKlantkaartContractenInfoIndicatiesParams,
  IBepalenKlantkaartPersonenInfoIndicatiesParams,
  IBepalenKlantkaartTransportInfoIndicatiesParams,
  IBepalenKlantkaartServiceInfoIndicatiesParams,
  IBepalenKlantkaartFacturenInfoIndicatiesParams,
  IBepalenKlantkaartAccountsInfoIndicatiesParams,
  IBepalenKlantkaartWinkelwagensInfoIndicatiesParams,
} from '../../../../../../shared/src/api/v2/infoIndicatie';
import { paramsToParamsKey } from '../../../../stores/InfoIndicatieStore';

const IconCommunicatie = functioneleIconMap[EFunctioneleIcon.Communicatie];

export enum EGroepLink {
  Financieel,
  Overige,
}

interface IProps extends RouteComponentProps {
  relatie: IOphalenRelatiesResultElementV2;
}

const Navigatie: React.FC<IProps> = observer((props) => {
  const { infoIndicatieStore } = useContext(RootStoreContext);

  // Personen
  const infoIndicatiePersonenParams = useMemo<IBepalenKlantkaartPersonenInfoIndicatiesParams>(
    () => ({
      relID: props.relatie.RelID,
    }),
    [props.relatie],
  );
  const infoIndicatiePersonenParamsKey = useMemo<string>(
    () => paramsToParamsKey(infoIndicatiePersonenParams),
    [infoIndicatiePersonenParams],
  );

  // Contracten
  const infoIndicatieContractenParams = useMemo<IBepalenKlantkaartContractenInfoIndicatiesParams>(
    () => ({
      relID: props.relatie.RelID,
    }),
    [props.relatie],
  );
  const infoIndicatieContractenParamsKey = useMemo<string>(
    () => paramsToParamsKey(infoIndicatieContractenParams),
    [infoIndicatieContractenParams],
  );

  // Transport
  const infoIndicatieTransportParams = useMemo<IBepalenKlantkaartTransportInfoIndicatiesParams>(
    () => ({
      relID: props.relatie.RelID,
    }),
    [props.relatie],
  );
  const infoIndicatieTransportParamsKey = useMemo<string>(
    () => paramsToParamsKey(infoIndicatieTransportParams),
    [infoIndicatieTransportParams],
  );

  // Service
  const infoIndicatieServiceParams = useMemo<IBepalenKlantkaartServiceInfoIndicatiesParams>(
    () => ({
      relID: props.relatie.RelID,
    }),
    [props.relatie],
  );
  const infoIndicatieServiceParamsKey = useMemo<string>(
    () => paramsToParamsKey(infoIndicatieServiceParams),
    [infoIndicatieServiceParams],
  );

  // Facturen
  const infoIndicatieFacturenParams = useMemo<IBepalenKlantkaartFacturenInfoIndicatiesParams>(
    () => ({
      relID: props.relatie.RelID,
    }),
    [props.relatie],
  );
  const infoIndicatieFacturenParamsKey = useMemo<string>(
    () => paramsToParamsKey(infoIndicatieFacturenParams),
    [infoIndicatieFacturenParams],
  );

  // Accounts
  const infoIndicatieAccountsParams = useMemo<IBepalenKlantkaartAccountsInfoIndicatiesParams>(
    () => ({
      relID: props.relatie.RelID,
    }),
    [props.relatie],
  );
  const infoIndicatieAccountsParamsKey = useMemo<string>(
    () => paramsToParamsKey(infoIndicatieAccountsParams),
    [infoIndicatieAccountsParams],
  );

  // Winkelwagens
  const infoIndicatieWinkelwagensParams = useMemo<
    IBepalenKlantkaartWinkelwagensInfoIndicatiesParams
  >(
    () => ({
      relID: props.relatie.RelID,
    }),
    [props.relatie],
  );
  const infoIndicatieWinkelwagensParamsKey = useMemo<string>(
    () => paramsToParamsKey(infoIndicatieWinkelwagensParams),
    [infoIndicatieWinkelwagensParams],
  );

  const notificieerBenodigdInfoIndicaties = useCallback(async () => {
    await infoIndicatieStore.notificeerBenodidigd([
      {
        soort: 'KLANTKAART_CONTRACTEN',
        params: infoIndicatieContractenParams,
      },
      {
        soort: 'KLANTKAART_PERSONEN',
        params: infoIndicatiePersonenParams,
      },
      {
        soort: 'KLANTKAART_TRANSPORT',
        params: infoIndicatieTransportParams,
      },
      {
        soort: 'KLANTKAART_SERVICE',
        params: infoIndicatieServiceParams,
      },
      {
        soort: 'KLANTKAART_FACTUREN',
        params: infoIndicatieFacturenParams,
      },
      {
        soort: 'KLANTKAART_ACCOUNTS',
        params: infoIndicatieAccountsParams,
      },
      {
        soort: 'KLANTKAART_WINKELWAGENS',
        params: infoIndicatieWinkelwagensParams,
      },
    ]);
  }, [
    infoIndicatieStore.notificeerBenodidigd,
    infoIndicatieContractenParams,
    infoIndicatieAccountsParams,
    infoIndicatiePersonenParams,
    infoIndicatieServiceParams,
    infoIndicatieFacturenParams,
    infoIndicatieTransportParams,
    infoIndicatieWinkelwagensParams,
  ]);

  useEffect(() => {
    notificieerBenodigdInfoIndicaties();
  }, [notificieerBenodigdInfoIndicaties]);

  const [uitgeklapteGroepLinks, setUitgeklapteGroepLinks] = useState<
    Partial<Record<EGroepLink, boolean>>
  >({
    [EGroepLink.Financieel]: true,
    [EGroepLink.Overige]: false,
  });

  const linkWeergaveRenderer = useCallback((text: string, icon?: React.ComponentType<any>) => {
    const Icon = icon;
    return (weergaveProps: IWeergaveProps) => (
      <li
        style={{
          backgroundColor: weergaveProps.actief ? EKleur.Actief : undefined,
        }}
      >
        {Icon !== undefined && (
          <Icon
            style={{
              width: 18,
              height: 18,
              fill: EKleur.Grijs,
            }}
          />
        )}
        <span style={{ color: EKleur.Grijs }}>{text}</span>
      </li>
    );
  }, []);

  const links = useMemo<Link<EGroepLink>[]>(
    () => [
      {
        type: ELink.Link,
        path: '/overzicht',
        weergave: linkWeergaveRenderer('Overzicht', IconOverzicht),
      },
      {
        type: ELink.Link,
        path: '/klant',
        weergave: linkWeergaveRenderer('Klant', IconKlantkaart),
      },
      {
        type: ELink.Link,
        path: '/bestanden',
        weergave: linkWeergaveRenderer('Bestanden', IconCloud),
      },
      {
        type: ELink.Link,
        path: '/contactpersonen',
        weergave: linkWeergaveRenderer('Personen', IconPersonen),
        indicatieAantal:
          infoIndicatieStore.indicaties.KLANTKAART_PERSONEN[infoIndicatiePersonenParamsKey] ??
          undefined,
      },
      {
        type: ELink.Link,
        path: '/communicatie/overzicht',
        weergave: linkWeergaveRenderer('Communicatie', IconCommunicatie),
      },
      {
        type: ELink.Link,
        path: '/contracten',
        weergave: linkWeergaveRenderer('Contracten', IconContract),
        indicatieAantal:
          infoIndicatieStore.indicaties.KLANTKAART_CONTRACTEN[infoIndicatieContractenParamsKey] ??
          undefined,
      },
      // {
      //   type: ELink.Link,
      //   path: '/transport/overzicht',
      //   weergave: linkWeergaveRenderer('Transport', IconTransport),
      // },
      {
        type: ELink.Link,
        path: '/transport',
        weergave: linkWeergaveRenderer('Transport', IconTransport),
        indicatieAantal:
          infoIndicatieStore.indicaties.KLANTKAART_TRANSPORT[infoIndicatieTransportParamsKey] ??
          undefined,
      },
      {
        type: ELink.Link,
        path: '/service',
        weergave: linkWeergaveRenderer('Service', IconService),
        indicatieAantal:
          infoIndicatieStore.indicaties.KLANTKAART_SERVICE[infoIndicatieServiceParamsKey] ??
          undefined,
      },
      {
        type: ELink.Link,
        path: '/accounts',
        weergave: linkWeergaveRenderer('Accounts', IconAccounts),
        indicatieAantal:
          infoIndicatieStore.indicaties.KLANTKAART_ACCOUNTS[infoIndicatieAccountsParamsKey] ??
          undefined,
      },
      {
        type: ELink.Link,
        path: '/acceptatie',
        weergave: linkWeergaveRenderer('Acceptatie', IconVink),
      },
      {
        type: ELink.Link,
        path: '/winkelwagens',
        weergave: linkWeergaveRenderer('Winkelwagens', IconWinkelwagen),
        indicatieAantal:
          infoIndicatieStore.indicaties.KLANTKAART_WINKELWAGENS[infoIndicatieAccountsParamsKey] ??
          undefined,
      },
      {
        type: ELink.GroepLink,
        id: EGroepLink.Financieel,
        weergave: (weergaveProps) => (
          <div className="d-flex" style={{ color: EKleur.Grijs }}>
            Financieel
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/facturen/overzicht',
            weergave: linkWeergaveRenderer('Facturen', IconGrid),
            indicatieAantal:
              infoIndicatieStore.indicaties.KLANTKAART_FACTUREN[infoIndicatieFacturenParamsKey] ??
              undefined,
          },
          {
            type: ELink.Link,
            path: '/bankzaken/bankopdrachten',
            weergave: linkWeergaveRenderer('Bankzaken', IconEuro),
          },
          {
            type: ELink.Link,
            path: '/debiteur/betalingsregelingen',
            weergave: linkWeergaveRenderer('Dossiers', IconBoekhouding),
          },
          {
            type: ELink.Link,
            path: '/boekingen',
            weergave: linkWeergaveRenderer('Boekingen', IconBoeking),
          },
          {
            type: ELink.Link,
            path: '/webbetalingen',
            weergave: linkWeergaveRenderer('Webbetalingen', IconWebsite),
          },
          {
            type: ELink.Link,
            path: '/rekeningen',
            weergave: linkWeergaveRenderer('Rekeningen', IconSavings),
          },
          {
            type: ELink.Link,
            path: '/rubrieken/factuurkenmerken',
            weergave: linkWeergaveRenderer('Rubrieken', IconCategory),
          },
          {
            type: ELink.Link,
            path: '/financieel/overzicht',
            weergave: linkWeergaveRenderer('Overzicht', IconOverzicht),
          },
        ],
      },
      {
        type: ELink.GroepLink,
        id: EGroepLink.Overige,
        weergave: (weergaveProps) => (
          <div className="d-flex" style={{ color: EKleur.Grijs }}>
            Overige
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/productadvies/staat',
            weergave: linkWeergaveRenderer(
              'Productadvies',
              functioneleIconMap[EFunctioneleIcon.Productadvies],
            ),
          },
          {
            type: ELink.Link,
            path: '/opvolging/leveropdrachten',
            weergave: linkWeergaveRenderer(
              'Opvolging',
              functioneleIconMap[EFunctioneleIcon.Vertegenwoordiging],
            ),
          },
          {
            type: ELink.Link,
            path: '/reviews/uitnodigingen',
            weergave: linkWeergaveRenderer('Reviews', IconReview),
          },
          {
            type: ELink.Link,
            path: '/locaties',
            weergave: linkWeergaveRenderer('Locaties', IconLocatie),
          },
          {
            type: ELink.Link,
            path: '/complexen',
            weergave: linkWeergaveRenderer('Complexen', IconLocatie),
          },
          // Alleen voor organisaties
          props.relatie.Relatiesoort === 'P'
            ? null
            : {
                type: ELink.Link,
                path: '/afdelingen',
                weergave: linkWeergaveRenderer('Afdelingen', IconAfdeling),
              },
          {
            type: ELink.Link,
            path: '/vertegenwoordiging/relaties',
            weergave: linkWeergaveRenderer(
              'Vertegenwoordiging',
              functioneleIconMap[EFunctioneleIcon.Vertegenwoordiging],
            ),
          },
          {
            type: ELink.Link,
            path: '/partnerships',
            weergave: linkWeergaveRenderer(
              'Partnerships',
              functioneleIconMap[EFunctioneleIcon.Partnership],
            ),
          },
          {
            type: ELink.Link,
            path: '/signaleringen',
            weergave: linkWeergaveRenderer(
              'Signaleringen',
              functioneleIconMap[EFunctioneleIcon.Signaleringen],
            ),
          },
          {
            type: ELink.Link,
            path: '/events',
            weergave: linkWeergaveRenderer('Events', IconEvents),
          },
        ].filter((x) => x !== null) as Link<EGroepLink>[],
      },
    ],
    [
      linkWeergaveRenderer,
      props.relatie.Relatiesoort,
      infoIndicatieStore.indicaties,
      infoIndicatieContractenParamsKey,
    ],
  );

  return (
    <KaartNavigatie
      links={links}
      uitgeklapteGroepLinks={uitgeklapteGroepLinks}
      onUitgeklapteGroepLinksChange={setUitgeklapteGroepLinks}
      pathPrefix={props.match.url}
    />
  );
});

export default withRouter(Navigatie);
