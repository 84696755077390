import {
  IOphalenGebouwenParams,
  IOphalenGebouwenResult,
  IToevoegenGebouwParams,
  IToevoegenGebouwResult,
  IWijzigenGebouwParams,
  IWijzigenGebouwResult,
  IVerwijderenGebouwParams,
  IVerwijderenGebouwResult,
} from '../../../../../shared/src/api/v2/gebouw';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const gebouw = {
  ophalenGebouwen: async (params: IOphalenGebouwenParams): Promise<IOphalenGebouwenResult> => {
    return await api.post('/v2/gebouw/ophalen-gebouwen', params);
  },
  toevoegenGebouw: async (params: IToevoegenGebouwParams): Promise<IToevoegenGebouwResult> => {
    return await api.post('/v2/gebouw/toevoegen-gebouw', params);
  },
  checkToevoegenGebouw: async (params: IToevoegenGebouwParams): Promise<ICheckData> => {
    return await api.post('/v2/gebouw/check-toevoegen-gebouw', params);
  },
  wijzigenGebouw: async (params: IWijzigenGebouwParams): Promise<IWijzigenGebouwResult> => {
    return await api.post('/v2/gebouw/wijzigen-gebouw', params);
  },
  checkWijzigenGebouw: async (params: IWijzigenGebouwParams): Promise<ICheckData> => {
    return await api.post('/v2/gebouw/check-wijzigen-gebouw', params);
  },
  verwijderenGebouw: async (
    params: IVerwijderenGebouwParams,
  ): Promise<IVerwijderenGebouwResult> => {
    return await api.post('/v2/gebouw/verwijderen-gebouw', params);
  },
  checkVerwijderenGebouw: async (params: IVerwijderenGebouwParams): Promise<ICheckData> => {
    return await api.post('/v2/gebouw/check-verwijderen-gebouw', params);
  },
};

export default gebouw;
