import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import AdresFormulier, { IValues, IValues as IAdresFormikValues } from '../../AdresFormulier';
import { IOphalenLocatieResult } from '../../../../../../shared/src/api/v2/locatie/locatie';
import api from '../../../../api';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import VinkVeld from '../../VinkVeld';
import * as Yup from 'yup';
import teksten from '../../../../bedrijfslogica/teksten';
import HorizontaleScheidingslijn from '../../../layout/HorizontaleScheidingslijn';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import useGebruikersStempel, { IPlaatsStempelOutput } from '../../../../core/useGebruikersStempel';
import Tabblad, { ITabblad } from '../../../layout/Tabblad';
import AlgemeenTabblad, { IAlgemeenTabbladProps } from './AlgemeenTabblad';
import AdresTabblad, { IAdresTabbladProps } from './AdresTabblad';
import { ITijdvak } from '../../TijdvakVeld';
import GebouwenTabblad, { IGebouwenTabbladProps } from './GebouwenTabblad';
import { IOphalenGebouwenResult } from '../../../../../../shared/src/api/v2/gebouw';

export enum ETabblad {
  Algemeen,
  Adres,
  Gebouwen,
}

export interface IDialoogResult {
  locID: number;
}

export enum ELocatieMutatieDialoogType {
  Nieuw,
  Muteren,
  Corrigeren,
}

const locatieMutatieDialoogTypeMap: { [type: number]: string } = {
  [ELocatieMutatieDialoogType.Nieuw]: 'Nieuwe',
  [ELocatieMutatieDialoogType.Muteren]: 'Muteren',
  [ELocatieMutatieDialoogType.Corrigeren]: 'Corrigeren',
};

export interface IFormikValues {
  adres: IAdresFormikValues | null;
  bijzonderheden: string;
  bezoekinstructies: string;
  liftAanwezig: boolean;
  bereikbaarTijdvak: ITijdvak | null;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  type: ELocatieMutatieDialoogType;
  locID?: number;
  initieelTabblad?: ETabblad;
}

export const veldnamen = {
  adres: 'Adres',
  bijzonderheden: 'Notities locatie',
  liftAanwezig: 'Lift aanwezig',
  bezoekinstructies: 'Bezoekinstructies',
  bereikbaarTijdvak: 'Bereikbaarheid van het adres (b.v. Tot 11:00, dat dan in het 2e veld zetten)',
};

const LocatieMutatieDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, type, locID } = props;

  const [tabblad, setTabblad] = useState(props.initieelTabblad ?? ETabblad.Algemeen);

  const [locatie, setLocatie] = useState<IOphalenLocatieResult | null>(null);
  const [bestaandeLocatieBepaald, setBestaandeLocatieBepaald] = useState<
    IRemoteData<IOphalenLocatieResult | null>
  >(createPendingRemoteData());

  const [gebouwenResult, setGebouwenResult] = useState<IRemoteData<IOphalenGebouwenResult | null>>(
    createPendingRemoteData(),
  );
  const ophalenGebouwen = useCallback(async () => {
    if (locID === undefined) {
      setGebouwenResult(createReadyRemoteData(null));
      return;
    }

    const result = await api.v2.gebouw.ophalenGebouwen({
      filterSchema: {
        filters: [
          {
            naam: 'LOC_IDS',
            data: [locID],
          },
        ],
      },
    });

    setGebouwenResult(createReadyRemoteData(result));
  }, [locID]);

  useEffect(() => {
    ophalenGebouwen();
  }, [ophalenGebouwen]);

  useEffect(() => {
    if (locID === undefined) {
      setLocatie(null);
      return;
    }

    (async () => {
      const loc = (
        await api.v2.locatie.ophalenLocaties({
          filterSchema: { filters: [{ naam: 'IDS', data: [locID!] }] },
        })
      ).locaties[0];
      setLocatie(loc);
    })();
  }, [locID]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (locID !== undefined && locatie === null) {
      return null;
    }

    if (locID !== undefined && locatie !== null) {
      return {
        adres: {
          bisnummer: locatie.Bisnummer || '',
          huisnummer: locatie.Huisnummer,
          landID: locatie.LandID,
          plaatsnaam: locatie.Plaatsnaam,
          postcode: locatie.Postcode,
          straatnaam: locatie.Straatnaam,
        },
        bijzonderheden: locatie.Notities || '',
        bezoekinstructies: locatie.Bezoekinstructies || '',
        liftAanwezig: locatie.LiftAanwezig,
        bereikbaarTijdvak:
          locatie.BereikbaarVan === null && locatie.BereikbaarTot === null
            ? null
            : {
                begin: locatie.BereikbaarVan !== null ? locatie.BereikbaarVan : null,
                eind: locatie.BereikbaarTot !== null ? locatie.BereikbaarTot : null,
              },
      };
    }

    return {
      adres: null,
      bijzonderheden: '',
      bezoekinstructies: '',
      liftAanwezig: false,
      bereikbaarTijdvak: { begin: null, eind: null },
    };
  }, [locID, locatie]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      adres: Yup.object().required('Adres gegevens zijn incorrect'),
      bezoekinstructies: Yup.string().max(
        250,
        teksten.formulier.E_TEKST_MAXIMAAL({
          veldnaam: veldnamen.bezoekinstructies,
          aantal: 250,
        }),
      ),
    });
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const adres = values.adres!;
      const bezoekinstructies = (() => {
        const val = values.bezoekinstructies.trim();
        return val === '' ? null : val;
      })();
      const notities = (() => {
        const val = values.bijzonderheden.trim();
        return val === '' ? null : val;
      })();

      if (type === ELocatieMutatieDialoogType.Corrigeren) {
        await api.v2.locatie.corrigerenLocatie({
          locID: props.locID!,
          straatnaam: adres.straatnaam,
          bezoekinstructies,
          bisnummer: adres.bisnummer === '' ? null : adres.bisnummer,
          huisnummer: adres.huisnummer,
          landID: adres.landID,
          liftAanwezig: values.liftAanwezig,
          notities,
          plaatsnaam: adres.plaatsnaam,
          postcode: adres.postcode,
          bereikbaarVan: values.bereikbaarTijdvak !== null ? values.bereikbaarTijdvak.begin : null,
          bereikbaarTot: values.bereikbaarTijdvak !== null ? values.bereikbaarTijdvak!.eind : null,
        });

        onSuccess({
          locID: props.locID!,
        });
        return;
      }

      const muterenResult = await api.v2.locatie.muterenLocatie({
        straatnaam: adres.straatnaam,
        bezoekinstructies,
        bisnummer: adres.bisnummer === '' ? null : adres.bisnummer,
        huisnummer: adres.huisnummer,
        landID: adres.landID,
        liftAanwezig: values.liftAanwezig,
        notities,
        plaatsnaam: adres.plaatsnaam,
        postcode: adres.postcode,
      });

      onSuccess({
        locID: muterenResult.LocID,
      });
      actions.setSubmitting(false);
    },
    [onSuccess, type, props.locID],
  );

  const onAdresGewijzigd = useCallback(
    async (formikProps: FormikProps<IFormikValues>, adres: IAdresFormikValues | null) => {
      setBestaandeLocatieBepaald(createPendingRemoteData());
      if (adres === null) {
        return;
      }

      const values = { ...formikProps.values, adres };
      const isValid = await validationSchema.isValid(values);
      if (isValid) {
        const result = await api.v2.locatie.bepalenLocatieIsAanwezig({
          bisnummer: adres.bisnummer === '' ? null : adres.bisnummer,
          huisnummer: adres.huisnummer,
          landID: adres.landID,
          plaatsnaam: adres.plaatsnaam,
          postcode: adres.postcode,
          straatnaam: adres.straatnaam,
        });

        if (result !== null) {
          const loc = (
            await api.v2.locatie.ophalenLocaties({
              filterSchema: { filters: [{ naam: 'IDS', data: [result] }] },
            })
          ).locaties[0];
          if (type !== ELocatieMutatieDialoogType.Corrigeren) {
            formikProps.setFieldValue('liftAanwezig', loc.LiftAanwezig);
            formikProps.setFieldValue('bezoekinstructies', loc.Bezoekinstructies || '');
            formikProps.setFieldValue('bijzonderheden', loc.Notities || '');
          }

          setBestaandeLocatieBepaald(createReadyRemoteData(loc));
        } else {
          setBestaandeLocatieBepaald(createReadyRemoteData(null));
        }
      }
    },

    [validationSchema, setBestaandeLocatieBepaald, type],
  );

  const bestaandeLocatieBijCorrectie = useMemo(
    () =>
      bestaandeLocatieBepaald.state === ERemoteDataState.Ready &&
      bestaandeLocatieBepaald.data !== null &&
      type === ELocatieMutatieDialoogType.Corrigeren &&
      bestaandeLocatieBepaald.data!.LocID !== locatie!.LocID,
    [bestaandeLocatieBepaald, type],
  );

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        dialogClassName: 'dialog-width-550',
      }}
    >
      <ModalHeader>
        <ModalTitle>{locatieMutatieDialoogTypeMap[type]} locatie</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            return (
              <Formulier
                formikProps={formikProps}
                onAdresGewijzigd={onAdresGewijzigd}
                bestaandeLocatieBepaald={bestaandeLocatieBepaald}
                type={type}
                bestaandeLocatieBijCorrectie={bestaandeLocatieBijCorrectie}
                onAnnuleren={onAnnuleren}
                tabblad={tabblad}
                onTabbladChange={setTabblad}
                gebouwenResult={gebouwenResult}
                verversenGebouwen={ophalenGebouwen}
                locID={locID}
              />
            );
          }}
        />
      )}
    </Dialoog>
  );
};

interface IFormulierProps {
  formikProps: FormikProps<IFormikValues>;
  onAdresGewijzigd: (formikProps: FormikProps<IFormikValues>, x: IValues | null) => Promise<void>;
  bestaandeLocatieBepaald: IRemoteData<IOphalenLocatieResult | null>;
  type: ELocatieMutatieDialoogType;
  bestaandeLocatieBijCorrectie: boolean;
  onAnnuleren: () => void;
  tabblad: ETabblad;
  onTabbladChange: (tabblad: ETabblad) => void;
  gebouwenResult: IRemoteData<IOphalenGebouwenResult | null>;
  verversenGebouwen: () => Promise<void>;
  locID?: number;
}

const Formulier = ({
  formikProps,
  onAdresGewijzigd,
  bestaandeLocatieBepaald,
  type,
  bestaandeLocatieBijCorrectie,
  onAnnuleren,
  tabblad,
  onTabbladChange,
  gebouwenResult,
  verversenGebouwen,
  locID,
}: IFormulierProps) => {
  const { submitForm, isSubmitting, values, setFieldValue } = formikProps;

  // Initieel moeten we het adres wijzigen zodat er eventueel een bestaande locatie bepaald kan worden
  useEffect(() => {
    onAdresGewijzigd(formikProps, values.adres);
  }, []);

  const [
    bijzonderhedenPlaatsStempelOutput,
    setBijzonderhedenPlaatsStempelOutput,
  ] = useState<IPlaatsStempelOutput | null>(null);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () =>
      [
        {
          id: ETabblad.Algemeen,
          label: 'Algemeen',
          content: AlgemeenTabblad,
        },
        {
          id: ETabblad.Adres,
          label: 'Adres',
          content: AdresTabblad,
        },
        type === ELocatieMutatieDialoogType.Nieuw
          ? null
          : {
              id: ETabblad.Gebouwen,
              label: 'Gebouwen',
              content: GebouwenTabblad,
            },
      ].filter((x) => x !== null) as ITabblad<ETabblad>[],
    [
      type,
      onAdresGewijzigd,
      formikProps,
      bestaandeLocatieBepaald,
      bestaandeLocatieBijCorrectie,
      bijzonderhedenPlaatsStempelOutput,
      setBijzonderhedenPlaatsStempelOutput,
    ],
  );

  return (
    <>
      <ModalBody style={{ padding: 0, minHeight: 350 }} className="d-flex flex-column">
        <Tabblad<ETabblad, IAlgemeenTabbladProps & IAdresTabbladProps & IGebouwenTabbladProps>
          geselecteerd={tabblad}
          onSelectieChange={onTabbladChange}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              values: formikProps.values,
              setFieldValue: formikProps.setFieldValue,
              bestaandeLocatieBepaald,
              bijzonderhedenPlaatsStempelOutput,
              onBijzonderhedenPlaatsStempelOutputChange: setBijzonderhedenPlaatsStempelOutput,
              formikProps,
              type,
              bestaandeLocatieBijCorrectie,
              onAdresGewijzigd,
              gebouwenResult,
              verversenGebouwen,
              locID: locID!,
            },
          }}
        />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        <button
          className="btn btn-primary"
          onClick={submitForm}
          disabled={
            isSubmitting ||
            bestaandeLocatieBepaald.state === ERemoteDataState.Pending ||
            bestaandeLocatieBijCorrectie
          }
          style={{ width: 100 }}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default LocatieMutatieDialoog;
