import React, { useCallback, useState } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';
import UitlegTooltip from '../../../../../../components/formulier/UitlegTooltip';

export interface IAanbiedenVoorVerzendenDialoogResult {
  directVersturen: boolean;
}

interface IProps extends IDialoogProps<IAanbiedenVoorVerzendenDialoogResult> {}

const AanbiedenVoorVerzendenDialoog = (props: IProps) => {
  const [directVersturen, setDirectVersturen] = useState(false);

  const handleOkClick = useCallback(() => {
    props.onSuccess({
      directVersturen,
    });
  }, [directVersturen]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Aanbieden voor verzenden</ModalTitle>
      </ModalHeader>

      <ModalBody style={{ padding: '2rem', fontSize: 15 }}>
        <p>Bevestig dat je de berichten wilt aanbieden voor verzenden.</p>
        <p>De berichten zullen daarop door de verzendservice worden verstuurd.</p>
        <div className="d-flex align-items-center mt-4">
          <VinkVeld aangevinkt={directVersturen} onGewijzigd={setDirectVersturen} />
          <span className="ml-2 mr-2">Direct versturen</span>
          <UitlegTooltip inhoud="Bij direct versturen worden de berichten op status in afwachting gezet, waardoor ze meteen beschikbaar worden gesteld aan de verzender. Bij niet direct versturen dienen de berichten eerst nog reviewed worden bij de verzender." />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={handleOkClick}
          style={{ width: 100 }}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default AanbiedenVoorVerzendenDialoog;
