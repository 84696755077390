import { create } from 'zustand';
import {
  IWhatsappBericht,
  IWhatsappChatsessie,
  IWhatsappMedia,
  IWhatsappTijdvensterAanvraag,
} from '../../../api/services/v1/whatsapp';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import api from '../../../api';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';
import { IOphalenMediaTypesV2ResultElement } from '../../../../../shared/src/api/v2/mediatype';
import { Bestand } from '../../BijlagenContainer';
import { addDays } from 'date-fns';
import { EWhatsappTabblad } from './types';

export interface IChatsessiesData {
  chatsessieIDBijIndex: Record<number, IRemoteData<number>>;
  totaalAantal: number;
}

export interface IBerichtenData {
  berichtIDBijIndex: Record<number, IRemoteData<number>>;
  totaalAantal: number;
}

export interface IMeerChatsessiesLadenParams {
  status: 'nieuw' | 'lopend' | 'archief';
  index: number;
  aantal: number;
}

export interface IChatsessieState {
  bericht: string;
  bijlage: Bestand | null;
}

export interface IMeerBerichtenLadenParams {
  chatsessieID: number;
  index: number;
  aantal: number;
}

export interface IWhatsappBerichtNieuwBericht {
  id: number;
}

export interface IWhatsappBerichtBijgewerktBericht {
  id: number;
}

export interface IWhatsappChatsessieBijgewerktBericht {
  id: number;
}

export interface IBerichtVersturenState {
  bijlageUploadProgress: number | null;
}

export interface ITijdvenster {
  totEnMet: Date;
}

export interface IWhatsappConversatieVoortzettenAangevraagdBericht {
  chatsessieId: number;
  tijdvensterAanvraagId: number;
}

interface IZoekResultaat {
  zoekterm: string;
  chatsessiesData: IRemoteData<IChatsessiesData>;
  berichtenData: IRemoteData<IBerichtenData>;
}

interface IMeerZoekResultatenChatsessiesLadenParams {
  index: number;
  aantal: number;
}

interface IMeerZoekResultatenBerichtenLadenParams {
  index: number;
  aantal: number;
}

interface IScrollNaarIndexVoorTabblad {
  tabblad: EWhatsappTabblad;
  index: number;
}

export interface IWhatsappV2Store {
  zoekResultaat: IRemoteData<IZoekResultaat | null>;
  zoek: (zoekterm: string, signal: AbortSignal) => Promise<void>;
  meerZoekResultatenChatsessiesLaden: (
    params: IMeerZoekResultatenChatsessiesLadenParams,
    signal: AbortSignal,
  ) => Promise<void>;
  meerZoekResultatenBerichtenLaden: (
    params: IMeerZoekResultatenBerichtenLadenParams,
    signal: AbortSignal,
  ) => Promise<void>;
  leegZoekResultaat: () => void;
  chatsessieBijID: IRemoteData<Record<number, IWhatsappChatsessie>>;
  mediaBijID: Record<number, IWhatsappMedia>;
  bestandBijID: Record<number, IOphalenBestandenResultElement>;
  mediaTypeBijID: Record<number, IOphalenMediaTypesV2ResultElement>;
  berichtBijID: Record<number, IWhatsappBericht>;
  chatsessiesDataNieuw: IRemoteData<IChatsessiesData>;
  chatsessiesDataLopend: IRemoteData<IChatsessiesData>;
  chatsessiesDataArchief: IRemoteData<IChatsessiesData>;
  whatsappBerichtenDataBijChatsessieId: Record<number, IRemoteData<IBerichtenData>>;
  chatsessieBerichtIDBijChatsessieId: Record<number, number>;
  berichtVersturenState: Record<number, IBerichtVersturenState | null>;
  setBerichtVersturenState: (chatsessieId: number, state: IBerichtVersturenState | null) => void;
  tijdvensterBijChatsessieId: Record<number, ITijdvenster>;
  laatsteTijdvensterAanvraagBijChatsessieId: Record<
    number,
    IRemoteData<IWhatsappTijdvensterAanvraag | null>
  >;
  bepalenLaatsteTijdvensterAanvraagVoorChatsessie: (chatsessieID: number) => Promise<void>;
  isGeinitialiseerd: boolean;
  chatsessieState: Record<number, IChatsessieState>;
  setChatsessieState: (chatsessieID: number, partial: Partial<IChatsessieState>) => void;
  initialiseer: (initieleChatsessieID: number) => Promise<void>;
  initialiseerChatsessie: (chatsessieID: number) => Promise<void>;
  meerChatsessiesLaden: (params: IMeerChatsessiesLadenParams) => Promise<void>;
  meerBerichtenLaden: (params: IMeerBerichtenLadenParams) => Promise<void>;
  ophalenBerichtenInitieel: (chatsessieID: number) => Promise<IBerichtenData>;
  handleWhatsappBerichtNieuw: (bericht: IWhatsappBerichtNieuwBericht) => Promise<void>;
  handleWhatsappBerichtBijgewerkt: (bericht: IWhatsappBerichtBijgewerktBericht) => Promise<void>;
  handleWhatsappChatsessieBijgewerkt: (
    bericht: IWhatsappChatsessieBijgewerktBericht,
  ) => Promise<void>;
  handleWhatsappConversatieVoortzettenAangevraagd: (
    bericht: IWhatsappConversatieVoortzettenAangevraagdBericht,
  ) => Promise<void>;
  scrollNaarIndexVoorTabblad: IScrollNaarIndexVoorTabblad | null;
  setScrollNaarIndexVoorTabblad: (
    scrollNaarIndexVoorTabblad: IScrollNaarIndexVoorTabblad | null,
  ) => void;
}

const mediasBepalenAfhandelen = async (mediaIDs: number[], set: any, get: any) => {
  const mediaBijID = get().mediaBijID;
  const opTeHalenMediaIDs = mediaIDs.filter((mediaID) => mediaBijID[mediaID] === undefined);
  if (opTeHalenMediaIDs.length === 0) {
    return;
  }
  const output = await api.services.v1.whatsapp.ophalenMedias({
    filters: {
      enkel: {
        kolom: 'id',
        filter: {
          nummeriek: {
            in: opTeHalenMediaIDs,
          },
        },
      },
    },
  });
  const nieuweMediaBijID = output.medias.reduce<Record<number, IWhatsappMedia>>(
    (acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    },
    { ...mediaBijID },
  );

  set({
    mediaBijID: nieuweMediaBijID,
  });

  const bestandIDs = output.medias.map((x) => x.bestandId).filter((x) => x !== null) as number[];
  if (bestandIDs.length === 0) {
    return;
  }

  const result = await api.v2.bestand.ophalenBestanden({
    filterSchema: {
      filters: [
        {
          naam: 'IDS',
          data: bestandIDs,
        },
      ],
    },
  });

  const bestandBijID = get().bestandBijID;
  const nieuweBestandBijID = result.bestanden.reduce<
    Record<number, IOphalenBestandenResultElement>
  >(
    (acc, curr) => {
      acc[curr.ID] = curr;
      return acc;
    },
    { ...bestandBijID },
  );

  set({
    bestandBijID: nieuweBestandBijID,
  });

  const mediaTypeIDs = result.bestanden.map((x) => x.MediaTypeID);
  if (mediaTypeIDs.length === 0) {
    return;
  }

  const mediaTypeBijID = get().mediaTypeBijID;
  const nogNietOpgeslagenMediaTypeIDs = mediaTypeIDs.filter(
    (mediaTypeID) => mediaTypeBijID[mediaTypeID] === undefined,
  );
  if (nogNietOpgeslagenMediaTypeIDs.length === 0) {
    return;
  }

  const mediaTypeResult = await api.v2.mediaType.ophalenMediaTypes({
    filterSchema: {
      filters: [
        {
          naam: 'IDS',
          data: nogNietOpgeslagenMediaTypeIDs,
        },
      ],
    },
  });

  const nieuweMediaTypeBijID = mediaTypeResult.mediaTypes.reduce<
    Record<number, IOphalenMediaTypesV2ResultElement>
  >(
    (acc, curr) => {
      acc[curr.ID] = curr;
      return acc;
    },
    { ...mediaTypeBijID },
  );

  set({
    mediaTypeBijID: nieuweMediaTypeBijID,
  });
};

export const useWhatsappV2Store = create<IWhatsappV2Store>((set, get) => {
  return {
    zoekResultaat: createReadyRemoteData(null),
    chatsessieBijID: createPendingRemoteData(),
    mediaBijID: {},
    bestandBijID: {},
    mediaTypeBijID: {},
    berichtBijID: {},
    chatsessiesDataNieuw: createPendingRemoteData(),
    chatsessiesDataLopend: createPendingRemoteData(),
    chatsessiesDataArchief: createPendingRemoteData(),
    whatsappBerichtenDataBijChatsessieId: {},
    chatsessieBerichtIDBijChatsessieId: {},
    berichtVersturenState: {},
    setBerichtVersturenState: (chatsessieId, state) => {
      const berichtVersturenState = { ...get().berichtVersturenState };
      berichtVersturenState[chatsessieId] = state;
      set({
        berichtVersturenState,
      });
    },
    tijdvensterBijChatsessieId: {},
    isGeinitialiseerd: false,
    chatsessieState: {},
    setChatsessieState: (chatsessieID, partial) => {
      const chatsessieState = { ...get().chatsessieState };
      chatsessieState[chatsessieID] = {
        ...chatsessieState[chatsessieID],
        ...partial,
      };
      set({ chatsessieState });
    },
    laatsteTijdvensterAanvraagBijChatsessieId: {},
    initialiseer: async (initieleChatsessieID) => {
      set({
        isGeinitialiseerd: true,
      });

      const [output1, output2, output3] = await Promise.all([
        api.services.v1.whatsapp.ophalenChatsessiesUitgebreid({
          paginatie: {
            index: 0,
            aantal: 50,
          },
          filters: {
            enkel: {
              kolom: 'status',
              filter: {
                nummeriek: {
                  gelijkAan: 1,
                },
              },
            },
          },
          sorteringen: [
            {
              kolom: 'meestRecenteBerichtDatum',
              richting: 'DESC',
            },
          ],
        }),
        api.services.v1.whatsapp.ophalenChatsessiesUitgebreid({
          paginatie: {
            index: 0,
            aantal: 50,
          },
          filters: {
            enkel: {
              kolom: 'status',
              filter: {
                nummeriek: {
                  gelijkAan: 2,
                },
              },
            },
          },
          sorteringen: [
            {
              kolom: 'meestRecenteBerichtDatum',
              richting: 'DESC',
            },
          ],
        }),
        api.services.v1.whatsapp.ophalenChatsessiesUitgebreid({
          paginatie: {
            index: 0,
            aantal: 50,
          },
          filters: {
            enkel: {
              kolom: 'status',
              filter: {
                nummeriek: {
                  gelijkAan: 3,
                },
              },
            },
          },
          sorteringen: [
            {
              kolom: 'meestRecenteBerichtDatum',
              richting: 'DESC',
            },
          ],
        }),
      ]);

      const chatsessies = [...output1.chatsessies, ...output2.chatsessies, ...output3.chatsessies];
      const chatsessieIDs = chatsessies.map((chatsessie) => chatsessie.id);

      const [laatsteBerichtenOutput, laatsteInkomendeBerichtenOutput] = await Promise.all([
        api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies({
          whatsappChatsessieIds: chatsessieIDs,
          filters: {
            enkel: {
              kolom: 'whatsappBerichttypeId',
              filter: {
                isNull: true,
              },
            },
          },
        }),
        api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies({
          whatsappChatsessieIds: chatsessieIDs,
          filters: {
            enkel: {
              kolom: 'richting',
              filter: {
                string: {
                  gelijkAan: 'I',
                },
              },
            },
          },
        }),
      ]);

      const chatsessiesBijID = chatsessies.reduce<Record<number, IWhatsappChatsessie>>(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {},
      );

      if (initieleChatsessieID !== null && chatsessiesBijID[initieleChatsessieID] === undefined) {
        const output = await api.services.v1.whatsapp.ophalenChatsessies({
          filters: {
            enkel: {
              kolom: 'id',
              filter: {
                nummeriek: {
                  gelijkAan: initieleChatsessieID,
                },
              },
            },
          },
        });
        chatsessiesBijID[initieleChatsessieID] = output.chatsessies[0];
      }

      const chatsessieIDsNieuwBijIndex = output1.chatsessies.reduce<
        Record<number, IRemoteData<number>>
      >((acc, curr, index) => {
        acc[index] = createReadyRemoteData(curr.id);
        return acc;
      }, {});
      const chatsessieIDsLopendBijIndex = output2.chatsessies.reduce<
        Record<number, IRemoteData<number>>
      >((acc, curr, index) => {
        acc[index] = createReadyRemoteData(curr.id);
        return acc;
      }, {});
      const chatsessieIDsArchiefBijIndex = output3.chatsessies.reduce<
        Record<number, IRemoteData<number>>
      >((acc, curr, index) => {
        acc[index] = createReadyRemoteData(curr.id);
        return acc;
      }, {});

      const berichtBijID = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId).reduce<
        Record<number, IWhatsappBericht>
      >((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});

      const chatsessieBerichtIDBijChatsessieId = Object.keys(
        laatsteBerichtenOutput.berichtenPerChatsessieId,
      ).reduce<Record<number, number>>((acc, key) => {
        const chatsessieID = parseInt(key, 10);
        const bericht = laatsteBerichtenOutput.berichtenPerChatsessieId[chatsessieID];

        acc[chatsessieID] = bericht.id;
        return acc;
      }, {});

      const tijdvensterBijChatsessieId = Object.keys(
        laatsteInkomendeBerichtenOutput.berichtenPerChatsessieId,
      ).reduce<Record<number, ITijdvenster>>((acc, key) => {
        const chatsessieID = parseInt(key, 10);
        const bericht = laatsteInkomendeBerichtenOutput.berichtenPerChatsessieId[chatsessieID];

        const datum = new Date(bericht.datum);
        const totEnMet = addDays(datum, 1);

        acc[chatsessieID] = {
          totEnMet,
        };

        return acc;
      }, {});

      set({
        chatsessieBijID: createReadyRemoteData(chatsessiesBijID),
        chatsessiesDataNieuw: createReadyRemoteData({
          chatsessieIDBijIndex: chatsessieIDsNieuwBijIndex,
          totaalAantal: output1.totaalAantal,
        }),
        chatsessiesDataLopend: createReadyRemoteData({
          chatsessieIDBijIndex: chatsessieIDsLopendBijIndex,
          totaalAantal: output2.totaalAantal,
        }),
        chatsessiesDataArchief: createReadyRemoteData({
          chatsessieIDBijIndex: chatsessieIDsArchiefBijIndex,
          totaalAantal: output3.totaalAantal,
        }),
        chatsessieBerichtIDBijChatsessieId,
        berichtBijID,
        tijdvensterBijChatsessieId,
      });

      const mediaIDs = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId)
        .filter((bericht) => bericht.whatsappMediaId !== null)
        .map((bericht) => bericht.whatsappMediaId!);
      await mediasBepalenAfhandelen(mediaIDs, set, get);
    },
    initialiseerChatsessie: async (chatsessieID) => {
      let chatsessieBijID = get().chatsessieBijID;
      if (
        chatsessieBijID.state === ERemoteDataState.Pending ||
        chatsessieBijID.data![chatsessieID] !== undefined
      ) {
        return;
      }

      const ophalenChatsessiesOutput = await api.services.v1.whatsapp.ophalenChatsessies({
        paginatie: {
          index: 0,
          aantal: 1,
        },
        filters: {
          enkel: {
            kolom: 'id',
            filter: {
              nummeriek: {
                gelijkAan: chatsessieID,
              },
            },
          },
        },
      });
      const chatsessie = ophalenChatsessiesOutput.chatsessies[0];
      if (chatsessie === undefined) {
        console.error('Chatsessie niet gevonden');
        return;
      }

      chatsessieBijID = createReadyRemoteData({
        ...chatsessieBijID.data!,
        [chatsessieID]: chatsessie,
      });

      const [laatsteBerichtenOutput, laatsteInkomendeBerichtenOutput] = await Promise.all([
        api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies({
          whatsappChatsessieIds: [chatsessieID],
        }),
        api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies({
          whatsappChatsessieIds: [chatsessieID],
          filters: {
            enkel: {
              kolom: 'richting',
              filter: {
                string: {
                  gelijkAan: 'I',
                },
              },
            },
          },
        }),
      ]);

      const berichtBijID = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId).reduce<
        Record<number, IWhatsappBericht>
      >(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        { ...get().berichtBijID },
      );

      const chatsessieBerichtIDBijChatsessieId = Object.keys(
        laatsteBerichtenOutput.berichtenPerChatsessieId,
      ).reduce<Record<number, number>>(
        (acc, key) => {
          const chatsessieID = parseInt(key, 10);
          const bericht = laatsteBerichtenOutput.berichtenPerChatsessieId[chatsessieID];

          acc[chatsessieID] = bericht.id;
          return acc;
        },
        { ...get().chatsessieBerichtIDBijChatsessieId },
      );

      const tijdvensterBijChatsessieId = Object.keys(
        laatsteInkomendeBerichtenOutput.berichtenPerChatsessieId,
      ).reduce<Record<number, ITijdvenster>>(
        (acc, key) => {
          const chatsessieID = parseInt(key, 10);
          const bericht = laatsteInkomendeBerichtenOutput.berichtenPerChatsessieId[chatsessieID];

          const datum = new Date(bericht.datum);
          const totEnMet = addDays(datum, 1);

          acc[chatsessieID] = {
            totEnMet,
          };

          return acc;
        },
        { ...get().tijdvensterBijChatsessieId },
      );

      set({
        chatsessieBijID,
        chatsessieBerichtIDBijChatsessieId,
        berichtBijID,
        tijdvensterBijChatsessieId,
      });

      const mediaIDs = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId)
        .filter((bericht) => bericht.whatsappMediaId !== null)
        .map((bericht) => bericht.whatsappMediaId!);
      await mediasBepalenAfhandelen(mediaIDs, set, get);
    },
    meerChatsessiesLaden: async (params) => {
      let data: IChatsessiesData;
      let statusNumber: number;
      switch (params.status) {
        case 'nieuw':
          data = get().chatsessiesDataNieuw.data!;
          statusNumber = 1;
          break;
        case 'lopend':
          data = get().chatsessiesDataLopend.data!;
          statusNumber = 2;
          break;
        case 'archief':
          data = get().chatsessiesDataArchief.data!;
          statusNumber = 3;
          break;
      }

      for (let i = params.index; i < params.index + params.aantal; i++) {
        data.chatsessieIDBijIndex[i] = createPendingRemoteData();
      }

      let partial: Partial<IWhatsappV2Store>;
      switch (params.status) {
        case 'nieuw':
          partial = {
            chatsessiesDataNieuw: createReadyRemoteData(data),
          };
          break;
        case 'lopend':
          partial = {
            chatsessiesDataLopend: createReadyRemoteData(data),
          };
          break;
        case 'archief':
          partial = {
            chatsessiesDataArchief: createReadyRemoteData(data),
          };
          break;
      }

      set(partial);

      const chatsessiesOutput = await api.services.v1.whatsapp.ophalenChatsessiesUitgebreid({
        paginatie: {
          index: params.index,
          aantal: params.aantal,
        },
        filters: {
          enkel: {
            kolom: 'status',
            filter: {
              nummeriek: {
                gelijkAan: statusNumber,
              },
            },
          },
        },
        sorteringen: [
          {
            kolom: 'meestRecenteBerichtDatum',
            richting: 'DESC',
          },
        ],
      });

      const chatsessieIDs = chatsessiesOutput.chatsessies.map((chatsessie) => chatsessie.id);

      const [laatsteBerichtenOutput, laatsteInkomendeBerichtenOutput] = await Promise.all([
        api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies({
          whatsappChatsessieIds: chatsessieIDs,
          filters: {
            enkel: {
              kolom: 'whatsappBerichttypeId',
              filter: {
                isNull: true,
              },
            },
          },
        }),
        api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies({
          whatsappChatsessieIds: chatsessieIDs,
          filters: {
            enkel: {
              kolom: 'richting',
              filter: {
                string: {
                  gelijkAan: 'I',
                },
              },
            },
          },
        }),
      ]);

      switch (params.status) {
        case 'nieuw':
          data = get().chatsessiesDataNieuw.data!;
          break;
        case 'lopend':
          data = get().chatsessiesDataLopend.data!;
          break;
        case 'archief':
          data = get().chatsessiesDataArchief.data!;
          break;
      }

      for (let i = 0; i < chatsessiesOutput.chatsessies.length; i++) {
        data.chatsessieIDBijIndex[i + params.index] = createReadyRemoteData(
          chatsessiesOutput.chatsessies[i].id,
        );
      }

      switch (params.status) {
        case 'nieuw':
          partial = {
            chatsessiesDataNieuw: createReadyRemoteData(data),
          };
          break;
        case 'lopend':
          partial = {
            chatsessiesDataLopend: createReadyRemoteData(data),
          };
          break;
        case 'archief':
          partial = {
            chatsessiesDataArchief: createReadyRemoteData(data),
          };
          break;
      }

      const chatsessiesBijID = {
        ...(get().chatsessieBijID.data ?? {}),
      };
      for (const chatsessie of chatsessiesOutput.chatsessies) {
        chatsessiesBijID[chatsessie.id] = chatsessie;
      }

      partial.chatsessieBijID = createReadyRemoteData(chatsessiesBijID);

      partial.berichtBijID = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId).reduce<
        Record<number, IWhatsappBericht>
      >(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        { ...get().berichtBijID },
      );

      partial.chatsessieBerichtIDBijChatsessieId = Object.keys(
        laatsteBerichtenOutput.berichtenPerChatsessieId,
      ).reduce(
        (acc, key) => {
          const chatsessieID = parseInt(key, 10);
          const bericht = laatsteBerichtenOutput.berichtenPerChatsessieId[chatsessieID];

          acc[chatsessieID] = bericht.id;
          return acc;
        },
        {
          ...get().chatsessieBerichtIDBijChatsessieId,
        },
      );

      partial.tijdvensterBijChatsessieId = Object.keys(
        laatsteInkomendeBerichtenOutput.berichtenPerChatsessieId,
      ).reduce<Record<number, ITijdvenster>>(
        (acc, key) => {
          const chatsessieID = parseInt(key, 10);
          const bericht = laatsteInkomendeBerichtenOutput.berichtenPerChatsessieId[chatsessieID];

          const datum = new Date(bericht.datum);
          const totEnMet = addDays(datum, 1);

          acc[chatsessieID] = {
            totEnMet,
          };

          return acc;
        },
        {
          ...get().tijdvensterBijChatsessieId,
        },
      );

      set(partial);

      const mediaIDs = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId)
        .filter((bericht) => bericht.whatsappMediaId !== null)
        .map((bericht) => bericht.whatsappMediaId!);
      await mediasBepalenAfhandelen(mediaIDs, set, get);
    },
    ophalenBerichtenInitieel: async (chatsessieID) => {
      const berichtenOutput = await api.services.v1.whatsapp.ophalenBerichten({
        paginatie: {
          index: 0,
          aantal: 50,
        },
        filters: {
          enkel: {
            kolom: 'whatsappChatsessieId',
            filter: {
              nummeriek: {
                gelijkAan: chatsessieID,
              },
            },
          },
        },
        sorteringen: [
          {
            kolom: 'datum',
            richting: 'DESC',
          },
        ],
      });

      const berichtIDBijIndex = berichtenOutput.berichten.reduce<
        Record<number, IRemoteData<number>>
      >((acc, curr, i) => {
        acc[berichtenOutput.totaalAantal - i - 1] = createReadyRemoteData(curr.id);
        return acc;
      }, {});

      const berichtenData: IBerichtenData = {
        totaalAantal: berichtenOutput.totaalAantal,
        berichtIDBijIndex,
      };

      const berichtenDataBijChatsessieId = {
        ...get().whatsappBerichtenDataBijChatsessieId,
        [chatsessieID]: createReadyRemoteData(berichtenData),
      };

      const berichtBijID = berichtenOutput.berichten.reduce<Record<number, IWhatsappBericht>>(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        { ...get().berichtBijID },
      );

      set({
        whatsappBerichtenDataBijChatsessieId: berichtenDataBijChatsessieId,
        berichtBijID,
      });

      const mediaIDs = berichtenOutput.berichten
        .filter((bericht) => bericht.whatsappMediaId !== null)
        .map((bericht) => bericht.whatsappMediaId!);
      // noinspection ES6MissingAwait
      mediasBepalenAfhandelen(mediaIDs, set, get);

      return berichtenData;
    },
    meerBerichtenLaden: async (params) => {
      const berichtenOutput = await api.services.v1.whatsapp.ophalenBerichten({
        paginatie: {
          index: params.index,
          aantal: params.aantal,
        },
        filters: {
          enkel: {
            kolom: 'whatsappChatsessieId',
            filter: {
              nummeriek: {
                gelijkAan: params.chatsessieID,
              },
            },
          },
        },
        sorteringen: [
          {
            kolom: 'datum',
            richting: 'ASC',
          },
        ],
      });

      const whatsappBerichtenDataBijChatsessieId = get().whatsappBerichtenDataBijChatsessieId;
      const berichtenData: IBerichtenData = whatsappBerichtenDataBijChatsessieId[
        params.chatsessieID
      ].data!;
      const berichtIDBijIndex = berichtenOutput.berichten.reduce<
        Record<number, IRemoteData<number>>
      >((acc, curr, i) => {
        acc[params.index + i] = createReadyRemoteData(curr.id);
        return acc;
      }, berichtenData.berichtIDBijIndex);
      const nieuweData: IBerichtenData = {
        ...berichtenData,
        berichtIDBijIndex,
      };

      whatsappBerichtenDataBijChatsessieId[params.chatsessieID] = createReadyRemoteData(nieuweData);

      const berichtBijID = berichtenOutput.berichten.reduce<Record<number, IWhatsappBericht>>(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        { ...get().berichtBijID },
      );

      set({
        whatsappBerichtenDataBijChatsessieId,
        berichtBijID,
      });

      const mediaIDs = berichtenOutput.berichten
        .filter((bericht) => bericht.whatsappMediaId !== null)
        .map((bericht) => bericht.whatsappMediaId!);
      await mediasBepalenAfhandelen(mediaIDs, set, get);
    },
    handleWhatsappBerichtNieuw: async (b) => {
      console.log('>>>NIEUW BERICHT', b);

      const chatsessieBijID = get().chatsessieBijID;
      if (chatsessieBijID.state === ERemoteDataState.Pending) {
        console.log('Chatsessie bij ID is nog niet geladen, eerder afbreken');
        return;
      }

      const ophalenOutput = await api.services.v1.whatsapp.ophalenBerichten({
        paginatie: {
          index: 0,
          aantal: 1,
        },
        filters: {
          enkel: {
            kolom: 'id',
            filter: {
              nummeriek: {
                gelijkAan: b.id,
              },
            },
          },
        },
      });
      const bericht = ophalenOutput.berichten[0];
      if (bericht === undefined) {
        console.error('Bericht niet gevonden whatsapp bericht nieuw');
        return;
      }

      const chatsessie = chatsessieBijID.data![bericht.whatsappChatsessieId];
      if (chatsessie === undefined) {
        // Deze chatsessie is nog niet geladen dus kunnen we het bericht negeren
        console.log('Deze chatsessie is nog niet geladen dus kunnen we het bericht negeren');
        return;
      }

      const berichtenDataBijChatsessieId = get().whatsappBerichtenDataBijChatsessieId;
      const berichtenData = berichtenDataBijChatsessieId[chatsessie.id];
      if (berichtenData.state === ERemoteDataState.Pending) {
        // Deze chatsessie heeft nog geen berichten dus kunnen we het bericht negeren
        console.log('Deze chatsessie heeft nog geen berichten dus kunnen we het bericht negeren');
        return;
      }

      const nieuweBerichtenBijIndex = {
        ...berichtenData.data!.berichtIDBijIndex,
        [berichtenData.data!.totaalAantal]: createReadyRemoteData(bericht.id),
      };

      const nieuweBerichtenData: IBerichtenData = {
        totaalAantal: berichtenData.data!.totaalAantal + 1,
        berichtIDBijIndex: nieuweBerichtenBijIndex,
      };

      berichtenDataBijChatsessieId[chatsessie.id] = createReadyRemoteData(nieuweBerichtenData);

      const berichtBijID = {
        ...get().berichtBijID,
        [bericht.id]: bericht,
      };

      const chatsessieBerichtIDBijChatsessieId = {
        ...get().chatsessieBerichtIDBijChatsessieId,
      };

      // Als het berichttype null is dan is het een speciaal bericht wat we niet willen
      // weergeven als chatsessie bericht
      if (bericht.whatsappBerichttypeId === null) {
        chatsessieBerichtIDBijChatsessieId[bericht.whatsappChatsessieId] = bericht.id;
      }

      let tijdvensterBijChatsessieId = get().tijdvensterBijChatsessieId;
      console.log('tijdvensterBijChatsessieId', tijdvensterBijChatsessieId);
      if (bericht.richting === 'I') {
        console.log('isinkomend');
        const datum = new Date(bericht.datum);
        console.log(datum);
        const totEnMet = addDays(datum, 1);
        console.log(totEnMet);
        tijdvensterBijChatsessieId = {
          ...tijdvensterBijChatsessieId,
          [bericht.whatsappChatsessieId]: {
            totEnMet,
          },
        };
        console.log('tijdvensterBijChatsessieId', tijdvensterBijChatsessieId);
      }

      set({
        whatsappBerichtenDataBijChatsessieId: berichtenDataBijChatsessieId,
        berichtBijID,
        chatsessieBerichtIDBijChatsessieId,
        tijdvensterBijChatsessieId,
      });

      if (bericht.whatsappMediaId === null) {
        return;
      }

      await mediasBepalenAfhandelen([bericht.whatsappMediaId!], set, get);
    },
    handleWhatsappBerichtBijgewerkt: async (b) => {
      const chatsessieBijID = get().chatsessieBijID;
      if (chatsessieBijID.state === ERemoteDataState.Pending) {
        return;
      }

      let berichtBijID = get().berichtBijID;
      const oudBericht = berichtBijID[b.id];
      if (oudBericht === undefined) {
        return;
      }

      const output = await api.services.v1.whatsapp.ophalenBerichten({
        paginatie: {
          index: 0,
          aantal: 1,
        },
        filters: {
          enkel: {
            kolom: 'id',
            filter: {
              nummeriek: {
                gelijkAan: b.id,
              },
            },
          },
        },
      });
      const nieuwBericht = output.berichten[0];
      if (nieuwBericht === undefined) {
        console.error('Bericht niet gevonden whatsapp bericht bijgewerkt');
        return;
      }

      berichtBijID = {
        ...berichtBijID,
        [b.id]: nieuwBericht,
      };

      set({
        berichtBijID,
      });
    },
    handleWhatsappChatsessieBijgewerkt: async (b) => {
      const chatsessieBijID = get().chatsessieBijID;
      if (chatsessieBijID.state === ERemoteDataState.Pending) {
        return;
      }
      const oudeChatsessie = chatsessieBijID.data![b.id];

      const [ophalenChatsessiesOutput, laatsteBerichtenOutput] = await Promise.all([
        api.services.v1.whatsapp.ophalenChatsessies({
          filters: {
            enkel: {
              kolom: 'id',
              filter: {
                nummeriek: {
                  gelijkAan: b.id,
                },
              },
            },
          },
          paginatie: {
            index: 0,
            aantal: 1,
          },
        }),
        api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies({
          whatsappChatsessieIds: [b.id],
          filters: {
            enkel: {
              kolom: 'whatsappBerichttypeId',
              filter: {
                isNull: true,
              },
            },
          },
        }),
      ]);

      const nieuweChatsessie = ophalenChatsessiesOutput.chatsessies[0];
      if (nieuweChatsessie === undefined) {
        console.error('Chatsessie niet gevonden whatsapp chatsessie bijgewerkt');
        return;
      }

      // Toevoegen aan nieuwe lijst
      let statusNumber: number;
      switch (nieuweChatsessie.status) {
        case 'nieuw':
          statusNumber = 1;
          break;
        case 'lopend':
          statusNumber = 2;
          break;
        case 'afgehandeld':
          statusNumber = 3;
          break;
      }
      const positiesOutput = await api.services.v1.whatsapp.bepalenPositiesVanChatsessies({
        chatsessieIds: [nieuweChatsessie.id],
        filters: {
          enkel: {
            kolom: 'status',
            filter: {
              nummeriek: {
                gelijkAan: statusNumber,
              },
            },
          },
        },
        sorteringen: [
          {
            kolom: 'meestRecenteBerichtDatum',
            richting: 'DESC',
          },
        ],
      });
      const index = positiesOutput.positieBijChatsessieId[nieuweChatsessie.id];
      if (index === undefined) {
        console.error('Chatsessie niet gevonden voor deze status whatsapp chatsessie bijgewerkt');
        return;
      }

      const nieuweChatsessieBijID: Record<number, IWhatsappChatsessie> = {
        ...get().chatsessieBijID.data!,
        [b.id]: nieuweChatsessie,
      };
      let chatsessiesDataNieuw = get().chatsessiesDataNieuw;
      let chatsessiesDataLopend = get().chatsessiesDataLopend;
      let chatsessiesDataArchief = get().chatsessiesDataArchief;

      if (oudeChatsessie !== undefined) {
        const verwijderUitData = (
          data: IRemoteData<IChatsessiesData>,
          chatsessieId: number,
        ): IRemoteData<IChatsessiesData> => {
          if (data.state === ERemoteDataState.Pending) {
            return data;
          }

          const d = data.data!;
          const chatsessieIndexStr = Object.keys(d.chatsessieIDBijIndex).find((key) => {
            const index = parseInt(key, 10);
            const chatsessieIdData = d.chatsessieIDBijIndex[index];
            if (chatsessieIdData.state === ERemoteDataState.Pending) {
              return false;
            }
            return chatsessieIdData.data! === chatsessieId;
          });

          if (chatsessieIndexStr === undefined) {
            return data;
          }
          const chatsessieIndex = parseInt(chatsessieIndexStr, 10);

          const nieuweData = Object.keys(d.chatsessieIDBijIndex).reduce<
            Record<number, IRemoteData<number>>
          >((acc, curr, i) => {
            const currIndex = parseInt(curr, 10);
            if (currIndex < chatsessieIndex) {
              acc[i] = d.chatsessieIDBijIndex[currIndex];
            } else if (currIndex > chatsessieIndex) {
              acc[i - 1] = d.chatsessieIDBijIndex[currIndex];
            }
            return acc;
          }, {});

          return createReadyRemoteData({
            chatsessieIDBijIndex: nieuweData,
            totaalAantal: d.totaalAantal - 1,
          });
        };

        // Dan moeten we het verplaatsen...
        // Verwijderen uit originele lijst
        switch (oudeChatsessie.status) {
          case 'nieuw':
            chatsessiesDataNieuw = verwijderUitData(chatsessiesDataNieuw, oudeChatsessie.id);
            break;
          case 'lopend':
            chatsessiesDataLopend = verwijderUitData(chatsessiesDataLopend, oudeChatsessie.id);
            break;
          case 'afgehandeld':
            chatsessiesDataArchief = verwijderUitData(chatsessiesDataArchief, oudeChatsessie.id);
            break;
        }
      }

      const toevoegenAanData = (
        data: IRemoteData<IChatsessiesData>,
        index: number,
      ): IRemoteData<IChatsessiesData> => {
        if (data.state === ERemoteDataState.Pending) {
          return data;
        }

        const d = data.data!;
        const nieuweData = Object.keys(d.chatsessieIDBijIndex).reduce<
          Record<number, IRemoteData<number>>
        >(
          (acc, curr, i) => {
            const currIndex = parseInt(curr, 10);
            if (currIndex < index) {
              acc[i] = d.chatsessieIDBijIndex[currIndex];
            } else if (currIndex >= index) {
              acc[i + 1] = d.chatsessieIDBijIndex[currIndex];
            }
            return acc;
          },
          {
            [index]: createReadyRemoteData(nieuweChatsessie.id),
          },
        );

        return createReadyRemoteData({
          chatsessieIDBijIndex: nieuweData,
          totaalAantal: d.totaalAantal + 1,
        });
      };

      switch (nieuweChatsessie.status) {
        case 'nieuw':
          chatsessiesDataNieuw = toevoegenAanData(chatsessiesDataNieuw, index);
          break;
        case 'lopend':
          chatsessiesDataLopend = toevoegenAanData(chatsessiesDataLopend, index);
          break;
        case 'afgehandeld':
          chatsessiesDataArchief = toevoegenAanData(chatsessiesDataArchief, index);
          break;
      }

      const berichtBijID: Record<number, IWhatsappBericht> = {
        ...get().berichtBijID,
      };
      for (const bericht of Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId)) {
        berichtBijID[bericht.id] = bericht;
      }

      const chatsessieBerichtIDBijChatsessieId = Object.keys(
        laatsteBerichtenOutput.berichtenPerChatsessieId,
      ).reduce(
        (acc, key) => {
          const chatsessieID = parseInt(key, 10);
          const bericht = laatsteBerichtenOutput.berichtenPerChatsessieId[chatsessieID];
          acc[chatsessieID] = bericht.id;
          return acc;
        },
        {
          ...get().chatsessieBerichtIDBijChatsessieId,
        },
      );

      set({
        chatsessieBijID: createReadyRemoteData(nieuweChatsessieBijID),
        chatsessiesDataNieuw,
        chatsessiesDataLopend,
        chatsessiesDataArchief,
        chatsessieBerichtIDBijChatsessieId,
        berichtBijID,
      });
    },
    bepalenLaatsteTijdvensterAanvraagVoorChatsessie: async (chatsessieId) => {
      let huidigeData = get().laatsteTijdvensterAanvraagBijChatsessieId;
      let nieuweData = {
        ...huidigeData,
        [chatsessieId]: createPendingRemoteData(),
      };
      set({
        laatsteTijdvensterAanvraagBijChatsessieId: nieuweData,
      });

      const output = await api.services.v1.whatsapp.ophalenTijdvensterAanvragen({
        filters: {
          enkel: {
            kolom: 'chatsessieId',
            filter: {
              nummeriek: {
                gelijkAan: chatsessieId,
              },
            },
          },
        },
        sorteringen: [
          {
            kolom: 'datum',
            richting: 'DESC',
          },
        ],
        paginatie: {
          index: 0,
          aantal: 1,
        },
      });

      const aanvraag = output.tijdvensterAanvragen[0] ?? null;
      huidigeData = get().laatsteTijdvensterAanvraagBijChatsessieId;
      nieuweData = {
        ...huidigeData,
        [chatsessieId]: createReadyRemoteData(aanvraag),
      };
      set({
        laatsteTijdvensterAanvraagBijChatsessieId: nieuweData,
      });
    },
    handleWhatsappConversatieVoortzettenAangevraagd: async (b) => {
      const remoteData = get().laatsteTijdvensterAanvraagBijChatsessieId[b.chatsessieId];
      if (remoteData === undefined) {
        return;
      }

      await get().bepalenLaatsteTijdvensterAanvraagVoorChatsessie(b.chatsessieId);
    },
    zoek: async (zoekterm, signal) => {
      const trimmedZoekterm = zoekterm.trim();
      if (trimmedZoekterm.length === 0) {
        set({
          zoekResultaat: createReadyRemoteData(null),
        });
        return;
      }

      set({
        zoekResultaat: createPendingRemoteData(),
      });

      const bepaalDefaultZoekenResultaat = (): IZoekResultaat => {
        return {
          zoekterm: trimmedZoekterm,
          berichtenData: createPendingRemoteData(),
          chatsessiesData: createPendingRemoteData(),
        };
      };

      await Promise.all([
        (async () => {
          const output = await api.services.v1.whatsapp.zoekenChatsessies(
            {
              zoekterm: trimmedZoekterm,
              paginatie: {
                index: 0,
                aantal: 3,
              },
            },
            signal,
          );

          let berichtBijID = get().berichtBijID;
          let chatsessieBerichtIDBijChatsessieId = get().chatsessieBerichtIDBijChatsessieId;
          const chatsessieIDsZonderLaatsteBericht = output.chatsessies
            .filter((chatsessie) => chatsessieBerichtIDBijChatsessieId[chatsessie.id] === undefined)
            .map((chatsessie) => chatsessie.id);
          if (chatsessieIDsZonderLaatsteBericht.length > 0) {
            const laatsteBerichtenOutput = await api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies(
              {
                whatsappChatsessieIds: chatsessieIDsZonderLaatsteBericht,
                filters: {
                  enkel: {
                    kolom: 'whatsappBerichttypeId',
                    filter: {
                      isNull: true,
                    },
                  },
                },
              },
            );
            berichtBijID = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId).reduce<
              Record<number, IWhatsappBericht>
            >((acc, curr) => {
              acc[curr.id] = curr;
              return acc;
            }, get().berichtBijID);

            chatsessieBerichtIDBijChatsessieId = Object.keys(
              laatsteBerichtenOutput.berichtenPerChatsessieId,
            ).reduce<Record<number, number>>((acc, key) => {
              const chatsessieID = parseInt(key, 10);
              acc[chatsessieID] = laatsteBerichtenOutput.berichtenPerChatsessieId[chatsessieID].id;
              return acc;
            }, get().chatsessieBerichtIDBijChatsessieId);
          }

          const chatsessieIDBijIndex = output.chatsessies.reduce<
            Record<number, IRemoteData<number>>
          >((acc, curr, i) => {
            acc[i] = createReadyRemoteData(curr.id);
            return acc;
          }, {});
          const chatsessiesData: IChatsessiesData = {
            totaalAantal: output.totaalAantal,
            chatsessieIDBijIndex,
          };

          const huidigResultaat = get().zoekResultaat;
          const nieuweResultaat: IZoekResultaat = {
            ...(huidigResultaat.data ?? bepaalDefaultZoekenResultaat()),
            chatsessiesData: createReadyRemoteData(chatsessiesData),
          };

          const chatsessieBijID = {
            ...(get().chatsessieBijID.data ?? {}),
          };
          for (const chatsessie of output.chatsessies) {
            chatsessieBijID[chatsessie.id] = chatsessie;
          }

          const partial: Partial<IWhatsappV2Store> = {
            zoekResultaat: createReadyRemoteData(nieuweResultaat),
            chatsessieBijID: createReadyRemoteData(chatsessieBijID),
            berichtBijID,
            chatsessieBerichtIDBijChatsessieId,
          };
          set(partial);
        })(),
        (async () => {
          const output = await api.services.v1.whatsapp.zoekenBerichten(
            {
              zoekterm: trimmedZoekterm,
              paginatie: {
                index: 0,
                aantal: 30,
              },
            },
            signal,
          );

          const berichtIDBijIndex = output.berichten.reduce<Record<number, IRemoteData<number>>>(
            (acc, curr, i) => {
              acc[i] = createReadyRemoteData(curr.id);
              return acc;
            },
            {},
          );
          const berichtenData: IBerichtenData = {
            totaalAantal: output.totaalAantal,
            berichtIDBijIndex,
          };

          const huidigResultaat = get().zoekResultaat;
          const nieuweResultaat: IZoekResultaat = {
            ...(huidigResultaat.data ?? bepaalDefaultZoekenResultaat()),
            berichtenData: createReadyRemoteData(berichtenData),
          };

          const berichtBijID: Record<number, IWhatsappBericht> = {
            ...get().berichtBijID,
          };
          for (const bericht of output.berichten) {
            berichtBijID[bericht.id] = bericht;
          }

          let chatsessieBijID = get().chatsessieBijID;

          const nogNietOpgehaaldeChatsessieIds = output.berichten
            .filter((x) => {
              return (
                chatsessieBijID.state === ERemoteDataState.Pending ||
                chatsessieBijID.data![x.whatsappChatsessieId] === undefined
              );
            })
            .map((x) => x.whatsappChatsessieId);

          if (nogNietOpgehaaldeChatsessieIds.length > 0) {
            const chatsessiesOutput = await api.services.v1.whatsapp.ophalenChatsessies({
              filters: {
                enkel: {
                  kolom: 'id',
                  filter: {
                    nummeriek: {
                      in: nogNietOpgehaaldeChatsessieIds,
                    },
                  },
                },
              },
            });

            const nieuweChatsessieBijID = chatsessiesOutput.chatsessies.reduce(
              (acc, curr) => {
                acc[curr.id] = curr;
                return acc;
              },
              {
                ...(chatsessieBijID.data ?? {}),
              },
            );
            chatsessieBijID = createReadyRemoteData(nieuweChatsessieBijID);
          }

          const partial: Partial<IWhatsappV2Store> = {
            zoekResultaat: createReadyRemoteData(nieuweResultaat),
            berichtBijID,
            chatsessieBijID,
          };

          set(partial);

          const mediaIDs = output.berichten
            .filter((x) => x.whatsappMediaId !== null)
            .map((x) => x.whatsappMediaId) as number[];
          await mediasBepalenAfhandelen(mediaIDs, set, get);
        })(),
      ]);
    },
    leegZoekResultaat: () => {
      set({
        zoekResultaat: createReadyRemoteData(null),
      });
    },
    meerZoekResultatenChatsessiesLaden: async (params, signal: AbortSignal) => {
      let zoekResultaat = get().zoekResultaat;
      if (
        zoekResultaat.state === ERemoteDataState.Pending ||
        zoekResultaat.data!.chatsessiesData.state === ERemoteDataState.Pending
      ) {
        return;
      }
      const data = { ...zoekResultaat.data!.chatsessiesData.data! };

      for (let i = params.index; i < params.index + params.aantal; i++) {
        data.chatsessieIDBijIndex[i] = createPendingRemoteData();
      }

      const nieuwZoekResultaat = createReadyRemoteData<IZoekResultaat>({
        ...zoekResultaat.data!,
        chatsessiesData: createReadyRemoteData(data),
      });

      set({
        zoekResultaat: nieuwZoekResultaat,
      });

      const zoekterm = zoekResultaat.data!.zoekterm;
      const output = await api.services.v1.whatsapp.zoekenChatsessies(
        {
          zoekterm,
          paginatie: {
            index: params.index,
            aantal: params.aantal,
          },
        },
        signal,
      );

      let berichtBijID = get().berichtBijID;
      let chatsessieBerichtIDBijChatsessieId = get().chatsessieBerichtIDBijChatsessieId;
      const chatsessieIDsZonderLaatsteBericht = output.chatsessies
        .filter((chatsessie) => chatsessieBerichtIDBijChatsessieId[chatsessie.id] === undefined)
        .map((chatsessie) => chatsessie.id);
      if (chatsessieIDsZonderLaatsteBericht.length > 0) {
        const laatsteBerichtenOutput = await api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies(
          {
            whatsappChatsessieIds: chatsessieIDsZonderLaatsteBericht,
            filters: {
              enkel: {
                kolom: 'whatsappBerichttypeId',
                filter: {
                  isNull: true,
                },
              },
            },
          },
        );
        berichtBijID = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId).reduce<
          Record<number, IWhatsappBericht>
        >((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, get().berichtBijID);

        chatsessieBerichtIDBijChatsessieId = Object.keys(
          laatsteBerichtenOutput.berichtenPerChatsessieId,
        ).reduce<Record<number, number>>((acc, key) => {
          const chatsessieID = parseInt(key, 10);
          acc[chatsessieID] = laatsteBerichtenOutput.berichtenPerChatsessieId[chatsessieID].id;
          return acc;
        }, get().chatsessieBerichtIDBijChatsessieId);
      }

      zoekResultaat = get().zoekResultaat;
      if (
        zoekResultaat.state === ERemoteDataState.Pending ||
        zoekResultaat.data!.chatsessiesData.state === ERemoteDataState.Pending ||
        zoekResultaat.data!.zoekterm !== zoekterm
      ) {
        console.log('Afbreken meer laden chatsessies');
        return;
      }

      const chatsessieIDBijIndex = output.chatsessies.reduce<Record<number, IRemoteData<number>>>(
        (acc, curr, i) => {
          acc[i + params.index] = createReadyRemoteData(curr.id);
          return acc;
        },
        {
          ...zoekResultaat.data!.chatsessiesData.data!.chatsessieIDBijIndex,
        },
      );
      const chatsessiesData: IChatsessiesData = {
        totaalAantal: output.totaalAantal,
        chatsessieIDBijIndex,
      };

      const nieuweResultaat: IZoekResultaat = {
        ...zoekResultaat.data!,
        chatsessiesData: createReadyRemoteData(chatsessiesData),
      };

      const chatsessieBijID = {
        ...(get().chatsessieBijID.data ?? {}),
      };
      for (const chatsessie of output.chatsessies) {
        chatsessieBijID[chatsessie.id] = chatsessie;
      }

      const partial: Partial<IWhatsappV2Store> = {
        zoekResultaat: createReadyRemoteData(nieuweResultaat),
        chatsessieBijID: createReadyRemoteData(chatsessieBijID),
        berichtBijID,
        chatsessieBerichtIDBijChatsessieId: chatsessieBerichtIDBijChatsessieId,
      };

      set(partial);
    },
    meerZoekResultatenBerichtenLaden: async (params, signal) => {
      let zoekResultaat = get().zoekResultaat;
      if (
        zoekResultaat.state === ERemoteDataState.Pending ||
        zoekResultaat.data!.berichtenData.state === ERemoteDataState.Pending
      ) {
        return;
      }
      const data = { ...zoekResultaat.data!.berichtenData.data! };

      for (let i = params.index; i < params.index + params.aantal; i++) {
        data.berichtIDBijIndex[i] = createPendingRemoteData();
      }

      const nieuwZoekResultaat = createReadyRemoteData<IZoekResultaat>({
        ...zoekResultaat.data!,
        berichtenData: createReadyRemoteData(data),
      });

      set({
        zoekResultaat: nieuwZoekResultaat,
      });

      const zoekterm = zoekResultaat.data!.zoekterm;
      const output = await api.services.v1.whatsapp.zoekenBerichten(
        {
          zoekterm,
          paginatie: {
            index: params.index,
            aantal: params.aantal,
          },
        },
        signal,
      );

      const chatsessieBijID = get().chatsessieBijID;

      const nogNietOpgehaaldeChatsessieIds = output.berichten
        .filter((x) => {
          if (chatsessieBijID.state === ERemoteDataState.Pending) {
            return true;
          }
          return chatsessieBijID.data![x.whatsappChatsessieId] === undefined;
        })
        .map((x) => x.whatsappChatsessieId);

      const partial: Partial<IWhatsappV2Store> = {};

      if (nogNietOpgehaaldeChatsessieIds.length > 0) {
        // Laatste berichten van chatsessies bepalen, als deze nog niet bepaald zijn
        const [chatsessiesOutput, laatsteBerichtenOutput] = await Promise.all([
          api.services.v1.whatsapp.ophalenChatsessies({
            filters: {
              enkel: {
                kolom: 'id',
                filter: {
                  nummeriek: {
                    in: nogNietOpgehaaldeChatsessieIds,
                  },
                },
              },
            },
          }),
          api.services.v1.whatsapp.ophalenLaatsteBerichtenVoorChatsessies({
            whatsappChatsessieIds: nogNietOpgehaaldeChatsessieIds,
            // filters: {
            //   enkel: {
            //     kolom: 'whatsappBerichttypeId',
            //     filter: {
            //       isNull: true,
            //     },
            //   },
            // },
          }),
        ]);

        const chatsessieBijID = chatsessiesOutput.chatsessies.reduce<
          Record<number, IWhatsappChatsessie>
        >(
          (acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          },
          {
            ...(get().chatsessieBijID?.data ?? {}),
          },
        );

        partial.chatsessieBijID = createReadyRemoteData(chatsessieBijID);

        const berichtBijID = Object.keys(laatsteBerichtenOutput.berichtenPerChatsessieId).reduce(
          (acc, key) => {
            const chatseessieID = parseInt(key, 10);
            const bericht = laatsteBerichtenOutput.berichtenPerChatsessieId[chatseessieID];
            acc[bericht.id] = bericht;
            return acc;
          },
          { ...get().berichtBijID },
        );

        partial.berichtBijID = berichtBijID;

        const chatsessieBerichtIDBijChatsessieId = Object.keys(
          laatsteBerichtenOutput.berichtenPerChatsessieId,
        ).reduce(
          (acc, key) => {
            const chatsessieID = parseInt(key, 10);
            acc[chatsessieID] = laatsteBerichtenOutput.berichtenPerChatsessieId[chatsessieID].id;
            return acc;
          },
          { ...get().chatsessieBerichtIDBijChatsessieId },
        );

        partial.chatsessieBerichtIDBijChatsessieId = chatsessieBerichtIDBijChatsessieId;

        const mediaIDs = Object.values(laatsteBerichtenOutput.berichtenPerChatsessieId)
          .filter((x) => x.whatsappMediaId !== null)
          .map((x) => x.whatsappMediaId) as number[];

        // noinspection ES6MissingAwait
        mediasBepalenAfhandelen(mediaIDs, set, get);
      }

      // Chatsessies bijwerken bij id
      // Chatsessie toevoegen op basis van index
      const berichtIDBijIndex = output.berichten.reduce<Record<number, IRemoteData<number>>>(
        (acc, curr, i) => {
          acc[i + params.index] = createReadyRemoteData(curr.id);
          return acc;
        },
        {
          ...zoekResultaat.data!.berichtenData.data!.berichtIDBijIndex,
        },
      );
      const chatsessiesData: IBerichtenData = {
        totaalAantal: output.totaalAantal,
        berichtIDBijIndex,
      };

      const nieuweResultaat: IZoekResultaat = {
        ...zoekResultaat.data!,
        berichtenData: createReadyRemoteData(chatsessiesData),
      };

      partial.berichtBijID = output.berichten.reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {
          ...(partial.berichtBijID ?? get().berichtBijID),
        },
      );

      partial.zoekResultaat = createReadyRemoteData(nieuweResultaat);

      set(partial);
    },
    scrollNaarIndexVoorTabblad: null,
    setScrollNaarIndexVoorTabblad: (index) => {
      set({
        scrollNaarIndexVoorTabblad: index,
      });
    },
  };
});
