import { Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IOphalenDienstenResult } from '../../../../../shared/src/api/v2/dienst/sms/sms';
import { IMailMap } from '../../../../../shared/src/api/v2/email-v2';
import { IOphalenVerzendwijzenResultElement } from '../../../../../shared/src/api/v2/factuur';
import {
  IOphalenInstellingenResult,
  IWijzigenInstellingenParams,
} from '../../../../../shared/src/api/v2/instelling';
import { IOphalenMagazijnenResult } from '../../../../../shared/src/api/v2/magazijn';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import Applicatie from './Applicatie';
import Website from './Website';
import { IOphalenEmailAccountsResultElement } from '../../../../../shared/src/api/v2/dienst/email/email';
import ITaalTekst from '../../../../../shared/src/models/talen/ITaalTekst';

interface IProps extends RouteComponentProps {}

export interface IFormikValues {
  magID: number | null;
  voorraadMagID: number | null;
  revisieMagID: number | null;
  smsDienstID: number | null;
  opzegtermijn: number;
  retentieperiodeAnnulering: number;
  bestellingenDirectVerwerken: boolean;
  bezoekdagenMaximaal: number;
  blogMaxAantalMaandenOud: number;
  inloggenToegestaan: boolean;
  websiteNietWerkend: boolean;
  websiteMelding: boolean;
  WebsiteMeldingTekst: ITaalTekst[];
  reviewsWeergeven: boolean;
  factuurVerzendwijze: number;
  automatischProductenReserveren: boolean;
  contractwisselToestaanWebsite: boolean;
  betalingsregelingViaWebsiteToestaan: boolean;
  betalingstermijnDebiteur: number;
  respijttermijnRetourdatum: number;
  maximaalAantalAanmaningen: number;
  signaleringsgrensNietOvergenomen: number;
  openingstijdenModus: number;
  respijttermijnAanmaningenInBeltaak: number;
  aanmaningRespijttermijn: number;
  betalingstermijnLaatsteAanmaning: number;
  betalingstermijnAanmaning: number;
  parallelSMSVanafAanmaning: number;
  krediettoetsBijBestelling: boolean;
  krediettoetsBijRegistratie: boolean;
  automatischFiatterenOvernameContracten: number;
  appStoreBeschikbaar: boolean;
  googlePlayBeschikbaar: boolean;
  whatsAppBerAutReactie: boolean;
  doelmapAfleverbewijs: number | null;
  doelmapInkoopfactuur: number | null;
  doelmapPakketbezorgingBezoekinfo: number | null;
  termenUitsluitenInAchternaam: string | null;
  eenmaligeKostenBijWissel: boolean;
  bulkberichtenEmailGebrID: number;
  grensbedragIncassoopdrachtDebiteur: number;
  grensbedragBetaalopdrachtDebiteur: number;
  grensbedragBetaalopdrachtCrediteur: number;
  terugbelverzoekNietBeantwoordVersturen: boolean;
  ontvangstbevestigingWhatsAppBinnenOpeningstijd: boolean;
  ontvangstbevestigingWhatsAppBuitenOpeningstijd: boolean;
  rekeningLoonbetalingInternBetrekken: boolean;
}

export interface IInstellingenContext {
  smsDienstenResult: IOphalenDienstenResult | null;
  magazijnenResult: IOphalenMagazijnenResult | null;
  factuurVerzendwijzenResult: IOphalenVerzendwijzenResultElement[] | null;
  mailmappenResult: IMailMap[] | null;
  gebruikersResult: IOphalenEmailAccountsResultElement[] | null;
}

export const InstellingenContext = React.createContext<IInstellingenContext>(null as any);

export enum ETabblad {
  Website = 1,
  Applicatie = 2,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Website]: '/website',
  [ETabblad.Applicatie]: '/applicatie',
};

const Instellingen: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [instellingen, setInstellingen] = useState<IOphalenInstellingenResult | null>(null);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (instellingen === null) {
      return null;
    }

    const meldingTekst: ITaalTekst[] =
      instellingen.WebsiteMeldingTekst == null
        ? []
        : instellingen.WebsiteMeldingTekst.map((t) => {
            return {
              taalID: t.TaalID,
              toepassen: true,
              tekst: t.Tekst ?? '',
            };
          });

    return {
      magID: instellingen.MagID,
      voorraadMagID: instellingen.Voorraad_MagID,
      revisieMagID: instellingen.Revisie_MagID,
      bestellingenDirectVerwerken: instellingen.BestellingenDirectVerwerken,
      opzegtermijn: instellingen.Opzegtermijn,
      smsDienstID: instellingen.SmsDienstID,
      retentieperiodeAnnulering: instellingen.RetentieperiodeAnnulering,
      bezoekdagenMaximaal: instellingen.website.BezoekdagenMaximaal,
      blogMaxAantalMaandenOud: instellingen.website.BlogMaxAantalMaandenOud,
      inloggenToegestaan: instellingen.website.InloggenToegestaan,
      websiteNietWerkend: instellingen.website.WebsiteNietWerkend,
      websiteMelding: instellingen.website.WebsiteMelding,
      WebsiteMeldingTekst: meldingTekst,
      reviewsWeergeven: instellingen.website.ReviewsWeergeven,
      factuurVerzendwijze: instellingen.StandaardVerzendwijzeFacturen,
      automatischProductenReserveren: instellingen.website.AutomatischProductenReserveren,
      contractwisselToestaanWebsite: instellingen.ContractwisselToestaanWebsite,
      betalingsregelingViaWebsiteToestaan: instellingen.BetalingsregelingViaWebsiteToestaan,
      betalingstermijnDebiteur: instellingen.BetalingstermijnDebiteur,
      betalingstermijnAanmaning: instellingen.BetalingstermijnAanmaning,
      betalingstermijnLaatsteAanmaning: instellingen.BetalingstermijnLaatsteAanmaning,
      respijttermijnRetourdatum: instellingen.RespijttermijnRetourdatum,
      maximaalAantalAanmaningen: instellingen.MaximaalAantalAanmaningen,
      signaleringsgrensNietOvergenomen: instellingen.SignaleringsgrensNietOvergenomen,
      openingstijdenModus: instellingen.OpeningstijdenModus,
      respijttermijnAanmaningenInBeltaak: instellingen.RespijttermijnAanmaningenInBeltaak,
      aanmaningRespijttermijn: instellingen.AanmaningRespijttermijn,
      parallelSMSVanafAanmaning: instellingen.ParallelSMSVanafAanmaning,
      krediettoetsBijBestelling: instellingen.KrediettoetsBijBestelling,
      krediettoetsBijRegistratie: instellingen.KrediettoetsBijRegistratie,
      automatischFiatterenOvernameContracten: instellingen.AutomatischFiatterenOvernameContracten,
      appStoreBeschikbaar: instellingen.AppStoreBeschikbaar,
      googlePlayBeschikbaar: instellingen.GooglePlayBeschikbaar,
      whatsAppBerAutReactie: instellingen.WhatsAppBerAutReactie,
      doelmapAfleverbewijs: instellingen.Doelmap_Afleverbewijs_MailMapID,
      doelmapInkoopfactuur: instellingen.Doelmap_Inkoopfactuur_MailMapID,
      doelmapPakketbezorgingBezoekinfo: instellingen.Doelmap_PakketbezorgingBezoekinfo_MailMapID,
      termenUitsluitenInAchternaam: instellingen.TermenUitsluitenInAchternaam,
      eenmaligeKostenBijWissel: instellingen.EenmaligeKostenBijWissel,
      bulkberichtenEmailGebrID: instellingen.Bulkberichten_EmailGebrID,
      grensbedragIncassoopdrachtDebiteur: instellingen.GrensbedragIncassoopdrachtDebiteur,
      grensbedragBetaalopdrachtDebiteur: instellingen.GrensbedragBetaalopdrachtDebiteur,
      grensbedragBetaalopdrachtCrediteur: instellingen.GrensbedragBetaalopdrachtCrediteur,
      terugbelverzoekNietBeantwoordVersturen: instellingen.TerugbelverzoekNietBeantwoordVersturen,
      ontvangstbevestigingWhatsAppBinnenOpeningstijd:
        instellingen.OntvangstbevestigingWhatsAppBinnenOpeningstijd,
      ontvangstbevestigingWhatsAppBuitenOpeningstijd:
        instellingen.OntvangstbevestigingWhatsAppBuitenOpeningstijd,
      rekeningLoonbetalingInternBetrekken: instellingen.RekeningLoonbetalingInternBetrekken,
    };
  }, [instellingen]);

  const ophalenInstellingen = useCallback(async () => {
    const resultaat = await api.v2.instelling.ophalenInstellingen({});

    setInstellingen(resultaat as IOphalenInstellingenResult);
  }, []);

  useEffect(() => {
    ophalenInstellingen();
  }, [ophalenInstellingen]);

  const [smsDienstenResult, setSmsDienstenResult] = useState<IOphalenDienstenResult | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.dienst.sms.ophalenDiensten({
        filterSchema: { filters: [] },
      });
      setSmsDienstenResult(result);
    })();
  }, []);

  const [magazijnenResult, setMagazijnenResult] = useState<IOphalenMagazijnenResult | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.magazijn.ophalenMagazijnen({
        filterSchema: {
          filters: [],
        },
      });
      setMagazijnenResult(result);
    })();
  }, []);

  const [factuurVerzendwijzenResult, setFactuurVerzendwijzenResult] = useState<
    IOphalenVerzendwijzenResultElement[] | null
  >(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.factuur.ophalenVerzendwijzen({});
      setFactuurVerzendwijzenResult(result.verzendwijzen);
    })();
  }, []);

  const [mailmappenResult, setMailMappenResult] = useState<IMailMap[] | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.emailV2.ophalenMailMappen({});
      setMailMappenResult(result.mailMappen);
    })();
  }, []);

  const [gebruikersResult, setGebruikersResult] = useState<
    IOphalenEmailAccountsResultElement[] | null
  >(null);
  useEffect(() => {
    (async () => {
      if (instellingen === null) {
        return;
      }

      console.log('email account ophalen voor de standaard emaildienst?:');
      const result = await api.v2.email.ophalenEmailAccounts({
        filterSchema: {
          filters: [{ naam: 'EMAILDIENST_IDS', data: [instellingen.EmailDienstID] }],
        },
      });

      console.log('email accounts:');
      console.log(result);
      setGebruikersResult(result.accounts);
    })();
  }, [instellingen]);

  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Applicatie]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Applicatie,
        label: 'Applicatie',
        content: Applicatie,
      },
      {
        id: ETabblad.Website,
        label: 'Website',
        content: Website,
      },
    ],
    [],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenInstellingenParams = {
        applicatie: {
          bestellingenDirectVerwerken: values.bestellingenDirectVerwerken,
          opzegtermijn: values.opzegtermijn,
          smsDienstID: values.smsDienstID,
          voorraad_MagID: values.voorraadMagID,
          revisie_MagID: values.revisieMagID,
          retentieperiodeAnnulering: values.retentieperiodeAnnulering,
          standaardVerzendwijzeFacturen: values.factuurVerzendwijze,
          contractwisselToestaanWebsite: values.contractwisselToestaanWebsite,
          betalingsregelingViaWebsiteToestaan: values.betalingsregelingViaWebsiteToestaan,
          betalingstermijnDebiteur: values.betalingstermijnDebiteur,
          BetalingstermijnAanmaning: values.betalingstermijnAanmaning,
          BetalingstermijnLaatsteAanmaning: values.betalingstermijnLaatsteAanmaning,
          respijttermijnRetourdatum: values.respijttermijnRetourdatum,
          maximaalAantalAanmaningen: values.maximaalAantalAanmaningen,
          signaleringsgrensNietOvergenomen: values.signaleringsgrensNietOvergenomen,
          openingstijdenModus: values.openingstijdenModus,
          respijttermijnAanmaningenInBeltaak: values.respijttermijnAanmaningenInBeltaak,
          aanmaningRespijttermijn: values.aanmaningRespijttermijn,
          parallelSMSVanafAanmaning: values.parallelSMSVanafAanmaning,
          krediettoetsBijBestelling: values.krediettoetsBijBestelling,
          krediettoetsBijRegistratie: values.krediettoetsBijRegistratie,
          automatischFiatterenOvernameContracten: values.automatischFiatterenOvernameContracten,
          appStoreBeschikbaar: values.appStoreBeschikbaar,
          googlePlayBeschikbaar: values.googlePlayBeschikbaar,
          whatsAppBerAutReactie: values.whatsAppBerAutReactie,
          doelmapAfleverbewijs: values.doelmapAfleverbewijs,
          doelmapInkoopfactuur: values.doelmapInkoopfactuur,
          doelmapPakketbezorgingBezoekinfo: values.doelmapPakketbezorgingBezoekinfo,
          termenUitsluitenInAchternaam: values.termenUitsluitenInAchternaam,
          eenmaligeKostenBijWissel: values.eenmaligeKostenBijWissel,
          bulkberichten_EmailGebrID: values.bulkberichtenEmailGebrID,
          grensbedragBetaalopdrachtDebiteur: values.grensbedragBetaalopdrachtDebiteur,
          grensbedragIncassoopdrachtDebiteur: values.grensbedragIncassoopdrachtDebiteur,
          grensbedragBetaalopdrachtCrediteur: values.grensbedragBetaalopdrachtCrediteur,
          isBulkverzenderActief: instellingen === null ? false : instellingen.IsBulkverzenderActief,
          terugbelverzoekNietBeantwoordVersturen: values.terugbelverzoekNietBeantwoordVersturen,
          ontvangstbevestigingWhatsAppBinnenOpeningstijd:
            values.ontvangstbevestigingWhatsAppBinnenOpeningstijd,
          ontvangstbevestigingWhatsAppBuitenOpeningstijd:
            values.ontvangstbevestigingWhatsAppBuitenOpeningstijd,
          rekeningLoonbetalingInternBetrekken: values.rekeningLoonbetalingInternBetrekken,
        },
        website: {
          inloggenToegestaan: values.inloggenToegestaan,
          bezoekdagenMaximaal: values.bezoekdagenMaximaal,
          blogMaxAantalMaandenOud: values.blogMaxAantalMaandenOud,
          websiteMelding: values.websiteMelding,
          websiteMelding_TekstID:
            instellingen == null ? null : instellingen.website.Websitemelding_TekstID,
          websiteMeldingTekst: values.WebsiteMeldingTekst,
          websiteNietWerkend: values.websiteNietWerkend,
          reviewsWeergeven: values.reviewsWeergeven,
          automatischProductenReserveren: values.automatischProductenReserveren,
        },
      };

      await api.v2.instelling.wijzigenInstellingen(params);
      await ophalenInstellingen();

      actions.setSubmitting(false);
    },
    [instellingen, ophalenInstellingen],
  );

  const context = useMemo<IInstellingenContext>(() => {
    return {
      smsDienstenResult,
      magazijnenResult,
      factuurVerzendwijzenResult,
      mailmappenResult,
      gebruikersResult,
    };
  }, [
    smsDienstenResult,
    magazijnenResult,
    factuurVerzendwijzenResult,
    mailmappenResult,
    gebruikersResult,
  ]);

  return (
    <InstellingenContext.Provider value={context}>
      <div className="d-flex flex-column flex-fill bg-white">
        <div className="d-flex flex-column flex-fill">
          {initialValues !== null && (
            <Formik<IFormikValues>
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
              render={(formikProps: FormikProps<IFormikValues | null>) => {
                const { isSubmitting, submitForm, values, isValid, resetForm } = formikProps;

                return (
                  <>
                    <div className="d-flex flex-column flex-fill">
                      {tabblad !== null && (
                        <Tabblad<ETabblad>
                          options={{
                            tabbladComponentProps: {
                              /* hier eventueel props? */
                            },
                          }}
                          geselecteerd={tabblad}
                          onSelectieChange={(tab) => {
                            props.history.push(
                              `${props.location.pathname.replace(pathMap[tabblad], '')}${
                                pathMap[tab]
                              }`,
                            );
                          }}
                          tabbladen={tabbladen}
                        />
                      )}
                    </div>

                    <div className="d-flex align-items-center p-3 pl-4 pr-4">
                      <button
                        className="btn btn-primary d-flex align-items-center justify-content-center"
                        onClick={submitForm}
                        style={{ width: 100 }}
                        disabled={isSubmitting || !isValid}
                      >
                        {isSubmitting && (
                          <span className="mr-2">
                            <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
                          </span>
                        )}
                        Ok
                      </button>
                      <button
                        className="btn btn-secondary ml-2"
                        onClick={async () => {
                          if (
                            (await checkStore.bevestigen({ inhoud: 'Formulier resetten' })).type ===
                            EResultType.Annuleren
                          ) {
                            return;
                          }
                          resetForm();
                        }}
                        style={{ width: 100 }}
                        disabled={isSubmitting}
                      >
                        Annuleren
                      </button>
                    </div>
                  </>
                );
              }}
            />
          )}
        </div>
      </div>
    </InstellingenContext.Provider>
  );
});

export default Instellingen;
