export enum EIncassozaakStatus {
  Concept = 0,
  Ingetrokken = 1,
  Uithanden = 2,
  Gesloten = 3,
}

// Productstatussen
export enum EProductstatus {
  Retouren = 'RETOUREN',
  Voorraad = 'VOORRAAD',
  Revisie = 'REVISIE',
  Afvoer = 'AFVOER',
  RetLev = 'RETLEV',
  Verhuur = 'VERHUUR',
  UitBedrijf = 'UITBEDRIJF',
  Parkeren = 'GEPARKEERD',
}

// Magazijn Mutatiesoorten (van producten)
export enum EMagazijnMutatiesoort {
  Inkoop = 'INKOOP',
  RetourKlant = 'RETOUR_KLANT',
  PendelLossen = 'PENDEL_LOSSEN',
  RetourInkoop = 'RETOUR_INKOOP',
  LeveringKlant = 'LEVERING_KLANT',
  PendelLaden = 'PENDEL_LADEN',
  AfvoerIntern = 'AFVOER_INTERN',
  Surplus = 'SURPLUS',
  Manco = 'MANCO',
  StatusWijziging = 'STATUS_WIJZIGING',
  Zoekmelding = 'ZOEKMELDING',
}

// Reden waarvoor het product uit bedrijf gegaan is
export enum ERedenUitBedrijf {
  Afgevoerd = 'AFGEVOERD',
  RetourLeverancier = 'RETOUR_LEV',
  Verkocht = 'VERKOCHT',
  Zoekgeraakt = 'ZOEK',
  NietRetourKlant = 'NIET_RETOUR_KLANT',
  OverigeReden = 'OVERIG',
}

export enum EBetalingsregelingStatus {
  Concept = 'CONCEPT',
  Lopend = 'LOPEND',
  GeheelNagekomen = 'GEHEEL_NAGEKOMEN',
  DeelsNagekomen = 'DEELS_NAGEKOMEN',
  NietNagekomen = 'NIET_NAGEKOMEN',
}

export enum EBtwsoort {
  Geen = 'GEEN',
  AfdragenLaag = 'AFDRAGEN_LAAG',
  AfdragenHoog = 'AFDRAGEN_HOOG',
  VorderenLaag = 'VORDEREN_LAAG',
  VorderenHoog = 'VORDEREN_HOOG',
  Verlegd = 'VERLEGD',
  InkopenEU = 'INKOPEN_EU',
  InkopenBuitenEU = 'INKOPEN_BUITEN_EU',
}

export enum EBetaalwijze {
  AndereWijze = 'ANDERE_WIJZE',
  Overboeking = 'OVERBOEKING',
  Incasso = 'INCASSO',
  iDeal = 'IDEAL',
  Betaalverzoek = 'BETAALVERZOEK',
  Paypal = 'PAYPAL',
  Creditcard = 'CREDITCARD',
  Verrekenen = 'VERREKENEN',
  Verzamelbetaling = 'VERZAMELBETALING',
}

export enum ERegelstatusVordering {
  Direct = 0,
  WOP = 1,
  Ophouden = 2,
}

export enum EProductdocumentatiesoort {
  Gebruiksaanwijzing = 'GEBRUIKSAANWIJZING',
  Onderhoudsinstructies = 'ONDERHOUDTIPS',
  Programmatabel = 'PROGRAMMATABEL',
}

export enum EKanaal {
  Email = 'EMAIL',
  SMS = 'SMS',
  Telefoon = 'TELEFOON',
  Chat = 'CHAT',
  WebBericht = 'WEBBERICHT',
  WhatsApp = 'WHATSAPP',
}

export enum EVerzendwijzeFacturen {
  EmailMetLink = 1,
  EmailMetBijlage = 2,
  SMSMetLink = 3,
}

export enum EVerzendwijzeBetaallink {
  Email = 1,
  SMS = 2,
}

export enum EContractstatus {
  Concept = 'CONCEPT',
  Afgekeurd = 'AFGEKEURD',
  Lopend = 'LOPEND',
  Geannuleerd = 'GEANNULEERD',
  Overgenomen = 'OVERGENOMEN',
  Beeindigd = 'BEEINDIGD',
}

export enum EOpdrachtstatusTransport {
  Planning = 1,
  Uitstaand = 2,
  Afgehandeld = 3,
}

export enum ERegelstatusTransport {
  NIET_AFGEMELD = 0,
  UITGEVOERD = 1,
  MISLUKT = 2,
  GEANNULEERD = 3,
}

// export enum EGrootboekrekeningNummer {
//   CREDITEUREN = '1600',
//   DEBITEUREN = '1300',
//   SPLITSEN = '2000',
// }

export enum EGrootboekrekening {
  Crediteuren = 1600,
  Debiteuren = 1300,
  Splitsen = 2000,
  Vooruitbetaald = 1670,
  AfboekenDebiteuren = 4790,
  Aanmaningskosten = 8300,
  Machines = 100,
}

export enum EGrootboekrekeningNaam {
  Machines = 'MACHINES',
  AFSCHRIJVING_MACHINES = 'AFSCHRIJVING_MACHINES',
  Ing660 = 'ING_660',
  Rabo420 = 'RABO_420',
  Rabo670 = 'RABO_670',
  Kruispost = 'KRUISPOST',
  KruispostIdeal = 'KRUISPOST_IDEAL',
  KruispostPayPal = 'KRUISPOST_PAYPAL',
  Debiteuren = 'DEBITEUREN',
  Crediteuren = 'CREDITEUREN',
  Vennootschapsbelasting = 'VENNOOTSCHAPSBELASTING',
  WaarborgDebiteuren = 'WAARBORG_DEBITEUREN',
  VooruitOntvangenDebiteuren = 'VOORUIT_ONTVANGEN_DEBITEUREN',
  RCSplashHolding = 'RC_SPLASH_HOLDING',
  NettoSalarissen = 'NETTO_SALARISSEN',
  AfdrachtLoonheffing = 'AFDRACHT_LOONHEFFING',
  Splitsen = 'SPLITSEN',
  Omzet = 'OMZET',
  Aanmaningskosten = 'AANMANINGSKOSTEN',
  Stornokosten = 'STORNOKOSTEN',
  DebiteurenBetalingsregeling = 'DEBITEUREN_BETALINGSREGELING',
}

export enum EProcedureStatus {
  Concept = 'CONCEPT',
  Uitstaand = 'UITSTAAND',
  Geweigerd = 'GEWEIGERD',
  Geaccepteerd = 'GEACCEPTEERD',
  GeenReactie = 'GEEN_REACTIE',
}

export enum EDagboeksoort {
  INKOOP = 'INKOOP',
  VERKOOP = 'VERKOOP',
  BANK = 'BANK',
  MEMORIAAL = 'MEMORIAAL',
}

export enum EDagboek {
  INKOOP = 1,
  VERKOOP = 5,
  ING660 = 6,
  RABO420 = 7,
  RABO_SPAAR = 9,
  ING_SPAAR = 12,
  MEMO = 14,
  MEMO_MUTATIES = 16,
  MEMO_BUCKAROO = 18,
}

// Later eventueel EDagboek vervangen door EDagboeknaam, dus via het veld NaamEnum

export enum EDagboeknaam {
  BUCKAROO_BETALINGEN = 'BUCKAROO_BETALINGEN',
}

export enum EAccLogSoort {
  Inloggen = 1,
  Activeren = 2,
  NieuwWachtwoord = 3,
  WachtwoordGewijzigd = 4,
  WachtwoordVergeten = 5,
  Uitloggen = 6,
}

export enum ETransportopdrachtRegelsoort {
  Retour = 'RETOUR',
  Levering = 'LEVERING',
}

/**
 * KAART_CONTRACT_PROLONGATIE_NIEUWE_RUN
 * KAART_CONTRACT_PROLONGATIE_VERWERKEN_RUN
 * KAART_CONTRACT_PROLONGATIE_VERWIJDEREN_RUN
 * KAART_TRANSPORT_OPDRACHT_VERSTUREN
 * KAART_BANKZAKEN_NIEUWE_BANKOPDRACHT
 * KAART_BANKZAKEN_VERWIJDEREN_BANKOPDRACHT
 * KAART_BANKZAKEN_WIJZIGEN_BANKOPDRACHT
 * MAGAZIJN_PENDELOPDRACHT_WEERGEVEN
 * FINANCIEEL_BANKZAKEN_WEERGEVEN
 * FACILITAIR_IMPORT_EXPORT_WEERGEVEN
 */
export enum EPermissie {
  KaartContractProlongatieNieuweRun = 'KAART_CONTRACT_PROLONGATIE_NIEUWE_RUN',
  KaartContractProlongatieVerwerken = 'KAART_CONTRACT_PROLONGATIE_VERWERKEN_RUN',
  KaartContractProlongatieVerwijderen = 'KAART_CONTRACT_PROLONGATIE_VERWIJDEREN_RUN',
  KaartTransportOpdrachtVersturen = 'KAART_TRANSPORT_OPDRACHT_VERSTUREN',
  KaartBankzakenNieuweBankopdracht = 'KAART_BANKZAKEN_NIEUWE_BANKOPDRACHT',
  KaartBankzakenVerwijderenBankopdracht = 'KAART_BANKZAKEN_VERWIJDEREN_BANKOPDRACHT',
  KaartBankzakenWijzigenBankopdracht = 'KAART_BANKZAKEN_WIJZIGEN_BANKOPDRACHT',
  MagazijnPendelopdrachtWeergeven = 'MAGAZIJN_PENDELOPDRACHT_WEERGEVEN',
  FinancieelBanzakenWeergeven = 'FINANCIEEL_BANKZAKEN_WEERGEVEN',
  FacilitairImportExportWeergeven = 'FACILITAIR_IMPORT_EXPORT_WEERGEVEN',
  AdministratieMedewerkersWeergeven = 'ADMINISTRATIE_MEDEWERKERS_WEERGEVEN',
  AdministratieVerlofRegistratieWeergeven = 'ADMINISTRATIE_VERLOF_REGISTRATIE_WEERGEVEN',
  BoekingWijzigen = 'BOEKING_WIJZIGEN',
}

export enum EBankopdrachtsoort {
  Incasso = 1,
  Betaling = 2,
}

export enum EBankopdrachtsoortEnum {
  Incasso = 'INCASSO',
  Betaling = 'BETALING',
}

export enum EAanleidingVerkoopvoorstel {
  VerzoekKlant = 0,
  BeeindigingContract = 1,
}

export enum EProductsoort {
  Wasmachine = 'WASMACHINE',
  Droger = 'DROGER',
  Vaatwasser = 'VAATWASSER',
  Koelkast = 'KOELKAST',
  Wasdroogcombinatie = 'WASDROOGCOMBINATIE',
  Koelvriescombinatie = 'KOELVRIESCOMBINATIE',
  Fornuis = 'FORNUIS',
  Premium = 'PREMIUM',
}

export enum EOpdrachtwijze {
  Overig = 'OVERIG',
  Website = 'WEBSITE',
  Telefoon = 'TELEFOON',
  Email = 'EMAIL',
  PDF_Email = 'PDF_EMAIL',
  XML_Email = 'XML_EMAIL',
  XML_FTP = 'XML_FTP',
  XML_FTP_PDF_Email = 'XML_FTP_PDF_EMAIL',
  CSV_Email = 'CSV_EMAIL',
  Portal = 'PORTAL',
}

export enum ERelatiehoedanigheid {
  Klant = 'KLANT',
  Leverancier = 'LEVERANCIER',
}

export enum ETelefooneventStatus {
  Inkomend = 0,
  Rinkelt = 1,
  Beantwoord = 2,
  Beeindigd = 3,
}

export enum ETelefooneventBeeindigdReden {
  Geslaagd = 0,
  InGesprek = 1,
  NietBeantwoord = 2,
  Mislukt = 3,
  Geweigerd = 4,
}

export enum EMagazijnRichting {
  LAAD = 'LAAD',
  LOS = 'LOS',
}

export enum ETelefoonLand {
  Nederland,
  Duitsland,
  VerenigdKoninkrijk,
  Frankrijk,
  Belgie,
  Italie,
  Spanje,
  China,
  Rusland,
  VerenigdeStaten,
  Brazilie,
  Mexico,
}

export enum EPotentieelOntvangenItemStatus {
  Beoordelen = 0,
  AutomatischVerwerkt,
  HandmatigVerwerkt,
  Afgewezen,
}

export const enum EIndicatiePremium {
  Geen = 'GEEN',
  Automatisch = 'AUTOMATISCH',
  Handmatig = 'HANDMATIG',
}

export enum EReviewUitnodigingAanleiding {
  Bestelling = 'BESTELLING',
  Omruiling = 'OMRUILING',
  Servicebezoek = 'SERVICEBEZOEK',
  GeenKlantMeer = 'GEEN_KLANT_MEER',
}

export enum EReferentieNaamEnum {
  BankBatch = 'BANK_BATCH',
  BestandBestandslabel = 'BESTAND_BESTANDSLABEL',
  NavHulpItemDownload = 'NAV_HULP_ITEM_DOWNLOAD',
  PotentieelBestandInkoopFactuur = 'POTENTIEEL_BESTAND_INKOOP_FACTUUR',
  PotentieelOntvangenItemInkoopFactuur = 'POTENTIEEL_ONTVANGEN_ITEM_INKOOP_FACTUUR',
  ProductmodelAfbeelding = 'PRODUCTMODEL_AFBEELDING',
  RelatieBestand = 'RELATIE_BESTAND',
  RelatieDocument = 'RELATIE_DOCUMENT',
  ServiceMeldingAfbeelding = 'SERVICE_MELDING_AFBEELDING',
  TransportopdrachtBestand = 'TRANSPORTOPDRACHT_BESTAND',
  EmailBerichtBestand = 'EMAIL_BERICHT_BESTAND',
  PotentieelOntvangenItemWerkbon = 'POTENTIEEL_ONTVANGEN_ITEM_WERKBON',
  WhatsAppV2Media = 'WHATSAPP_V2_MEDIA',
  WhatsAppV2Chatsessie = 'WHATSAPP_V2_CHATSESSIE',
  WebAfbeelding = 'WEB_AFBEELDING',
  BlogItem = 'BLOG_ITEM',
  BankDienst = 'BANK_DIENST',
  Bedrijf = 'BEDRIJF',
  Factuur = 'FACTUUR',
  EmailberbestV2 = 'EMAILBERBEST_V2',
  AlgemeneVoorwaarden = 'ALGEMENE_VOORWAARDEN',
  SponsorContractBijlage = 'SPONSOR_CONTRACT_BIJLAGE',
}

export enum ESjablooncode {
  BestellingBevestiging = 'bestelling.bevestiging',
  AccountActivatielink = 'account.activatielink',
  AccountWachtwoordVergeten = 'account.ww.vergeten',
  AlgemeneVoorwaarden = 'algemene-voorwaarden',
  ActieVoorwaarden = 'actie-voorwaarden',
  PrivacyBeleid = 'privacybeleid',
  CookieBeleid = 'cookiebeleid',
  RegistratieWebsite = 'registratie.website',
  VerzoekDocumentenAlleenGebruik = 'acceptatieprocedure.verzoek.documenten-alleengebruik',
  VerzoekDocumentenAccepatie = 'acceptatieprocedure.verzoek.documenten',
  Afwijzing1Gebruiker = 'afwijzing.eengebruiker',
  AfwijzingMeerGebruikers = 'acceptatieprocedure.afwijzing',
  // PendelopdrachtbestandZonderNotities = 'pendel.opdracht.lijst.zonder-notities',
  // PendelopdrachtbestandMetNotities = 'pendel.opdracht.lijst.met-notities',
}

export enum EVerlofMutatiesoort {
  Saldo2014 = 'SALDO_2014',
  OpnameVerlof = 'OPNAME_VERLOF',
}

export enum EOverigeTarieven {
  Gebruikerstoeslag = 'GEBRUIKERSTOESLAG',
  Annulering_Contracten = 'ANNULERING_CONTRACTEN',
  Annulering_Per_Contract = 'ANNULERING_PER_CONTRACT',
  Percentage_Kosten_VTB = 'PERCENTAGE_KOSTEN_VTB',
  Korting_Wervingscode_Aanbrenger = 'KORTING_WERVINGSCODE_AANBRENGER',
  Korting_Wervingscode_Aangebrachte = 'KORTING_WERVINGSCODE_AANGEBRACHTE',
  Korting_Overstapcode = 'KORTING_OVERSTAPCODE',
  Afvoer_Oud_Apparaat = 'AFVOER_OUD_APPARAAT',
  Afvoer_Oud_Apparaat_Extra = 'AFVOER_OUD_APPARAAT_EXTRA',
  Aansluiten_Trekschakelaar = 'AANSLUITEN_TREKSCHAKELAAR',
  Kosten_Aanmaning_1 = 'KOSTEN_AANMANING_1',
  Kosten_Aanmaning_2 = 'KOSTEN_AANMANING_2',
  DoorTeBerekenen_Standaard_Servicekosten = 'DOOR_TE_BEREKENEN_STANDAARD_SERVICEKOSTEN',
}

export enum EMutatiebron {
  Intern = 1,
  Website = 2,
  GebruikersApp = 3,
}

export enum EBeltaakContext {
  Debiteuren = 'DEBITEUREN',
  Status_Servicemeldingen = 'STATUS_SERVICEMELDINGEN',
}

export enum EMutatiesoort {
  Inkoop = 'INKOOP',
  RetourKlant = 'RETOUR_KLANT',
  PendelLossen = 'PENDEL_LOSSEN',
  RetourInkoop = 'RETOUR_INKOOP',
  LeveringKlant = 'LEVERING_KLANT',
  PendelLaden = 'PENDEL_LADEN',
  AfvoerIntern = 'AFVOER_INTERN',
  Surplus = 'SURPLUS',
  Manco = 'MANCO',
  StatusWijziging = 'STATUS_WIJZIGING',
  Zoekmelding = 'ZOEKMELDING',
  HerstelUitBedrijf = 'HERSTEL_UIT_BEDRIJF',
}
