import {
  IBulkberichtReviewAccepterenParams,
  IBulkberichtReviewAccepterenResult,
  IOphalenBulkVerzendBerichtEmailsParams,
  IOphalenBulkVerzendBerichtEmailsResult,
  IOphalenBulkVerzendBerichtenParams,
  IOphalenBulkVerzendBerichtenResult,
  IOphalenBulkVerzendBerichtSmsParams,
  IOphalenBulkVerzendBerichtSmsResult,
} from '../../../../../shared/src/api/v2/bulkVerzending';
import api from '../../index';

const bulkVerzending = {
  ophalenBulkVerzendBerichten: async (
    params: IOphalenBulkVerzendBerichtenParams,
  ): Promise<IOphalenBulkVerzendBerichtenResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-berichten', params);
  },
  ophalenBulkVerzendBerichtEmails: async (
    params: IOphalenBulkVerzendBerichtEmailsParams,
  ): Promise<IOphalenBulkVerzendBerichtEmailsResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-bericht-emails', params);
  },
  ophalenBulkVerzendBerichtSms: async (
    params: IOphalenBulkVerzendBerichtSmsParams,
  ): Promise<IOphalenBulkVerzendBerichtSmsResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-bericht-sms', params);
  },
  bulkberichtReviewAccepteren: async (
    params: IBulkberichtReviewAccepterenParams,
  ): Promise<IBulkberichtReviewAccepterenResult> => {
    return await api.post('/v2/bulk-verzending/bulkbericht-review-accepteren', params);
  },
  bulkberichtReviewWeigeren: async (
    params: IBulkberichtReviewAccepterenParams,
  ): Promise<IBulkberichtReviewAccepterenResult> => {
    return await api.post('/v2/bulk-verzending/bulkbericht-review-weigeren', params);
  },
};

export default bulkVerzending;
