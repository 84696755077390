import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import nameOf from '../../../../core/nameOf';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../shared/src/api/v2/tekst';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import {
  IEnqueteVraagIntern,
  IInstellenEnqueteVraagParams,
} from '../../../../../../shared/src/api/v2/enquete';
import nameof from '../../../../core/nameOf';
import MeertaligTekstveld from '../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../shared/src/models/talen/ITaalTekst';
import Combobox from '../../../../components/formulier/Combobox';

export enum EVraagType {
  Tekst = 0,
  JaOfNee = 1,
  TekstOfNee = 2,
  MultiKeuzeEnkelAntwoord = 3,
  MultiKeuzeMultiAntwoord = 4,
}

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number | null;
}

interface IFormikValues {
  sortNr: number;
  vraagType: number;
  vraag: ITaalTekst[];
  optie1: ITaalTekst[];
  optie2: ITaalTekst[];
  optie3: ITaalTekst[];
  optie4: ITaalTekst[];
  optie5: ITaalTekst[];

  heeftToelichting1: boolean;
  heeftToelichting2: boolean;
  heeftToelichting3: boolean;
  heeftToelichting4: boolean;
  heeftToelichting5: boolean;
}

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const [item, setItem] = useState<IEnqueteVraagIntern | null>(null);

  const formikRef = useRef<Formik<IFormikValues>>(null);

  const ophalenItem = useCallback(async () => {
    const results = await api.v2.enquete.ophalenVragen({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });

    if (results.length > 0) {
      setItem(results[0]);
    }
  }, [props.id]);

  useEffect(() => {
    ophalenItem();
  }, [ophalenItem]);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );

  const ophalenTeksten = useCallback(async () => {
    if (!item) {
      if (teksten.state === ERemoteDataState.Ready) {
        setTeksten(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs: number[] = [
      ...item.Opties.map((o) => o.TekstID),
      item.Vraag_TekstID ?? null,
    ].filter((x) => x) as number[];
    if (tekstIDs.length == 0) return;

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTeksten(createReadyRemoteData(result));
  }, [item, teksten.state]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const opties = [values.optie1, values.optie2, values.optie3, values.optie4, values.optie5];
      const toelichtingen = [
        values.heeftToelichting1,
        values.heeftToelichting2,
        values.heeftToelichting3,
        values.heeftToelichting4,
        values.heeftToelichting5,
      ];
      const tekstIDsAantal = item == null ? 0 : item.Opties.length;

      const params: IInstellenEnqueteVraagParams = {
        ID: item?.ID ?? null,
        SortNr: values.sortNr,
        Vraag: {
          tekstID: item?.Vraag_TekstID ?? null,
          taalTeksten: values.vraag,
        },
        VraagType: values.vraagType,
        Opties: opties
          .filter((o) => o)
          .map((o, i) => ({
            Tekst: {
              tekstID: i >= tekstIDsAantal ? null : item?.Opties[i].TekstID ?? null,
              taalTeksten: o,
            },
            HeeftToelichting: toelichtingen[i],
          })),
      };
      console.log(params);
      await api.v2.enquete.instellenVraag(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [item],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (props.id === null) {
      return {
        sortNr: 0,
        vraagType: 0,
        vraag: [],
        optie1: [],
        optie2: [],
        optie3: [],
        optie4: [],
        optie5: [],
        heeftToelichting1: false,
        heeftToelichting2: false,
        heeftToelichting3: false,
        heeftToelichting4: false,
        heeftToelichting5: false,
      };
    }

    if (item === null || teksten === null || teksten.state == ERemoteDataState.Pending) {
      return null;
    }

    const vraagTeksten =
      item.Vraag_TekstID === null
        ? []
        : teksten
            .data!.teksten.filter((x) => x.TekstID === item.Vraag_TekstID)
            .map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            }));

    const optieTeksten = [];
    for (let i = 0; i < 5; i++) {
      optieTeksten.push(
        item.Opties.length < i
          ? []
          : teksten
              .data!.teksten.filter((x) => x.TekstID === item.Opties[i].TekstID)
              .map((x) => ({
                tekst: x.Tekst ?? '',
                taalID: x.TaalID,
                toepassen: x.Toepassen,
              })),
      );
    }
    return {
      sortNr: item.SortNr,
      vraag: vraagTeksten,
      vraagType: item.VraagType,
      optie1: optieTeksten[0],
      optie2: optieTeksten[1],
      optie3: optieTeksten[2],
      optie4: optieTeksten[3],
      optie5: optieTeksten[4],
      heeftToelichting1: item.Opties[0].HeeftToelichting,
      heeftToelichting2: item.Opties[1].HeeftToelichting,
      heeftToelichting3: item.Opties[2].HeeftToelichting,
      heeftToelichting4: item.Opties[3].HeeftToelichting,
      heeftToelichting5: item.Opties[4].HeeftToelichting,
    };
  }, [item, teksten]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        vraag: Yup.array().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{item == null ? 'Toevoegen Vraag' : `Wijzigen Vraag`}</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          ref={formikRef}
          isInitialValid
          enableReinitialize
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Vraagnummer</label>
                        <Field
                          name={nameof<IFormikValues>('sortNr')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="number" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Vraagtype</label>
                        <Field
                          name={nameOf<IFormikValues>('vraagType')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            const opties = [
                              { id: 0, label: 'Tekst' },
                              { id: 1, label: 'Ja of Nee' },
                              { id: 2, label: 'Tekst of Nee' },
                              { id: 3, label: 'Multi-keuze Enkel antwoord' },
                              { id: 4, label: 'Multi-keuze Multi antwoord' },
                            ];

                            return (
                              <>
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={opties}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Vraag</label>
                        <Field
                          name={nameof<IFormikValues>('vraag')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>

                      {!formikRef.current ||
                      formikRef.current.getFormikBag().values.vraagType < 3 ? (
                        ''
                      ) : (
                        <>
                          <div className="col-12 mt-3">
                            <label>Antwoordoptie 1</label>
                            <Field
                              name={nameof<IFormikValues>('optie1')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                return (
                                  <MeertaligTekstveld
                                    waarden={fieldProps.field.value}
                                    onChange={(x) =>
                                      fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                    }
                                  />
                                );
                              }}
                            />
                            {formikRef.current?.getFormikBag().values.vraagType != 3 ? (
                              ''
                            ) : (
                              <Field
                                name={nameOf<IFormikValues>('heeftToelichting1')}
                                render={({ field, form }: FieldProps<IFormikValues>) => {
                                  return (
                                    <div className="d-flex align-items-center">
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                      <span className="ml-2">Heeft toelichtingsveld</span>
                                    </div>
                                  );
                                }}
                              />
                            )}
                          </div>
                          <div className="col-12 mt-3">
                            <label>Antwoordoptie 2</label>
                            <Field
                              name={nameof<IFormikValues>('optie2')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                return (
                                  <MeertaligTekstveld
                                    waarden={fieldProps.field.value}
                                    onChange={(x) =>
                                      fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                    }
                                  />
                                );
                              }}
                            />
                            {formikRef.current!.getFormikBag().values.vraagType != 3 ? (
                              ''
                            ) : (
                              <Field
                                name={nameOf<IFormikValues>('heeftToelichting2')}
                                render={({ field, form }: FieldProps<IFormikValues>) => {
                                  return (
                                    <div className="d-flex align-items-center">
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                      <span className="ml-2">Heeft toelichtingsveld</span>
                                    </div>
                                  );
                                }}
                              />
                            )}
                          </div>
                          <div className="col-12 mt-3">
                            <label>Antwoordoptie 3</label>
                            <Field
                              name={nameof<IFormikValues>('optie3')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                return (
                                  <MeertaligTekstveld
                                    waarden={fieldProps.field.value}
                                    onChange={(x) =>
                                      fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                    }
                                  />
                                );
                              }}
                            />
                            {formikRef.current!.getFormikBag().values.vraagType != 3 ? (
                              ''
                            ) : (
                              <Field
                                name={nameOf<IFormikValues>('heeftToelichting3')}
                                render={({ field, form }: FieldProps<IFormikValues>) => {
                                  return (
                                    <div className="d-flex align-items-center">
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                      <span className="ml-2">Heeft toelichtingsveld</span>
                                    </div>
                                  );
                                }}
                              />
                            )}
                          </div>
                          <div className="col-12 mt-3">
                            <label>Antwoordoptie 4</label>
                            <Field
                              name={nameof<IFormikValues>('optie4')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                return (
                                  <MeertaligTekstveld
                                    waarden={fieldProps.field.value}
                                    onChange={(x) =>
                                      fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                    }
                                  />
                                );
                              }}
                            />
                            {formikRef.current!.getFormikBag().values.vraagType != 3 ? (
                              ''
                            ) : (
                              <Field
                                name={nameOf<IFormikValues>('heeftToelichting4')}
                                render={({ field, form }: FieldProps<IFormikValues>) => {
                                  return (
                                    <div className="d-flex align-items-center">
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                      <span className="ml-2">Heeft toelichtingsveld</span>
                                    </div>
                                  );
                                }}
                              />
                            )}
                          </div>
                          <div className="col-12 mt-3">
                            <label>Antwoordoptie 5</label>
                            <Field
                              name={nameof<IFormikValues>('optie5')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                return (
                                  <MeertaligTekstveld
                                    waarden={fieldProps.field.value}
                                    onChange={(x) =>
                                      fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                    }
                                  />
                                );
                              }}
                            />
                            {formikRef.current!.getFormikBag().values.vraagType != 3 ? (
                              ''
                            ) : (
                              <Field
                                name={nameOf<IFormikValues>('heeftToelichting5')}
                                render={({ field, form }: FieldProps<IFormikValues>) => {
                                  return (
                                    <div className="d-flex align-items-center">
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                      />
                                      <span className="ml-2">Heeft toelichtingsveld</span>
                                    </div>
                                  );
                                }}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
