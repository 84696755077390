import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import MenuLayout from '../../../../components/MenuLayout';
import { IconPlay, IconStop } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import api, { IPaginatiePositie } from '../../../../api';
import { IBulkVerzendBericht } from '../../../../../../shared/src/api/v2/bulkVerzending';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';

const ActivatieKnop = styled.button<{ disabled?: boolean; isActief: boolean }>`
  background-color: ${({ disabled, isActief }) =>
    disabled ? '#b0b0b0' : isActief ? Kleur.Rood : Kleur.Groen};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.2s;
  margin-right: 20px;

  &:hover {
    background-color: ${({ disabled, isActief }) =>
      disabled ? '#b0b0b0' : isActief ? Kleur.DonkerRood : Kleur.DonkerGroen};
  }
`;

const initielePaginatie: IPaginatiePositie = {
  index: 0,
  aantal: 50,
};

interface IData {
  totaalAantal: number;
  berichten: { [index: number]: IBulkVerzendBericht };
}

interface IProps extends RouteComponentProps {}

const Overzicht = observer((props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  // tslint:disable-next-line:variable-name
  const [data, _setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const dataRef = useRef(data);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    _setData(data);
  }, []);

  const [uitgeklapt, setUitgeklapt] = useState<number[]>([]);

  const [isBezig, setIsBezig] = useState(false);

  const { instellingStore } = useContext(RootStoreContext);
  useEffect(() => {
    instellingStore.ophalenInstellingen();
  }, []);

  const isActief = instellingStore.IsBulkverzenderActief;

  const handleIsActiefMuterenClick = useCallback(async () => {
    setIsBezig(true);

    await api.v2.instelling.deelsWijzigenInstellingen({
      applicatie: {
        isBulkverzenderActief: !isActief,
      },
    });
    await instellingStore.ophalenInstellingen();

    setIsBezig(false);
  }, [isActief]);

  const ophalenData = useCallback(async (paginatie: IPaginatiePositie, uitbreiden = false) => {
    const result = await api.v2.bulkVerzending.ophalenBulkVerzendBerichten({
      paginatie,
    });

    const berichten = result.items.reduce(
      (acc, item, i) => {
        acc[paginatie.index + i] = item;
        return acc;
      },
      uitbreiden ? dataRef.current.data?.berichten ?? {} : {},
    );

    setData(
      createReadyRemoteData({
        totaalAantal: result.totaalAantal,
        berichten,
      }),
    );
  }, []);

  const dataVerversen = useCallback(async () => {
    await ophalenData(initielePaginatie);
  }, [ophalenData]);

  useEffect(() => {
    dataVerversen();
  }, []);

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <ActivatieKnop
            onClick={handleIsActiefMuterenClick}
            isActief={isActief}
            disabled={isBezig}
          >
            {isActief ? (
              <>
                <IconStop style={{ width: 18, height: 18, fill: Kleur.Wit }} />
                <span className="ml-2">Deactiveren verzender</span>
              </>
            ) : (
              <>
                <IconPlay style={{ width: 18, height: 18, fill: Kleur.Wit }} />
                <span className="ml-2">Activeren verzender</span>
              </>
            )}
          </ActivatieKnop>

          {isActief ? (
            <span style={{ color: Kleur.Groen }} className="font-weight-bold">
              De bulkverzender is actief en pakt berichten op die in afwachting zijn.
            </span>
          ) : (
            <span style={{ color: Kleur.Rood }} className="font-weight-bold">
              De bulkverzender is niet actief.
            </span>
          )}
        </div>
      }
    />
  );
});

export default Overzicht;
