import React, { useMemo, useState } from 'react';
import { IconCorrigeren, IconKaart } from '../../Icons';
import { Root, IconButton } from './style';
import LocatieMutatieDialoog, {
  ELocatieMutatieDialoogType,
} from '../../formulier/LocatieSelectie/LocatieMutatieDialoog';
import ActieMenuKnop, { IActie } from '../../ActieMenuKnop';

interface IProps {
  locID?: number;
  onCorrectieUitgevoerd?: () => void;
  weergaveTekst?: string;
  extraActies?: IActie[];
  tekstStyle?: React.CSSProperties;
}

const AdresVisualisatie: React.FC<IProps> = (props) => {
  const [correctieOpen, setCorrectieOpen] = useState(false);
  const tekst = props.weergaveTekst;

  const acties = useMemo<IActie[]>(() => {
    const arr: IActie[] = [];
    if (props.locID !== undefined) {
      arr.push({
        text: 'Corrigeren',
        icon: (
          <IconCorrigeren
            style={{
              width: 18,
              height: 18,
              position: 'relative',
              bottom: 1,
            }}
          />
        ),
        onClick: () => {
          setCorrectieOpen(true);
        },
      });
    }
    if (tekst !== undefined) {
      arr.push({
        text: 'Openen in Google Maps',
        icon: (
          <IconKaart
            style={{
              width: 17,
              height: 17,
              position: 'relative',
              bottom: 1,
            }}
          />
        ),
        onClick: () => {
          const url = `https://www.google.com/maps/search/${tekst.replace(/\s/g, '+')}`;
          window.open(url, '_blank');
        },
      });
    }
    if (props.extraActies !== undefined) {
      arr.push(...props.extraActies);
    }
    return arr;
  }, [props.extraActies, props.locID, tekst]);

  return (
    <>
      <Root className="d-flex align-items-center">
        <span style={props.tekstStyle} title={tekst}>
          {tekst}
        </span>
        <ActieMenuKnop acties={acties} className="hover-only ml-2" />
      </Root>
      {correctieOpen && (
        <LocatieMutatieDialoog
          locID={props.locID!}
          type={ELocatieMutatieDialoogType.Corrigeren}
          open
          onSuccess={(result) => {
            setCorrectieOpen(false);
            if (props.onCorrectieUitgevoerd) {
              props.onCorrectieUitgevoerd();
            }
          }}
          onAnnuleren={() => {
            setCorrectieOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AdresVisualisatie;
