import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../../../../models/IRemoteData';
import { IOphalenInterneLocatiesResultElement } from '../../../../../../../../../shared/src/api/v2/locatie/locatie';
import api from '../../../../../../../api';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { IOphalenLocatiesResultLocatie } from '../../../../../../../../../shared/src/api/v2/relatie/locatie';
import nameOf from '../../../../../../../core/nameOf';
import MultiComboboxV2, {
  IRepresentatieProps,
  Provider,
} from '../../../../../../../components/formulier/MultiComboboxV2';
import { IOphalenComplexenResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/complex';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../components/tabel/ASPTabel/types';

interface IProps extends IDialoogProps {
  relLocID: number;
}

interface IFormikValues {
  relatieComplexID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  relatieComplexID: 'Complex',
};

const WijzigenDialoog = (props: IProps) => {
  const [relatieLocatie, setRelatieLocatie] = useState<IRemoteData<IOphalenLocatiesResultLocatie>>(
    createPendingRemoteData(),
  );

  const ophalenRelatieLocatie = useCallback(async () => {
    const result = await api.v2.relatieLocatie.ophalenLocaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.relLocID],
          },
        ],
      },
    });
    const locatie = result.locaties[0];
    setRelatieLocatie(createReadyRemoteData(locatie));
  }, [props.relLocID]);

  useEffect(() => {
    ophalenRelatieLocatie();
  }, [ophalenRelatieLocatie]);

  const initialValues = useMemo<IRemoteData<IFormikValues>>(
    () =>
      mapRemoteData(relatieLocatie, (interneLocatie) => ({
        relatieComplexID: interneLocatie.RelatieComplexID,
      })),
    [relatieLocatie],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const locatie = relatieLocatie.data!;
      await api.v2.relatieLocatie.wijzigenLocatie({
        id: locatie.ID,
        relID: locatie.RelID,
        locID: locatie.LocID,
        relatieComplexID: values.relatieComplexID,
      });

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.onSuccess, relatieLocatie],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen locatie</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Ready &&
        relatieLocatie.state === ERemoteDataState.Ready && (
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues.data!}
            render={(formikProps) => (
              <Formulier
                {...props}
                formikProps={formikProps}
                relatieLocatie={relatieLocatie.data!}
              />
            )}
          />
        )}
    </Dialoog>
  );
};

interface IFormulierProps extends IProps {
  formikProps: FormikProps<IFormikValues>;
  relatieLocatie: IOphalenLocatiesResultLocatie;
}

type RelatieComplexKolom = 'naam' | 'omschrijving';

const Formulier = (props: IFormulierProps) => {
  const { isSubmitting, isValid, submitForm } = props.formikProps;

  const relatieComplexComboboxEnkeleProvider = useMemo(
    () => async (relatieComplexID: number) => {
      const result = await api.v2.relatie.complex.ophalenComplexen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [relatieComplexID],
            },
          ],
        },
      });

      return result.complexen[0];
    },
    [],
  );

  const relatieComplexComboboxProvider = useMemo<
    Provider<RelatieComplexKolom, IOphalenComplexenResultElement>
  >(
    () => async (params) => {
      const result = await api.v2.relatie.complex.ophalenComplexen({
        paginatie: params.paginatie,
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [props.relatieLocatie.RelID],
            },
          ],
        },
      });

      const items = result.complexen.reduce<Record<number, IOphalenComplexenResultElement>>(
        (acc, complex, i) => {
          acc[params.paginatie.index + i] = complex;
          return acc;
        },
        {},
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [props.relatieLocatie.RelID],
  );

  const relatieComplexKeyExtractor = useCallback(
    (complex: IOphalenComplexenResultElement) => complex.ID,
    [],
  );

  const relatieComplexKolommen = useMemo<
    ASPKolom<RelatieComplexKolom, IOphalenComplexenResultElement>[]
  >(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (complex) => complex.Naam,
      },
      {
        key: 'omschrijving',
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (complex) => complex.Omschrijving ?? '- -',
      },
    ],
    [],
  );

  return (
    <>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <label>{veldnamen.relatieComplexID}</label>
            <Field
              name={nameOf<IFormikValues>('relatieComplexID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;

                return (
                  <MultiComboboxV2
                    enkeleProvider={relatieComplexComboboxEnkeleProvider}
                    provider={relatieComplexComboboxProvider}
                    keyExtractor={relatieComplexKeyExtractor}
                    kolommen={relatieComplexKolommen}
                    waarde={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    representatieComponent={RelatieComplexRepresentatie}
                    wisbaar
                  />
                );
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

const RelatieComplexRepresentatie = (
  props: IRepresentatieProps<IOphalenComplexenResultElement>,
) => {
  return <span>{props.entiteit.Naam}</span>;
};

export default WijzigenDialoog;
