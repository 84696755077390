import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  VirtualTable,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  RowDetailState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  IOphalenVertegenwoordigersResultElement,
  IOphalenVertegenwoordigingHoedanighedenResultElement,
} from '../../../../../../shared/src/api/v2/relatie/vertegenwoordiger';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import nameOf from '../../../../core/nameOf';

interface IProps extends RouteComponentProps {}

const geenData = {
  noData: 'Er zijn geen vertegenwoordigers gevonden.',
};

interface IRegel extends IOphalenVertegenwoordigersResultElement {
  relatieVertegenwoordiger_RelID: number | null;
}

const Vertegenwoordigd: React.FC<IProps> = (props) => {
  const [hoedanigheden, setHoedanigheden] = useState<
    IOphalenVertegenwoordigingHoedanighedenResultElement[] | null
  >(null);
  const [vertegenwoordigers, setVertegenwoordigers] = useState<IRegel[] | null>(null);

  const ophalenVertegenwoordigers = useCallback(async () => {
    const result = await api.v2.relatie.vertegenwoordiging.ophalenVertegenwoordigers({
      //  filterSchema: {
      //    filters: [{ naam: 'REL_IDS', data: [props.relID] }],
      //  },
    });

    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: result.vertegenwoordigers.map((x) => x.RelID) }],
        },
      })
    ).relaties;

    const vertegenwoordigers = result.vertegenwoordigers.map((x) => {
      const relatie = relaties.find((r) => r.RelID === x.RelID)!;
      return { ...x, relatieVertegenwoordiger_RelID: relatie.Vertegenwoordiger_RelID };
    });

    setVertegenwoordigers(vertegenwoordigers);
  }, []);

  useEffect(() => {
    ophalenVertegenwoordigers();
  }, [ophalenVertegenwoordigers]);

  const ophalenHoedanigheden = useCallback(async () => {
    const result = await api.v2.relatie.vertegenwoordiging.ophalenHoedanigheden({
      //  filterSchema: {
      //    filters: [{ naam: 'REL_IDS', data: [props.relID] }],
      //  },
    });
    setHoedanigheden(result.hoedanigheden);
  }, []);

  useEffect(() => {
    ophalenHoedanigheden();
  }, [ophalenHoedanigheden]);

  const keyExtractor = useCallback((row: IOphalenVertegenwoordigersResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenVertegenwoordigersResultElement>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__vertegenwoordiger' as any,
        title: 'Vertegenwoordiger',
      },
      {
        name: '__actueel' as any,
        title: 'Actueel',
      },
      {
        name: '__type' as any,
        title: 'Hoedanigheid',
      },
      {
        name: '__facturen' as any,
        title: 'Facturen doorsturen',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenVertegenwoordigersResultElement>[]
  >(
    () => [
      {
        columnName: '__relatie' as any,
        width: 275,
      },
      {
        columnName: '__vertegenwoordiger' as any,
        width: 250,
      },
      {
        columnName: '__actueel' as any,
        width: 125,
      },
      {
        columnName: '__type' as any,
        width: 175,
      },
      {
        columnName: '__facturen' as any,
        width: 175,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      {vertegenwoordigers === null || hoedanigheden === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <MenuLayout
            menu={
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <div className="d-flex align-items-center"></div>
                </div>
              </div>
            }
            body={
              <>
                <GridStyleWrapper height="calc(100vh - 150px)">
                  <Grid rows={vertegenwoordigers} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__relatie']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenVertegenwoordigersResultElement = formatterProps.row;

                        return <RelatieVisualisatie relID={rij.RelID!} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__vertegenwoordiger']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenVertegenwoordigersResultElement = formatterProps.row;

                        return <RelatieVisualisatie relID={rij.Vertegenwoordiger_RelID!} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__actueel']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegel = formatterProps.row;

                        const actueel =
                          rij.relatieVertegenwoordiger_RelID !== null &&
                          rij.relatieVertegenwoordiger_RelID === rij.Vertegenwoordiger_RelID;

                        return <span>{actueel ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__facturen']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenVertegenwoordigersResultElement = formatterProps.row;

                        return <span>{rij.FacturenDoorsturen ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__type']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenVertegenwoordigersResultElement = formatterProps.row;
                        debugger;
                        return <span>{rij.hoedanigheid.Naam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenVertegenwoordigersResultElement>('RecordToegevoegd')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    {/* <SortingState defaultSorting={[]} />
                    <IntegratedSorting /> */}

                    <VirtualTable messages={geenData} />

                    <RowDetailState defaultExpandedRowIds={[]} />

                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default withRouter(Vertegenwoordigd);
