import React, {
  DependencyList,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RootStore, RootStoreContext } from '../../stores/RootStore';
import { v4 as uuid } from 'uuid';
import { observer } from 'mobx-react-lite';
import RealtimeStore from '../../stores/RealtimeStore';
import Dialoog from '../../components/dialogen/Dialoog';
import ModalBody from 'react-bootstrap/ModalBody';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import {
  IWhatsappBerichtNieuwBericht,
  IWhatsappChatsessieBijgewerktBericht,
  IWhatsappConversatieVoortzettenAangevraagdBericht,
  useWhatsappV2Store,
} from '../../components/communicatie/WhatsAppWerkbladV2/store';

const wsUrl = process.env.REACT_APP_REALTIME_MANAGER_URL!;
export let ws: WebSocket | null = null;
// const autoReconnectInterval = 1000;
// ws.addEventListener('message', (ev) => {
//   // tslint:disable-next-line:no-console
//   console.log('Message: ' + ev.data);
// });

interface IMessage {
  type: string;
  data: any;
}

const messageMapping: { [naamEnum: string]: (rootStore: RootStore) => (msg: any) => void } = {
  // WHATSAPP_INBOUND_MESSAGE: (x) => x.whatsappStore.handleInkomendBericht,
  // WHATSAPP_UPDATE_CHATSESSIE: (x) => x.whatsappStore.handleWhatsappUpdateChatsessie,
  INSTELLEN_BEZIGHEID: (x) => x.gebruikerStore.handleInstellenBezigheid,
  SMS_BERICHT: (x) => x.smsStore.handleSmsBericht,
  NIEUW_SMS_CONTACT: (x) => x.smsStore.handleNieuwSmsContact,
  SMS_CONTACT_GEWIJZIGD: (x) => x.smsStore.handleSmsContactGewijzigd,
  // BOEKHOUDING_BANKMUTATIES_TOEVOEGEN_BESTAND_IMPORT: (x) =>
  //   x.bankMutatieStore.handleToevoegenBestandImport,
  // BOEKHOUDING_BANKMUTATIES_VERWIJDEREN_BESTAND_IMPORT: (x) =>
  //   x.bankMutatieStore.handleVerwijderenBestandImport,
  // BOEKHOUDING_BANKMUTATIES_WIJZIGEN_BESTAND_IMPORT: (x) =>
  //   x.bankMutatieStore.handleWijzigenBestandImport,
  // BOEKHOUDING_BANKMUTATIES_GEWIJZIGD: (x) => x.bankMutatieStore.handleGewijzigd,
  // BOEKHOUDING_BANKMUTATIES_DOORVOEREN_MUTATIES: (x) => x.bankMutatieStore.handleDoorvoerenMutaties,
  // BOEKHOUDING_BANKMUTATIES_VERWIJDEREN_MUTATIE: (x) => x.bankMutatieStore.handleVerwijderenMutaties,
  TELEFOON_OPROEP_GEWIJZIGD: (x) => (msg) => {
    x.telefoonStore.handleTelefoonOproepGewijzigd(msg);
    x.telefoonHistorieCommunicatieLayoutStore.handleTelefoonOproepGewijzigd(msg);
    x.telefoonHistorieCommunicatieGlobaalStore.handleTelefoonOproepGewijzigd(msg);
  },
  TELEFOON_OPROEP_BEEINDIGD: (x) => (msg) => {
    x.telefoonHistorieCommunicatieLayoutStore.handleTelefoonOproepBeeindigd(msg);
    x.telefoonHistorieCommunicatieGlobaalStore.handleTelefoonOproepBeeindigd(msg);
  },
  TELEFOON_OPROEP: (x) => x.telefoonStore.handleTelefoonOproep,
  NOTIFICATIE_NIEUW: (x) => x.notificatieStore.handleNotificatieNieuw,
  NOTIFICATIE_BEVESTIGD: (x) => x.notificatieStore.handleNotificatieBevestigd,
  ACCOUNT_TOEGEVOEGD: (x) => x.accountStore.handleAccountToegevoegd,
  ACCOUNTS_VERWIJDERD: (x) => x.accountStore.handleAccountsVerwijderd,
  PERSOON_GEWIJZIGD: (x) => (msg: any) => {
    x.persoonStore.handlePersoonGewijzigd(msg);
    x.relatieStore.handlePersoonGewijzigd(msg);
    x.klantkaartStore.handlePersoonGewijzigd(msg);
  },
  CONTACTPERSOON_GEKOPPELD: (x) => x.klantkaartStore.handleContactpersoonGekoppeld,
  CONTACTPERSOON_ONTKOPPELD: (x) => x.klantkaartStore.handleContactpersoonOntkoppeld,
  // CONTRACT_MUTATIE_GEWIJZIGD: (x) => x.contractMutatieStore.handleContractMutatieGewijzigd,
  CONTROL_TAAK_NIEUW: (x) => x.controlStore.handleControlTaakNieuw,
  CONTROL_TAKEN_AFGEHANDELD: (x) => x.controlStore.handleControlTakenAfgehandeld,
  TEKST_GEWIJZIGD: (x) => x.tekstStore.handleTekstGewijzigd,
  ASP_GEBRUIKER_STATUS_INGESTELD: (x) => x.gebruikerStore.handleAspGebruikerStatusIngesteld,
  ASP_GEBRUIKER_AUTO_STATUS_GEWIJZIGD: (x) =>
    x.gebruikerStore.handleAspGebruikerAutoStatusGewijzigd,
  ASP_GEBRUIKER_GEWIJZIGD: (x) => x.gebruikerStore.handleAspGebruikerGewijzigd,
  ASP_GEBRUIKER_GEBRUIKERSPROFIEL_INGESTELD: (x) =>
    x.gebruikerStore.handleAspGebruikerGebruikersprofielIngesteld,
  GEBRUIKERSPROFIEL_GEWIJZIGD: (x) => x.gebruikerStore.handleGebruikersprofielGewijzigd,
  MEMO_TOEGEVOEGD: (x) => x.memoStore.handleMemoToegevoegdEvent,
  MEMO_GEWIJZIGD: (x) => x.memoStore.handleMemoGewijzigdEvent,
  MEMO_VERWIJDERD: (x) => x.memoStore.handleMemoVerwijderdEvent,
  VOIP_ACCOUNT_VIGEREND_INGESTELD: (x) => x.voipStore.handleVoipAccountVigerendIngesteld,
  VOIP_ACCOUNTS_VIGEREND_VERWIJDERD: (x) => x.voipStore.handleVoipAccountsVigerendVerwijderd,
  MELDING_INDICATIE_GEWIJZIGD: (x) =>
    x.meldingIndicatieStore.handleMeldingIndicatieGewijzigdMessageData,
  INFO_INDICATIE_GEWIJZIGD: (x) => x.infoIndicatieStore.handleInfoIndicatieGewijzigdMessageData,
  WHATSAPP_BERICHT_NIEUW: (_) => (bericht) => {
    const state = useWhatsappV2Store.getState();
    state.handleWhatsappBerichtNieuw(bericht as IWhatsappBerichtNieuwBericht);
  },
  WHATSAPP_BERICHT_BIJGEWERKT: (_) => (bericht) => {
    const state = useWhatsappV2Store.getState();
    state.handleWhatsappBerichtBijgewerkt(bericht as IWhatsappBerichtNieuwBericht);
  },
  WHATSAPP_CHATSESSIE_BIJGEWERKT: (_) => (bericht) => {
    const state = useWhatsappV2Store.getState();
    state.handleWhatsappChatsessieBijgewerkt(bericht as IWhatsappChatsessieBijgewerktBericht);
  },
  WHATSAPP_CONVERSATIE_VOORTZETTEN_AANGEVRAAGD: (_) => (bericht) => {
    const state = useWhatsappV2Store.getState();
    state.handleWhatsappConversatieVoortzettenAangevraagd(
      bericht as IWhatsappConversatieVoortzettenAangevraagdBericht,
    );
  },
};

const CLOSE_NORMAL = 1000;
const CLOSE_GOING_AWAY = 1001;
const CLOSE_ABNORMAL_CLOSURE = 1006;

type ListenerFn = (naamEnum: string, data: any) => void;

export interface IRealtimeContext {
  registerListener: (key: string, listener: ListenerFn) => void;
  disposeListener: (key: string) => void;
}

export const RealtimeContext = React.createContext<IRealtimeContext>(null as any);

export const useRealtimeListener = (listenerFn: ListenerFn, deps?: DependencyList) => {
  const { registerListener, disposeListener } = useContext(RealtimeContext);
  const id = useMemo(() => uuid(), []);
  const listener = useCallback(listenerFn, deps ?? []);
  useEffect(() => {
    registerListener(id, listener);
    return () => disposeListener(id);
  }, [listener]);
};

interface IScheduledAction {
  id: string;
  action: () => Promise<void>;
}

const RealtimeIntegratie: React.FC = observer((props) => {
  const { realtimeStore } = useContext(RootStoreContext);

  // tslint:disable-next-line:variable-name
  const [webSocket, __setWebSocket] = useState<WebSocket | null>(null);
  const listeners = useRef<Record<string, ListenerFn>>({});
  const scheduledActions = useRef<IScheduledAction[]>([]);
  const schedulerRunning = useRef<boolean>(false);

  const setWebSocket = useCallback(
    (value: WebSocket | null) => {
      ws = value;
      __setWebSocket(value);
    },
    [__setWebSocket],
  );

  const rootStore = useContext(RootStoreContext);
  const connect = useCallback(async () => {
    console.log('Verbinden met realtime manager...');
    const ws = new WebSocket(wsUrl);

    ws.onmessage = (ev: MessageEvent) => {
      const data = JSON.parse(ev.data) as IMessage;

      const fn = messageMapping[data.type];
      if (fn !== undefined) {
        fn(rootStore)(data.data);
      }

      Object.keys(listeners.current).forEach((key) => {
        const listener = listeners.current[key];
        listener(data.type, data.data);
      });
    };

    let open = false;
    let error = false;
    ws.onopen = () => {
      open = true;
      rootStore.realtimeStore.setConnected(true);
    };

    ws.onclose = async (ev: CloseEvent) => {
      open = false;
      rootStore.realtimeStore.setConnected(false);
      setWebSocket(null);

      // const wasOpen = open;
      switch (ev.code) {
        case CLOSE_NORMAL:
          // tslint:disable-next-line:no-console
          console.log('Server stopped realtime connection');
          break;
        case CLOSE_GOING_AWAY:
          // Pagina wordt verlaten
          return;
        case CLOSE_ABNORMAL_CLOSURE:
          // Op rare wijze afgesloten door de browser
          break;
      }
      console.log('Connectie verbroken/mislukt', ev);

      await new Promise((resolve) => setTimeout(resolve, 500));
      console.log('Proberen te herverbinden met realtime manager');
      const connectedAgain = await connect();
      if (connectedAgain) {
        console.log('Herverbinden met realtime manager gelukt!');
        return;
        // break;
      }
      console.log('Herverbinden met realtime manager mislukt');
    };

    ws.onerror = () => {
      error = true;
    };

    try {
      // Wait for connection
      await new Promise((resolve, reject) => {
        const timeoutCallback = () => {
          if (open) {
            resolve();
            return;
          }
          if (error) {
            reject();
            return;
          }

          setTimeout(timeoutCallback, 1);
        };
        timeoutCallback();
      });
      setWebSocket(ws);
      return true;
    } catch (_err) {
      return false;
    }
  }, [setWebSocket, rootStore]);

  const disconnect = useCallback(async () => {
    if (webSocket !== null) {
      if (webSocket.readyState === WebSocket.CLOSED) {
        return true;
      }
      webSocket.close(CLOSE_NORMAL);
      // Wait for connection to be closed
      await new Promise((resolve) => {
        const timeoutCallback = () => {
          if (webSocket.readyState === WebSocket.CLOSED) {
            resolve();
            return;
          }

          setTimeout(timeoutCallback, 1);
        };
        timeoutCallback();
      });

      setWebSocket(null);
      return true;
    }

    return false;
  }, [webSocket, setWebSocket]);

  const reconnect = useCallback(async () => {
    await disconnect();
    await connect();
  }, [connect, webSocket]);

  const wakeScheduler = useCallback(async () => {
    if (schedulerRunning.current) {
      return;
    }
    schedulerRunning.current = true;
    const scheduledActionsToBeRan = [...scheduledActions.current];
    for (const scheduledAction of scheduledActionsToBeRan) {
      try {
        await scheduledAction.action();
      } catch (err) {
        console.error(err.message);
      }
    }
    // Remove ran actions from list
    scheduledActions.current = scheduledActions.current.filter(
      (scheduledAction) =>
        scheduledActionsToBeRan.findIndex(
          (ranScheduledAction) => scheduledAction.id === ranScheduledAction.id,
        ) === -1,
    );
    schedulerRunning.current = false;
  }, []);

  const scheduleAction = useCallback(
    (action: () => Promise<void>) => {
      const id = uuid();

      scheduledActions.current = [
        ...scheduledActions.current,
        {
          id,
          action,
        },
      ];
      // noinspection JSIgnoredPromiseFromCall
      wakeScheduler();
    },
    [wakeScheduler],
  );

  const realtimeContextValue = useMemo<IRealtimeContext>(() => {
    return {
      registerListener: (key, listener) => {
        listeners.current = {
          ...listeners.current,
          [key]: listener,
        };
      },
      disposeListener: (key) => {
        listeners.current = Object.keys(listeners.current).reduce((acc, curr) => {
          if (curr === key) {
            return acc;
          }
          return {
            ...acc,
            [curr]: listeners.current[curr],
          };
        }, {});
      },
    };
  }, [listeners.current, webSocket, setWebSocket]);

  useEffect(() => {
    console.log('Realtime integratie initialiseren');
    connect();
  }, []);

  // useEffect(() => {
  //   scheduleAction(reconnect);
  //   // if (rootStore.gebruikerStore.gebruiker === null)
  // }, [
  //   rootStore.gebruikerStore.gebruiker === null
  //     ? null
  //     : rootStore.gebruikerStore.gebruiker.AspGebrID,
  //   scheduleAction,
  // ]);

  return (
    <>
      <RealtimeContext.Provider value={realtimeContextValue}>
        {props.children}
      </RealtimeContext.Provider>
      {!realtimeStore.isConnected && !realtimeStore.negerenNietVerbonden && (
        <Dialoog index={0}>
          <ModalBody>
            <div className="flex-fill d-flex p-3 align-items-center">
              <div className="ml-2">
                <LoadingSpinner grootte="40px" />
              </div>
              <div className="flex-fill d-flex flex-column ml-5">
                <div>
                  <h5>Realtime verbinding is verbroken</h5>
                </div>
                <div className="flex-fill">
                  <span>We proberen opnieuw te verbinden...</span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end ml-2 mr-2">
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  realtimeStore.setNegerenNietVerbonden(true);
                }}
              >
                Negeren
              </a>
            </div>
          </ModalBody>
        </Dialoog>
      )}
    </>
  );
});

export default RealtimeIntegratie;
