import * as React from 'react';
import { HTMLProps, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Avatar from '../../../personalia/Avatar';
import Berichten from './Berichten';
import { IChatsessieState, useWhatsappV2Store } from '../store';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IWhatsappChatsessie } from '../../../../api/services/v1/whatsapp';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { naamNaarAvatarKleur, naamNaarInitialen } from '../helpers';
import TelefoonComponent from '../../TelefoonComponent';
import Knop from '../Knop';
import { IconZoeken } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import PersoonKoppelComponent from '../../../personalia/PersoonKoppelComponent';
import api from '../../../../api';
import Invoerbalk from './Invoerbalk';
import TijdvensterVerlopen from './TijdvensterVerlopen';
import { EWhatsappTabblad } from '../types';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
`;

const HeadingMain = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const HeadingEnd = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const NaamComponent = (props: HTMLProps<HTMLSpanElement>) => {
  return <span {...props} style={{ ...props.style, fontSize: 15, fontWeight: 'bold' }} />;
};

const OngekoppeldContainerComponent = (props: HTMLProps<HTMLDivElement>) => {
  return <div {...props} style={{ ...props.style, fontSize: 15, fontWeight: 'bold' }} />;
};

interface IProps {
  tabblad: EWhatsappTabblad;
  chatsessieID: number | null;
}

const Conversatie = (props: IProps) => {
  const store = useWhatsappV2Store();
  const chatsessie = useMemo<IRemoteData<IWhatsappChatsessie | null>>(() => {
    if (props.chatsessieID === null) {
      return createReadyRemoteData(null);
    }
    if (store.chatsessieBijID.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const chatsessie = store.chatsessieBijID.data![props.chatsessieID];
    if (!chatsessie) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(chatsessie);
  }, [props.chatsessieID, store.chatsessieBijID]);

  const avatar = useMemo(() => {
    return (chatsessie.data?.naam ?? null) === null
      ? null
      : {
          initialen: naamNaarInitialen(chatsessie.data!.naam!),
          kleur: naamNaarAvatarKleur(chatsessie.data!.naam!),
        };
  }, [chatsessie.data?.naam]);

  const state = useMemo<IChatsessieState | null>(() => {
    if (props.chatsessieID === null) {
      return null;
    }
    return store.chatsessieState[props.chatsessieID] ?? null;
  }, [props.chatsessieID, store.chatsessieState]);

  const setState = useCallback(
    (partial: Partial<IChatsessieState>) => {
      if (props.chatsessieID === null) {
        return;
      }
      store.setChatsessieState(props.chatsessieID, partial);
    },
    [props.chatsessieID, store.setChatsessieState],
  );

  const tijdvensterIsVerlopen = useMemo<IRemoteData<boolean | null>>(() => {
    if (props.chatsessieID === null) {
      return createReadyRemoteData(null);
    }
    const tijdvenster = store.tijdvensterBijChatsessieId[props.chatsessieID];
    if (tijdvenster === undefined) {
      return createPendingRemoteData();
    }
    const nu = new Date();
    const isVerlopen = tijdvenster.totEnMet <= nu;
    // if (isVerlopen) {
    //   console.log('DEBUG tijdvenster verlopen bepaald');
    //   console.log('totEnMet: ', tijdvenster.totEnMet);
    //   console.log('nu: ', nu);
    // }
    return createReadyRemoteData(isVerlopen);
  }, [store.tijdvensterBijChatsessieId, props.chatsessieID]);

  // if (
  //   tijdvensterIsVerlopen.state === ERemoteDataState.Ready &&
  //   tijdvensterIsVerlopen.data === true
  // ) {
  //   console.log('DEBUG tijdvensterverlopen');
  //   console.log('chatsessieID: ', props.chatsessieID);
  //   console.log('tijdvenster: ', store.tijdvensterBijChatsessieId[props.chatsessieID!]);
  // }

  const invoerdeel = useMemo(() => {
    if (tijdvensterIsVerlopen.data === true) {
      if (props.tabblad === EWhatsappTabblad.Archief) {
        return null;
      }
      return <TijdvensterVerlopen chatsessieID={chatsessie.data!.id} />;
    }

    if (props.tabblad !== EWhatsappTabblad.Lopend) {
      return null;
    }

    return (
      <Invoerbalk
        chatsessie={chatsessie.data!}
        bericht={state?.bericht ?? ''}
        onBerichtChange={(x) => setState({ bericht: x })}
        bijlage={state?.bijlage ?? null}
        onBijlageChange={(x) => setState({ bijlage: x })}
        disabled={tijdvensterIsVerlopen.state === ERemoteDataState.Pending}
      />
    );
  }, [props.tabblad, tijdvensterIsVerlopen, chatsessie, state, setState]);

  return (
    <Root>
      {chatsessie.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : chatsessie.data === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <span>Kies een sessie om te beginnen</span>
        </div>
      ) : (
        <>
          <Heading>
            <Avatar size="48px" fontSize="16px" backgroundColor={avatar?.kleur}>
              {avatar?.initialen}
            </Avatar>
            <HeadingMain>
              <div className="flex-fill d-flex align-items-center">
                <span>
                  <PersoonKoppelComponent
                    persID={chatsessie.data!.persId}
                    onPersIDChange={async (persID) => {
                      await api.services.v1.whatsapp.wijzigenChatsessiePersoon({
                        chatsessieId: chatsessie.data!.id,
                        persoonId: persID,
                      });
                    }}
                    weergaveNaamPersoonOnbekend={
                      chatsessie.data!.naam ?? chatsessie.data!.telefoonnummer
                    }
                    naamComponent={NaamComponent}
                    ongekoppeldContainerComponent={OngekoppeldContainerComponent}
                    persoonSelectieDialoogDefaultFormValues={{
                      telefoon: chatsessie.data!.telefoonnummer,
                    }}
                  />
                </span>
                {chatsessie.data!.persId !== null && chatsessie.data!.naam !== null && (
                  <span className="font-weight-bold text-muted">({chatsessie.data!.naam})</span>
                )}
              </div>
              <TelefoonComponent
                telefoonNummer={chatsessie.data!.telefoonnummer}
                options={{ icoonWeergeven: false }}
                persID={chatsessie.data!.persId ?? undefined}
              />
            </HeadingMain>
            <HeadingEnd>
              <Knop title="Zoeken binnen conversatie">
                <IconZoeken style={{ fill: Kleur.Grijs, width: 24, height: 24 }} />
              </Knop>
            </HeadingEnd>
          </Heading>
          <Berichten chatsessie={chatsessie.data!} />
          {invoerdeel}
        </>
      )}
    </Root>
  );
};

export default Conversatie;
