import React, { useCallback, useContext, useMemo } from 'react';
import MenuLayout from '../../../../MenuLayout';
import ASPTabel from '../../../../tabel/ASPTabel';
import { IGebouw, IOphalenGebouwenResult } from '../../../../../../../shared/src/api/v2/gebouw';
import { ASPKolom, EAspKolomBreedteType } from '../../../../tabel/ASPTabel/types';
import IRemoteData, { ERemoteDataState } from '../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import api from '../../../../../api';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../../Icons';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import GebouwMutatieDialoog from './GebouwMutatieDialoog';

type Kolom = 'naam';

export interface IGebouwenTabbladProps {
  locID: number;
  gebouwenResult: IRemoteData<IOphalenGebouwenResult | null>;
  verversenGebouwen: () => Promise<void>;
}

const GebouwenTabblad = (props: IGebouwenTabbladProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const keyExtractor = useCallback((gebouw: IGebouw) => gebouw.ID, []);
  const kolommen = useMemo<ASPKolom<Kolom, IGebouw>[]>(
    () => [
      {
        key: 'naam',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        label: 'Naam',
        renderer: (gebouw) => gebouw.Naam,
      },
    ],
    [],
  );

  const handleToevoegenClick = useCallback(async () => {
    const gemuteerd = await globaleRenderer.render((renderProps) => (
      <GebouwMutatieDialoog
        locID={props.locID}
        onSuccess={() => renderProps.destroy(true)}
        onAnnuleren={() => renderProps.destroy(false)}
        open
      />
    ));
    if (!gemuteerd) {
      return;
    }
    await props.verversenGebouwen();
  }, [props.verversenGebouwen, props.locID]);

  const handleWijzigenRij = useCallback(
    async (rij: IGebouw) => {
      const gemuteerd = await globaleRenderer.render((renderProps) => (
        <GebouwMutatieDialoog
          locID={props.locID}
          onSuccess={() => renderProps.destroy(true)}
          onAnnuleren={() => renderProps.destroy(false)}
          open
          id={rij.ID}
        />
      ));
      if (!gemuteerd) {
        return;
      }
      await props.verversenGebouwen();
    },
    [props.verversenGebouwen],
  );
  const handleVerwijderenRij = useCallback(
    async (rij: IGebouw) => {
      await api.v2.gebouw.verwijderenGebouw({
        id: rij.ID,
      });
      await props.verversenGebouwen();
    },
    [props.verversenGebouwen],
  );
  const verwijderenRijConfirmatie = useCallback(
    async (rij: IGebouw, verwijderen: () => Promise<void>) => {
      const checkData = await api.v2.gebouw.checkVerwijderenGebouw({
        id: rij.ID,
      });

      const controleResult = await checkStore.controleren({
        checkData,
        titel: 'Verwijderen gebouw',
      });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      if (
        !controleResult.heeftGebruikersinteractieGehad &&
        (
          await checkStore.bevestigen({
            titel: 'Confirmatie verwijderen',
            inhoud: 'Bevestig het verwijderen van het gebouw',
            asynchroneActieNaBevestigingFn: verwijderen,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await verwijderen();
    },
    [checkStore],
  );

  if (props.gebouwenResult.state === ERemoteDataState.Pending) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={handleToevoegenClick}
          >
            <IconToevoegen
              style={{
                fill: Kleur.Grijs,
                width: 18,
                height: 18,
              }}
            />
            <span className="ml-2">Toevoegen</span>
          </button>
        </div>
      }
      body={
        <ASPTabel
          keyExtractor={keyExtractor}
          kolommen={kolommen}
          rijen={props.gebouwenResult.data?.gebouwen ?? []}
          onWijzigenRij={handleWijzigenRij}
          onVerwijderenRij={handleVerwijderenRij}
          verwijderenRijConfirmatie={verwijderenRijConfirmatie}
        />
      }
    />
  );
};

export default GebouwenTabblad;
