import React from 'react';
import { ISignalering } from '../../../../shared/src/api/v2/control/signalering';
import ContractVisualisatie from '../../components/entiteitVisualisaties/ContractVisualisatie';
import ServiceopdrachtVisualisatie from '../../components/entiteitVisualisaties/ServiceopdrachtVisualisatie';
import TransportopdrachtregelVisualisatie from '../../components/entiteitVisualisaties/TransportopdrachtregelVisualisatie';
import PersoonVisualisatie from '../../components/personalia/PersoonVisualisatie';
import RelatieVisualisatie from '../../components/personalia/RelatieVisualisatie';
import BetalingsregelingVisualisatie from '../../components/entiteitVisualisaties/BetalingsregelingVisualisatie';
import BankopdrachtVisualisatie from '../../components/entiteitVisualisaties/BankopdrachtVisualisatie';
import InkoopfactuurVisualisatie from '../../components/entiteitVisualisaties/InkoopfactuurVisualisatie';

interface IVoorbeeldWeergaveProps {
  signalering: ISignalering;
}

const voorbeeldWeergaveMap: Record<string, React.ComponentType<IVoorbeeldWeergaveProps>> = {
  CONTRACTEN_ZONDER_FACTUURKENMERK: (props) => {
    const { signalering } = props;
    const data = JSON.parse(signalering.Data);
    const cntIDs = data.cntIDs as number[];
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  GEANNULEERD_CONTRACT_MET_LEVEROPDRACHT: (props) => {
    const { signalering } = props;
    const data = JSON.parse(signalering.Data);
    const cntIDs = data.cntIDs as number[];
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft contract{cntIDs.length > 1 ? 'en' : ''}</span> */}
        <span className="ml-0">
          {cntIDs
            .map((cntID) => <ContractVisualisatie cntID={cntID} />)
            .reduce((acc: Array<JSX.Element | null>, curr: JSX.Element, i) => {
              return [
                ...acc,
                curr,
                i === cntIDs.length - 1 ? null : (
                  <span key={i}>
                    ,<span className="ml-1" />
                  </span>
                ),
              ];
            }, [])}
        </span>
      </span>
    );
  },
  ALTIJD_NIET_AANMANEN: (props) => {
    const { signalering } = props;
    const { relID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-0">
          <RelatieVisualisatie relID={relID} />
        </span>
      </span>
    );
  },
  LOPEND_CONTRACT_ZONDER_LEVEROPDRACHT: (props) => {
    const { signalering } = props;
    const data = JSON.parse(signalering.Data);
    const cntIDs = data.cntIDs as number[];
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft contract{cntIDs.length > 1 ? 'en' : ''}</span> */}
        <span className="ml-0">
          {cntIDs
            .map((cntID) => <ContractVisualisatie cntID={cntID} />)
            .reduce((acc: Array<JSX.Element | null>, curr: JSX.Element, i) => {
              return [
                ...acc,
                curr,
                i === cntIDs.length - 1 ? null : (
                  <span key={i}>
                    ,<span className="ml-1" />
                  </span>
                ),
              ];
            }, [])}
        </span>
      </span>
    );
  },
  LOPEND_CONTRACT_ZONDER_GEKOPPELD_PRODUCT: (props) => {
    const { signalering } = props;
    const data = JSON.parse(signalering.Data);
    const cntIDs = data.cntIDs as number[];
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft contract{cntIDs.length > 1 ? 'en' : ''}</span> */}
        <span className="ml-0">
          {cntIDs
            .map((cntID) => <ContractVisualisatie cntID={cntID} />)
            .reduce((acc: Array<JSX.Element | null>, curr: JSX.Element, i) => {
              return [
                ...acc,
                curr,
                i === cntIDs.length - 1 ? null : (
                  <span key={i}>
                    ,<span className="ml-1" />
                  </span>
                ),
              ];
            }, [])}
        </span>
      </span>
    );
  },
  GEPROLONGEERD_ZONDER_STARTDATUM: (props) => {
    const { signalering } = props;
    const data = JSON.parse(signalering.Data);
    const cntIDs = data.cntIDs as number[];
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft contract{cntIDs.length > 1 ? 'en' : ''}</span> */}
        <span className="ml-0">
          {cntIDs
            .map((cntID) => <ContractVisualisatie cntID={cntID} />)
            .reduce((acc: Array<JSX.Element | null>, curr: JSX.Element, i) => {
              return [
                ...acc,
                curr,
                i === cntIDs.length - 1 ? null : (
                  <span key={i}>
                    ,<span className="ml-1" />
                  </span>
                ),
              ];
            }, [])}
        </span>
      </span>
    );
  },
  HUISNUMMER_IN_STRAATNAAM: (props) => {
    const { signalering } = props;
    const { relID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-0">
          <RelatieVisualisatie relID={relID} />
        </span>
      </span>
    );
  },
  VOORVOEGSEL_IN_ACHTERNAAM: (props) => {
    const { signalering } = props;
    const { persID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-0">
          <PersoonVisualisatie persID={persID} />
        </span>
      </span>
    );
  },
  TELEFOONNUMMER_ONJUIST: (props) => {
    const { signalering } = props;
    const { persID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-0">
          <PersoonVisualisatie persID={persID} />
        </span>
      </span>
    );
  },
  PRODUCTEN_UIT_BEDRIJF_MET_GEKOPPELD_CONTRACT: (props) => {
    const { signalering } = props;
    const { cntID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        <span className="ml-0">
          <ContractVisualisatie cntID={cntID} />
        </span>
      </span>
    );
  },
  PRODUCTEN_IN_BEDRIJF_MET_DATUM_UIT_BEDRIJF: (props) => {
    const { signalering } = props;
    const { prodID, typenaam, merknaam, referentiecode } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        <span className="ml-0">
          {merknaam} {typenaam}, {referentiecode ?? 'Geen referentie'}
          {/* <ProductVisualisatie prodID={prodID} /> */}
        </span>
      </span>
    );
  },
  BEZOEKADRES_AFWIJKEND_VAN_LOCATIEADRES_PRODUCT: (props) => {
    const { signalering } = props;
    const { trsRegID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        <span>
          <TransportopdrachtregelVisualisatie trsRegID={trsRegID} />
        </span>
      </span>
    );
  },
  PROLONGATIE_LANG_GELEDEN: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  BEEINDIGD_CONTRACT_BLIJFT_PROLONGEREN: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  NIET_OVERGENOMEN_AANGEBODEN_CONTRACTEN: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  BEEINDIGD_CONTRACT_GEEN_PROLONGATIE_WEL_GEKOPPELD_PRODUCT: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  AFWIJKENDE_LOCATIE_PRODUCT_VS_CONTRACT: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  PROLONGATIE_NA_BEEINDIGING: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  RELATIE_MET_MEER_DAN_1_ACCOUNT: (props) => {
    const { signalering } = props;
    const { relID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span className="ml-0">
          <RelatieVisualisatie
            relID={relID}
            relatieLinkBuilder={(hoedanigheid, relID) =>
              `/${hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'}/${relID}/accounts`
            }
          />
        </span> */}
      </span>
    );
  },
  OMGEWISSELD_CONTRACT_NIET_BEEINDIGD: (props) => {
    const { signalering } = props;
    const { cntID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span className="ml-0">
          <RelatieVisualisatie
            relID={relID}
            relatieLinkBuilder={(hoedanigheid, relID) =>
              `/${hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'}/${relID}/accounts`
            }
          />
        </span> */}
      </span>
    );
  },
  SERVICEOPDRACHT_NIET_VERSTUURD: (props) => {
    const { signalering } = props;
    const { relID, servOpdID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        <span className="ml-0">
          <ServiceopdrachtVisualisatie servOpdID={servOpdID} />
        </span>
      </span>
    );
  },
  VOORLETTERS_GELIJK_AAN_VOORVOEGSEL: (props) => {
    const { signalering } = props;
    const { persID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-0">
          <PersoonVisualisatie persID={persID} />
        </span>
      </span>
    );
  },
  SMS_CONTACT_ZONDER_PERSOON_TELEFOONNUMMER_WEL_BEKEND: (props) => {
    const { signalering } = props;
    const { ID, Telefoonnummer } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-0">Telefoon: {Telefoonnummer}</span>
      </span>
    );
  },
  // RELATIE_ZONDER_STANDAARDREKENING_MET_LOPENDE_CONTRACT: (props) => {
  //   const { signalering } = props;
  //   const { relID } = JSON.parse(signalering.Data);
  //   return (
  //     <span className="d-flex align-items-center">
  //       {/* <span>Betreft relatie</span> */}
  //       <span className="ml-0">
  //         <RelatieVisualisatie relID={relID} />
  //       </span>
  //     </span>
  //   );
  // },
  RELATIE_BETAALWIJZE_INCASSO_MET_CONTRACT_WAARVOOR_GEEN_IBAN_BEPAALD_KAN_WORDEN: (props) => {
    const { signalering } = props;
    const { relID } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-0">
          <RelatieVisualisatie relID={relID} />
        </span>
      </span>
    );
  },
  RELATIES_MET_CONTRACTEN_MET_STATUS_CONCEPT: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  CONTRACTEN_MET_MEER_DAN_1_GEKOPPELD_PRODUCT: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  CONTRACTEN_LOPEND_MAAR_NIET_GEPROLONGEERD: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  KWK_CLAIMS_NOG_NIET_BEOORDEELD: (props) => {
    const { signalering } = props;
    const { id } = JSON.parse(signalering.Data);
    return <span className="d-flex"></span>;
  },
  CONTRACTEN_ZONDER_PROLONGATIE_STARTDATUM: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  AFWIJKENDE_GEBRUIKERSNAAM: (props) => {
    const { signalering } = props;
    const { relID, gebruikersnaam } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-0">{gebruikersnaam}</span>
      </span>
    );
  },
  BANKOPDRACHTEN_VOOR_NIET_LOPENDE_BETALINGSREGELING: (props) => {
    const { signalering } = props;
    const {
      relID,
      bankOpdID,
      betRglIDs,
    }: { relID: number; bankOpdID: number; betRglIDs: number[] } = JSON.parse(signalering.Data);

    return (
      <span className="d-flex">
        <span className="mr-2">
          <BankopdrachtVisualisatie bankOpdID={bankOpdID} />
        </span>
        {betRglIDs.map((betRglID) => {
          return (
            <span className="mr-2" key={betRglID}>
              <BetalingsregelingVisualisatie betRglID={betRglID} />
            </span>
          );
        })}
      </span>
    );
  },
  NIET_VERSTUURDE_STORNOBERICHTEN: (props) => {
    const { signalering } = props;
    const { relID, bankOpdID }: { relID: number; bankOpdID: number } = JSON.parse(signalering.Data);

    return (
      <span className="d-flex">
        <span className="mr-2">
          <BankopdrachtVisualisatie bankOpdID={bankOpdID} />
        </span>
      </span>
    );
  },
  OPENSTAANDE_INKOOPFACTUREN: (props) => {
    const { signalering } = props;
    const { relID, inkFactIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {inkFactIDs.map((inkFactID: any) => {
          return (
            <span className="mr-2">
              <InkoopfactuurVisualisatie inkFactID={inkFactID} />
            </span>
          );
        })}
      </span>
    );
  },
  CONTRACTEN_AANGEBODEN_VOOR_OVERNAME_EN_NIET_LOPEND: (props) => {
    const { signalering } = props;
    const { relID, cntIDs } = JSON.parse(signalering.Data);
    return (
      <span className="d-flex">
        {cntIDs.map((cntID: any) => {
          return (
            <span className="mr-2">
              <ContractVisualisatie cntID={cntID} />
            </span>
          );
        })}
      </span>
    );
  },
  // KWK_CLAIMS_NOG_NIET_BEOORDEELD: (props) => {
  //   const { signalering } = props;
  //   const data = JSON.parse(signalering.Data);
  //   console.log('>>>data>>', data);
  //   return <span></span>;
  //   const ids = data.ids as number[];
  //   return (
  //     <span className="d-flex align-items-center">
  //       {/* <span>Betreft contract{cntIDs.length > 1 ? 'en' : ''}</span> */}
  //       <span className="ml-0">
  //         {ids
  //           .map((id) => <span>{id}</span>)
  //           .reduce((acc: Array<JSX.Element | null>, curr: JSX.Element, i) => {
  //             return [
  //               ...acc,
  //               curr,
  //               i === ids.length - 1 ? null : (
  //                 <span key={i}>
  //                   ,<span className="ml-1" />
  //                 </span>
  //               ),
  //             ];
  //           }, [])}
  //       </span>
  //     </span>
  //   );
  // },
};

export default voorbeeldWeergaveMap;
