import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { Kleur } from '../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import MenuLayout from '../../../components/MenuLayout';
import nameOf from '../../../core/nameOf';
import useUrlState from '../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import WijzigenDialoog from './WijzigenDialoog';
import api from '../../../api';
import { IEnqueteVraagIntern } from '../../../../../shared/src/api/v2/enquete';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../shared/src/api/v2/tekst';
import { find } from 'lodash';

interface IProps extends RouteComponentProps {}

interface IWijzigenDialoogState {
  id: number | null;
}
interface IUrlState {
  selectie: number[];
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenDialoogState: null,
};

const Enquete: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [items, setItems] = useState<IEnqueteVraagIntern[] | null>(null);

  const [teksten, setTeksten] = useState<IOphalenTekstenInAlleTalenResult | null>(null);

  const ophalenItems = useCallback(async () => {
    const results = await api.v2.enquete.ophalenVragen({});
    setItems(results);
  }, []);

  useEffect(() => {
    ophalenItems();
  }, []);

  const ophalenTeksten = useCallback(async () => {
    if (items == null) return;

    const tekstIDs: number[] = items.map((i) => i.Vraag_TekstID).filter((x) => x) as number[];
    if (tekstIDs.length == 0) return;

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTeksten(result);
  }, [items]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('wijzigenDialoogState', { id: null });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde items verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.enquete.verwijderenVragen(params);
    setUrlStateSync('selectie', []);

    await ophalenItems();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IEnqueteVraagIntern) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IEnqueteVraagIntern>[]>(
    () => [
      {
        name: 'SortNr',
        title: 'Nummer',
      },
      {
        name: 'Vraag_TekstID',
        title: 'Vraag',
      },
      {
        name: 'VraagType',
        title: 'Type',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IEnqueteVraagIntern>[]>(
    () => [
      {
        columnName: 'SortNr',
        width: 300,
      },
      {
        columnName: 'Vraag_TekstID',
        width: 400,
      },
      {
        columnName: 'VraagType',
        width: 300,
      },
    ],
    [],
  );

  const vraagTypes = [
    'Tekst',
    'Ja of Nee',
    'Tekst of Nee',
    'Multi-keuze Enkel antwoord',
    'Multi-keuze Multi antwoord',
  ];

  return (
    <>
      <Helmet>
        <title>Vragen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
            {items === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 100px)">
                <Grid getRowId={keyExtractor} rows={items} columns={kolommen}>
                  <DataTypeProvider
                    for={[nameOf<IEnqueteVraagIntern>('SortNr')]}
                    formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
                  />
                  <DataTypeProvider
                    for={[nameOf<IEnqueteVraagIntern>('Vraag_TekstID')]}
                    formatterComponent={(formatterProps) => (
                      <span>
                        {teksten?.teksten.find((t) => t.TekstID == formatterProps.value)?.Tekst ??
                          ''}{' '}
                      </span>
                    )}
                  />
                  <DataTypeProvider
                    for={[nameOf<IEnqueteVraagIntern>('VraagType')]}
                    formatterComponent={(formatterProps) => (
                      <span>{vraagTypes[formatterProps.value]}</span>
                    )}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted !== undefined && changes.deleted.length > 0) {
                        const ids = changes.deleted as number[];
                      }
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenDialoogState', { id: id });
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={{ noData: 'Geen items aanwezig' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow showSortingControls />
                  <TableEditColumn
                    width={35}
                    commandComponent={DXTableEditColumnCommandComponent}
                    cellComponent={DXTableEditColumnCellComponent}
                    showEditCommand
                  />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />

      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            await ophalenItems();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

export default Enquete;
